export var types = [
    'ai',
    'avi',
    'css',
    'csv',
    'dbf',
    'doc',
    'docx',
    'drive',
    'dwg',
    'exe',
    'fla',
    'flash',
    'gif',
    'html',
    'id',
    'iso',
    'js',
    'javascript',
    'jpg',
    'jpeg',
    'json',
    'mp3',
    'mp4',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'psd',
    'rtf',
    'svg',
    'txt',
    'xls',
    'xlsx',
    'xml',
    'zip'
]