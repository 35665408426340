
import { ResourceNotFoundError, DaedelusError, BadRequestError, UnauthorizedAccessError, ServiceUnavailableError, ResourceGoneError } from './error.handler';
import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KANBAN_CONFIG, KanbanApiConfig } from "./api.config";
@Injectable()
export class DataService{
    
    constructor(private _http:HttpClient, 
        @Inject(KANBAN_CONFIG) private config: KanbanApiConfig){}
    toQueryString(conditions = {}){
        
        let query = Object.keys(conditions).reduce( (query, prop, index) =>{
            query += index > 0? "&":"";
            return query + prop+"="+conditions[prop];
        },"");
        return query.length > 0? "?"+query : "";
    }
    makeGetRequest(url){
        return this._http.get(this.config.baseUrl + url)
            .pipe(map( (res:any) =>{
                let data = res;
                return data || [];
            }))
            .pipe(catchError( this.errorHandler ))


    }
    makePostRequest(url, data, _headers:Array<{name:string,value:string}> = []){
        let headers = new Headers();
        for(let _header of _headers){
            headers.append(_header.name, _header.value);
        }
        return this._http.post(this.config.baseUrl + url, data)
            .pipe(map( (res:any) =>{
                let data = res;
                return data || [];
            }))
            .pipe(catchError( this.errorHandler ));
    
    }
    makePutRequest(url, data){
        return this._http.put(this.config.baseUrl + url, data)
            .pipe(map( (res:any) =>{
                
                let data = res;
                return data || [];
            }))
            .pipe(catchError( this.errorHandler ))
    }

    makeDeleteRequest(url){
        return this._http.delete(this.config.baseUrl + url)
            .pipe(map( (res:any) =>{
                let data = res;
                return data || [];
            }))
            .pipe(catchError( this.errorHandler ));

    }
    
    protected urlParameters(filterCriteria, url):string{
        if(filterCriteria){
            let parameters = [];
            for(var prop in filterCriteria){
                if(Array.isArray(filterCriteria[prop])){
                    let value = filterCriteria[prop].join(",");
                    parameters.push(`${prop}=${value}`);
                }else{
                    parameters.push(`${prop}=${filterCriteria[prop]}`);
                }
            }
            url += "?" + parameters.join("&");
        }
        return url;
    }
    errorHandler(error:Response):Observable<DaedelusError>{
        console.log('%c Error: '+ error.status+' : '+ error.url + ' - '+ error.statusText, 'background: #FFCCCC; color: #ff0033');
        console.log(error);
        if(error.status === 404){
            return throwError(new ResourceNotFoundError(error.json()))
        }
        if(error.status === 400){
            return throwError(new BadRequestError(error.json()))
        }
        if(error.status === 401){
            return throwError(new UnauthorizedAccessError(error.json()))
        }
        if(error.status === 410){
            return throwError(new ResourceGoneError(error.json()))
        }
        if(error.status === 502){
            return throwError(new ServiceUnavailableError(error.json()))
        }
        return throwError(new DaedelusError(error));
    }
}