import { CommonModule } from '@angular/common';
import { Component, Input, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
import { CoreApiAccountService } from '../../services/core/core-api.service';
import { User } from '../../KanbanService';

@Component({
    selector: 'findUser-dialog',
    templateUrl: './findUser.component.html',
    styleUrls: ['./findUser.scss']
})
export class FindUserDialogComponent{

    public message = "";
    public title = "";
    public subtitle = null;
    public confirm = false;
    public standard = true;
   
    public customOptions;
    public selectedUsers = [];
    @ViewChild('stakeholders') stakeholders;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any = {}, private dialogRef:MatDialogRef<FindUserDialogComponent>, private toasty:Toasty){
        
        this.message =  data.message || "Search for Users";
        this.title = data.title || "Find User";
        if(data.customOptions){
            this.standard = false;
            this.customOptions = data.customOptions;
        }
    }
    addUser($event){
        this.selectedUsers.push($event);
    }
    removeStakeholder(user){
        let index = this.selectedUsers.findIndex(_user=>user.id==_user.id);
        if(index > -1){
            this.selectedUsers.splice(index, 1);
        }
    }
    closeTrue(){
        this.dialogRef.close(this.selectedUsers);
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}

export interface FindUserDialogOptions{
    message?;
    title?;
    confirm?:boolean;
    subtitle?;
    customOptions?:Array<string>;
}