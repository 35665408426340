export enum TicketStatus{
    open,
    design,
    devReady,
    pending,
    progress,
    techHold,
    woam,
    approval,
    closed
}
export var TICKETSTATUS = [
    "open",
    "in design",
    "ready for dev",
    "pending",
    "in progress",
    "tech hold",
    "woam",
    "approval",
    "closed",
]
export var TICKETSTATUSLABELS = [
    "Open",
    "With Grahpics",
    "Review for Dev",
    "Send to Developer",
    "Start Working",
    "Tech Hold",
    "Send to WOAM",
    "Completed",
    "Closed"
];
export enum StatusForCMS {
    pending = "In Que",
    progress = "Active",
    techHold = "On Pause",
    woam = "Needs Response",
    approval = "Needs Review"
}
export enum StatusForDev {
    pending = "Pending",
    progress = "Active",
    techHold = "On Pause",
    woam = "WOAM",
    approval = "Completed"
}
export var DEV_TICKETSTATUS_LABELS = [

];