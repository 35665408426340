import { SingleTicketView } from './singleTicket/singleTicket.component';
import { AdminGuard } from './shared/securityAuth/adminGuard';
import { KanbanSettings } from './admin/settings/settings.component';
import { AccountsComponent } from './admin/accounts/accounts.component';

import { RouterModule } from '@angular/router';

import { BfAuthGuard } from "@brafton/skynet-angular-security-service";
import { AllTicketsComponent } from './ticketBoard/allTickets/allTickets.component';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnassignedTicketsComponent } from './ticketBoard/unassignedTickets/unassignedTickets.component';
import { TicketBoardComponent } from './ticketBoard/ticketBoard.component';
import { ActivationGuard } from './shared/activationGuard';
import { SingleAccountComponent } from './admin/accounts/singleAccount/singleAccount.component';
import { TicketView } from './ticket/ticket.component';

export var Routes = RouterModule.forRoot([
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [BfAuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [BfAuthGuard,AdminGuard]
    },
    {
        path: 'accounts',
        canActivate: [BfAuthGuard],
        children: [
            {
                path: "",
                component: AccountsComponent,
                canActivate: [BfAuthGuard],

            },
            {
                path: ":accountId",
                component: SingleAccountComponent,
                canActivate: [BfAuthGuard],

            }
        ]
    },
    {
        path: 'settings',
        component: KanbanSettings,
        canActivate: [BfAuthGuard, AdminGuard]
    },
    {
        path: 'ticketboard',
        component: TicketBoardComponent,
        canActivate: [BfAuthGuard],
        children: [
            {
                path: "unassigned",
                component: UnassignedTicketsComponent
            },
            {
                path: "mine",
                component: AllTicketsComponent
            },
            {
                path: "mine/:ticketId",
                component: AllTicketsComponent
            },
            {
                path: "team",
                component: AllTicketsComponent
            },
            {
                path: "all",
                component: AllTicketsComponent
            }
        ]
    },
    {
        path: 'ticket/:ticketId',
        component: TicketView,
        canActivate: [BfAuthGuard]
    },
    {
        path: '',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
])
