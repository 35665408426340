import { LinkifyPipe } from './components/likify-pipe/linkify.pipe';
import { CreateAccountNoteDialog } from './components/accountNotes/createAccountNote/createAccountNote.component';
import { AccountNoteService } from './services/accountNote.service';
import { AccountNotesComponent } from './components/accountNotes/accountNotes.component';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material';
import {MatDialogModule, MatIconModule} from '@angular/material';

import { TicketService } from './services/ticket.service';
import { UserService } from './services/user.service';
import { FileService } from './services/file.service';
import { AccountService } from './services/account.service';
import { WorkService } from './services/work.service';
import { TagsService } from './services/tags.service';

export * from './models/responseModels/Asset';
export * from './models/responseModels/Client';
export * from './models/responseModels/Comment';
export * from './models/responseModels/Ticket';
export * from './models/responseModels/User';
export * from './models/responseModels/Account';
export * from './models/responseModels/WorkCategory';
export * from './models/interfaces/responseModels/_Tag';

export * from './models/requestCreateModels/TicketCreateModel';
export * from './models/requestCreateModels/UserCreateModel';
export * from './models/requestCreateModels/AccountCreateModel';
export * from './models/requestCreateModels/WorkCategoryCreateModel';
export * from './models/interfaces/requestCreateModels/_TagCreateModel';

export * from './services/ticket.service';
export * from './services/user.service';
export * from './services/file.service';
export * from './services/account.service';
export * from './services/work.service';
export * from './services/tags.service';

export * from './models/enums/Department';
export * from './models/enums/EnumHelper';
export * from './models/enums/TicketPriority';
export * from './models/enums/TicketStages';
export * from './models/enums/TicketStatus';
export * from './models/enums/TicketWorkType';

export * from './models/ContractDefinitions';

export * from './components/accountNotes/accountNotes.component';
export * from './components/accountNotes/createAccountNote/createAccountNote.component';

export * from './components/likify-pipe/linkify.pipe';
@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule
  ],
  declarations: [
    AccountNotesComponent,
    CreateAccountNoteDialog,
    LinkifyPipe
  ],
  exports: [
    AccountNotesComponent,
    CreateAccountNoteDialog,
    LinkifyPipe
  ],
  entryComponents: [CreateAccountNoteDialog]
})
export class KanbanServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: KanbanServiceModule,
      providers: [
        TicketService,
        UserService,
        FileService,
        AccountService,
        WorkService,
        TagsService,
        AccountNoteService
      ]
    };
  }
}
