import { Component, ViewChild } from '@angular/core';
import { CoreApiAccountService } from 'app/services/core/core-api.service';
import { MatTableDataSource, MatPaginator, MatSortModule, MatSort } from '@angular/material';
import { ActivatedRoute, Params, Router, NavigationStart } from '@angular/router';

@Component({
    selector: 'accounts-component',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent{
    displayedColumns = ['id','name', 'Brand','Status', 'SalesforceId', 'Actions'];
    dataSource: MatTableDataSource<any>;
    public allAccounts;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(public coreApiService:CoreApiAccountService,private _router:Router){
        this.dataSource = new MatTableDataSource<Element>([]);
        this.dataSource.sort = this.sort;
    }
    ngOnInit(){
        if(this.coreApiService.isReady){
            this.getAccounts();
        }else{
            this.coreApiService.authenticated().subscribe(
                auth=>{
                    if(auth){
                        this.getAccounts();
                    }
                }
            )
        }
    }
    getAccounts(){
        this.coreApiService.listAccounts().subscribe(accounts=>{
            console.log(accounts);
            this.dataSource = new MatTableDataSource<Element>(accounts.items);
            this.dataSource.paginator = this.paginator;
            let pageSize = accounts.totalItems;
            
            this.coreApiService.listAccounts({pageSize: pageSize}).subscribe(fullList=>{
                console.log(fullList);
                this.allAccounts = fullList.items;
                this.dataSource = new MatTableDataSource<Element>(fullList.items);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

            })
        })
    }
    ngOnChange(){
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }
    navigate(pageName:string, parameters:Object = null){
        if(parameters){
            this._router.navigate([pageName], {queryParams: parameters});
        }else{
            this._router.navigate([pageName]);
        }
    }
    pageEvent($event){
        console.log($event);
    }
    altEvent($event){
        console.log($event);
    }
    openAccount(account){
        console.log(account);
        this.navigate('accounts/'+account.id);
    }
    searchForAccount($event, inputField){
          // this.coreApiService.listUserByQuery($event)
        // console.log($event, $event.keyCode, inputField);
        if($event.keyCode == 40){
            return;
        }
        var filteredAccounts = this.allAccounts.filter(account=>{
            return account.name.toLowerCase().includes(inputField.value.toLowerCase());
        })
        this.dataSource = new MatTableDataSource<Element>(filteredAccounts);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
}