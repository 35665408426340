import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Component, Inject, Input } from '@angular/core';
@Component({
    selector: 'new-account-note-dialog',
    templateUrl: './createAccountNote.component.html',
    styleUrls: ['./createAccountNote.scss']
})
export class CreateAccountNoteDialog{
    public existingComment:string = "";
    constructor( @Inject(MAT_DIALOG_DATA) data, 
    public popUp: MatDialog,
    private dialog:MatDialogRef<CreateAccountNoteDialog> ){
        // console.log(data);
        if(data && data.note){
            this.existingComment = data.note.note;
        }
    }

}