import { Component } from '@angular/core';

@Component({
    selector: 'admin-settings',
    templateUrl: './views/settings/settings.html',
    styleUrls: ['./views/settings/settings.scss']
})
export class KanbanSettings{
    public activeSetting = "";
    constructor(){}

    openSettings(setting){
        this.activeSetting = setting;
    }
    
}