
// import { PermissionSet } from 'app/shared/authenticatedUser.service';
import { PhaseConfirmationDialogComponent } from './../shared/phaseConfirmation/phaseConfirmation.component';
import { atlasPhasesToTasks } from './../shared/utils';
import { PhaseCompletedDialogComponent } from './../shared/phaseCompleted/phaseCompleted.component';
import { TechDeliverableService, CompletePhaseRequest, DeliverableService, DeliverableCriteria, SearchableField, CriteriaTarget, WorkReadyStatus, WorkTypeStub } from '@brafton/deliverables-client';
import { WorkTypePopUpDialogComponent } from './workTypePopup/workTypePopup.component';
import { WorkType, WORKTYPE } from './../KanbanService/models/enums/WorkType';
import { TICKETSTATUSLABELS } from './../KanbanService/models/enums/TicketStatus';
import { CoreApiAccountService } from './../services/core/core-api.service';
import { EventService } from './../shared/event.service';
import { colorSets } from '@swimlane/ngx-charts/release/utils';
import { TicketUpdateModel } from './../KanbanService/models/requestUpdateModels/TicketUpdateModel';
import { Contract } from './../KanbanService/models/ContractDefinitions';
import { Subject, Observable, Subscription } from 'rxjs';
import { map,takeUntil, take } from 'rxjs/operators';
import { FindUserDialogComponent } from './../shared/findUser/findUser.component';
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from './../shared/confirmation/confirmation.component';
import { TextAreaPopUpDialogComponent, TextAreaPopUpDialogOptions } from './../shared/textareaPopUp/textAreaPopUp.component';
import { AuthenticatedUser, PermissionSet } from './../shared/authenticatedUser.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource, MatSort } from '@angular/material';
import {Location } from '@angular/common';
import { Component, Inject, ElementRef, EventEmitter, Output, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Department, Ticket, TicketStatus, TICKETSTATUS, TicketService, Comment, TicketWorkType, TicketPriority, UserService, User, TicketCreateModel, TICKETWORKTYPE, WorkService } from '../KanbanService';
import { CommentCreateModel } from '../KanbanService/models/requestCreateModels/CommentCreateModel';
import { FileUploadComponent } from '../shared/fileUploader/fileUpload.Component';
import { TextNumberPopUpDialogComponent, TextNumberPopUpDialogOptions } from '../shared/textnumberPopUp/textNumberPopUp.component';
import { Toasty } from '../shared/toastNotifications/toasty.service';
import * as moment from 'moment';
import { TicketActions } from './ticketActionsEnum';
import {types} from '../shared/assetTypes';
import { TicketListService } from 'app/shared/ticketListService';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';
import { Task } from 'app/KanbanService/models/responseModels/Task';
import { TextCalanderPopUp } from 'app/shared/textCalanderPopUp/textCalanderPopUp';
import { NewTaskDialog } from 'app/tasks/newTask/newTask.componet';
import { TagsFormComponent } from 'app/admin/settings/tags.form.component';
import * as CanvasJS from '../shared/canvasjs.min';
import { Timespent } from 'app/KanbanService/models/interfaces/responseModels/_Ticket';
import { DaedelusError } from 'app/KanbanService/shared/error.handler';
import { update } from 'lodash';
import { PassShackService } from 'app/services/core/pass-shack.service';
import { TaskType } from 'app/KanbanService/models/enums/TaskTypes';
import { ATLASMAP } from '../shared/utils';
import { AssetFilesService, AssetSetsService } from '@brafton/assets-api';
@Component({
    selector: 'ticket-single-view',
    templateUrl: './single.Ticket.component.html',
    styleUrls: ['./single.Ticket.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SingleTicketView{
    // @ViewChild('hiddenScope') hiddenScope;
    public scheme  = {};
    public pieChartData = [

    ];
    @Output() ticketChange = new EventEmitter();
    public displayOptions = false;
    public ticket:Ticket;
    public ticketComments:Array<Comment> = [];
    public TICKETSTATUS = TICKETSTATUS;
    public TicketStatus = TicketStatus;
    public TICKETPRIORITY = TicketPriority;
    public Department = Department;
    // public userPermissions = "";
    public checkPermission;
    public PermissionSet = PermissionSet;
    public time:Timespent = {};
    public currentUserId = null;
    public currentUserEmail = null;
    public location = {
        bar: 0,
        dropdown: 1
    }
    public ATLASMAP = ATLASMAP;
    public TICKETACTIONS:Array<ActionsObject> = [];
    private _TICKETACTIONS:Array<ActionsObject> = [
        {
            name: "Update Scope",
            permission: [PermissionSet.isStakeholder],
            method: this.updateScope.bind(this),
            info: "Update the scope of this ticket",
            icon: 'edit',
            location: this.location.bar,
            condition: (action, location)=>{
                if(location === this.location.bar && this.ticket.status != TicketStatus.closed){
                    return true;
                }
                return false;
            }
        },
        {
            name: "Add Stakeholder",
            permission: [PermissionSet.isStakeholder],
            method: this.addStakeholder.bind(this),
            info: "Add additional stakeholders to this ticket",
            icon: 'group_add',
            location: this.location.bar,
            condition: (action,location)=>{
                return location === this.location.bar && this.ticket.status != TicketStatus.closed;
            }
        },
        {
            name: "Connect to Daedelus",
            permission: [PermissionSet.isStakeholder],
            method: this.connectToDaedelus.bind(this),
            info: "",
            icon: 'compare_arrows',
            location: this.location.dropdown,
            condition: function(action, location){
                if(this.ticket.daedelusId){
                    return false;
                }
                return true;
            }.bind(this)
        },
        // {
        //     name: "Create Daedelus Feedback",
        //     permission: [PermissionSet.isStakeholder],
        //     method: this.createDaedelusProject.bind(this),
        //     info: "",
        //     icon: 'room_preferences',
        //     location: this.location.dropdown,
        //     condition: function(action,location){
        //         if(this.ticket.daedelusId){
        //             return false;
        //         }
        //         return true;
        //     }.bind(this)
        // },
        // {
        //     name: "Connect to Trello",
        //     permission: [PermissionSet.isStakeholder],
        //     method: this.connectToTrello.bind(this),
        //     info: "",
        //     icon: 'compare_arrows',
        //     location: this.location.dropdown,
        //     condition: function(action, location){
        //         if(this.ticket.trelloId){
        //             return false;
        //         }
        //         return true;
        //     }.bind(this)
        // },
        // {
        //     name: "Connect to Salesforce",
        //     permission: [PermissionSet.isStakeholder],
        //     method: this.connectToSalesforce.bind(this),
        //     info: "",
        //     icon: 'compare_arrows',
        //     location: this.location.dropdown,
        //     condition: function(action, location){
        //         if(this.ticket.salesforceId){
        //             return false;
        //         }
        //         return true;
        //     }.bind(this)
        // },
        // {
        //     name: "Connect to Atals",
        //     permission: [PermissionSet.isStakeholder],
        //     method: this.connectToAtlas.bind(this),
        //     info: "",
        //     icon: 'compare_arrows',
        //     location: this.location.dropdown,
        //     condition: function(action, location){
        //         if(this.ticket.atlasId){
        //             return false;
        //         }
        //         return true;
        //     }.bind(this)
        // },
        {
            name: "Update Work Category",
            permission: [PermissionSet.isAdmin],
            method: this.updateWorkType.bind(this),
            info: "Change the work category.",
            icon: 'category',
            location: this.location.bar,
            condition: (action, location)=>{
                return this.authenticatedUser.checkPermission([PermissionSet.isTechTeam])
            }
        },
        {
            name: "Share Ticket URL",
            permission: [PermissionSet.isStakeholder],
            method: this.shareUrl.bind(this),
            info: "Copy the ticket RUL.",
            icon: 'share',
            location: this.location.bar,
            condition: function(action, location){
                return true;
            }.bind(this)
        },
        {
            name: "Leave Ticket Message",
            permission: [PermissionSet.isStakeholder],
            method: this.ticketMessage.bind(this),
            info: "Leave a ticket message, but leave ticket responsibility alone",
            icon: 'email',
            location:this.location.bar,
            condition: (action,location)=>{
                return location === this.location.bar && this.ticket.status != TicketStatus.closed;
            }

        },
        {
            name: "Move ticket",
            permission: [PermissionSet.isStakeholder],
            method: this.moveTicket.bind(this),
            info: "Change the ticket responsibility and provide a reason why",
            icon: 'swap_horiz',
            location:this.location.bar,
            condition: (action,location)=>{
                return location === this.location.bar && this.ticket.status != TicketStatus.closed;
            }
        },
        // {
        //     name: "Duplicate Ticket",
        //     permission: [PermissionSet.isStakeholder, PermissionSet.modifyTicketQuote],
        //     method: this.duplicateTicket.bind(this),
        //     info: "Create a duplicate of this ticket",
        //     icon: 'file_copy',
        //     location: this.location.dropdown,
        //     condition: ()=>false
        // },
        {
            name: "Attach Files",
            permission: [PermissionSet.isStakeholder],
            method: this.uploadFiles.bind(this),
            info: "Attach files to this ticket for use on this project",
            icon: 'attachment',
            location: this.location.dropdown,
            condition: ()=>true
        },
        {
            name: "Immediate Service?",
            permission: [PermissionSet.isStakeholder],
            method: this.makeSwimmer.bind(this),
            info: "Request an escalation of this ticket beyond its current Priority status..",
            icon: 'notification_important',
            location: this.location.dropdown,
            condition: ()=>{
                if(this.ticket.swimmer || this.ticket.status == TicketStatus.closed){
                    return false;
                }
                return true;
            }
        },
        {
            name: "Change Developers",
            permission: [PermissionSet.modifyTicketDevelopers],
            method: this.changeDeveloper.bind(this),
            info: "Update the Developer responsibile for this ticket",
            icon: 'code',
            location: this.location.dropdown,
            condition: (action,location)=>{
                return this.ticket.status != TicketStatus.closed && this.authenticatedUser.checkPermission([PermissionSet.isAdmin, PermissionSet.modifyTicketDevelopers]);
            }
        },
        {
            name: "Provide Quote",
            permission: [PermissionSet.isStakeholder, PermissionSet.modifyTicketQuote],
            method: this.provideQuote.bind(this),
            info: "Provide a Quoted scope and cost for this ticket",
            icon: 'attach_money',
            location: this.location.dropdown,
            condition: ()=>{
                return this.ticket.status != TicketStatus.closed && this.authenticatedUser.checkPermission([PermissionSet.modifyTicketQuote]) && this.ticket.isQuote;
            }
        },
        {
            name: "Request QA",
            permission: [PermissionSet.isStakeholder, PermissionSet.modifyTicketQuote],
            method: this.requestQA.bind(this),
            info: "Request a peer QA.",
            icon: 'find_replace',
            location: this.location.dropdown,
            condition: ()=>{
                return this.ticket.status != TicketStatus.closed && this.authenticatedUser.checkPermission([PermissionSet.modifyTicketQuote]);
            }
        },
        {
            name: "Set Expected Hours",
            permission: [PermissionSet.isStakeholder, PermissionSet.modifyTicketQuote],
            method: this.setExpectedHours.bind(this),
            info: "Provide an expected number of hours to complete this ticket",
            icon: 'hourglass_empty',
            location: this.location.dropdown,
            condition: ()=>{
                return this.authenticatedUser.checkPermission([PermissionSet.modifyTicketQuote]) && !this.ticket.unitsAllocated;
            }
        },
        {
            name: "Set Due Date",
            permission: [],
            method: this.setDueDate.bind(this),
            info: "Set a Due Date for this ticket",
            icon: "access_alarm",
            location: this.location.dropdown,
            condition: ()=>{
                return this.ticket.status != TicketStatus.closed && this.authenticatedUser.checkPermission([PermissionSet.isAdmin]);
            }
        },
        //@todo add actions
        /**
         * setTags: tag this ticket with categories to help with reporting and working out new quotes.
         * itemizedFeedback: Provide an interface simpilar to the edit round for daedelus including upload formated doc or google sheet.
         */
        {
            name: "Tags List",
            permission: [PermissionSet.modifyTicketQuote],
            method: this.addTags.bind(this),
            info: "Add and View tags to help clasify this tickets work types",
            icon: "label",
            location: this.location.bar,
            condition: (action,location)=>{
                return location === this.location.bar && this.authenticatedUser.checkPermission([PermissionSet.modifyTicketQuote]);
            }
        },
        {
            name: "Adjust Units",
            permission: [],
            method: this.adjustUnits.bind(this),
            info: "Adjust allocated Units. Does not change paid units, only allocated units",
            icon: "tune",
            location: this.location.dropdown,
            condition: ()=>{
                return this.authenticatedUser.checkPermission([PermissionSet.isAdmin]);
            }
        },
        {
            name: "Delete Ticket",
            permission: [PermissionSet.isAdmin],
            method: this.deleteTicket.bind(this),
            info: "Delete this ticket",
            icon: 'close',
            location: this.location.dropdown,
            condition: ()=>{
                return this.authenticatedUser.checkPermission([PermissionSet.isAdmin]);
            }
        },
        // {
        //     name: "Convert to Work",
        //     permission: [PermissionSet.isStakeholder],
        //     method: this.convertToWork.bind(this),
        //     info: "Close this ticket and open work ticket",
        //     icon: "open_in_new",
        //     location: this.location.bar,
        //     condition: ()=>{
        //         return this.ticket.isQuote;
        //     }
        // },
        {
            name: "Approve & Close",
            permission: [PermissionSet.isAdmin, PermissionSet.isTechTeam],
            method: this.closeTicket.bind(this),
            info: "Mark this ticket completed.",
            icon: 'thumb_up',
            location: this.location.bar,
            condition: ()=>{
                return this.ticket.status != TicketStatus.closed && this.authenticatedUser.checkPermission([PermissionSet.isAdmin, PermissionSet.isTechTeam], this.ticket.stakeholders);
            }
        }
    ]
    public warnings = {
        overdue: false,
        halfway: false,
        nearComplete: false
    };
    private subs:Subscription[] = [];
    public totalTimeChart:CanvasJS = null;
    public progressChart:CanvasJS = null;
    public moveHistoryDataSource:MatTableDataSource<any>;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns = ["from", "to", "hours"];
    public taskUpdate = 0;
    private deleted = false;
    private destroySubs$ = new Subject<boolean>();
    public passShackResponse:{id:number,creds:Array<{password:string,username:string,location:string,service:string,notes:string}>} = null;
    public warning = {
        title: null,
        message: null
    };
    public atlasAssets = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) private data,
        private toasty:Toasty,
        private _dialog:MatDialogRef<SingleTicketView>,
        private ticketService:TicketService,
        private ticketListService:TicketListService,
        private workCategoryService:WorkService,
        private eventService:EventService,
        private userService:UserService,
        private coreApiService:CoreApiAccountService,
        private techDeliverablesService:TechDeliverableService,
        private deliverablesService:DeliverableService,
        private assetService:AssetFilesService,
        private assetSetsService:AssetSetsService,
        private authenticatedUser:AuthenticatedUser,
        private passShackService:PassShackService,
        private dialog:MatDialog,
        private _location:Location){
        if(data.warnings){
            this.warnings = data.warnings;
        }
        this.ticket = data.ticket;
        this.checkPermission = authenticatedUser.checkPermission.bind(authenticatedUser);
        this.currentUserId = this.authenticatedUser.currentUser.id;
        this.currentUserEmail = this.authenticatedUser.currentUser.email;
        this.TICKETACTIONS = this._TICKETACTIONS;
        
    }

    ngOnInit(){
        console.log(this.ticket);
        this.scheme = colorSets.find(s=>s.name==='vivid');
        //get ticket comments
        this.ticketService.listTicketComments(this.ticket.id).pipe(take(1)).subscribe(
            comments=>{

                this.ticketComments = comments.sort((a,b)=>{
                    if(a.createdDate < b.createdDate){
                        return 1;
                    }
                    if(a.createdDate > b.createdDate){
                        return -1;
                    }
                    return 0;
                });
                
                this.subs.push(this.eventService.newTicketCommentAsync().pipe(takeUntil(this.destroySubs$)).subscribe(
                    notificationEvent=>{
                        if(notificationEvent.entity == this.ticket.id && this.ticketComments.findIndex(_com=>_com.id==notificationEvent.data.comment.id) == -1){
                            this.ticketComments.splice(0, 0, new Comment(notificationEvent.data.comment));
                        }
                    }
                ))
            }
        )
        //get ticket creds @todo add passshack service
        if(this.ticket.account['id']){
            this.passShackService.getClientCreds(this.ticket.account['id']).subscribe(
                response=>{
                    if(response){
                        this.passShackResponse = response;
                    }
                    
                },
                error=>{
                    
                }
            )
        }
        //calculate time in progress if ticket is in progress. For display purposes conly
        this.time = Object.assign({},this.ticket.time);
        if(TicketStatus.progress === this.ticket.status){
            var getBusinessDays = function(startDate, endDate){
                var startDateMoment = moment(startDate);
                var endDateMoment = moment(endDate)
                var days = Math.round(startDateMoment.diff(endDateMoment, 'days') - startDateMoment .diff(endDateMoment, 'days') / 7 * 2);
                if (endDateMoment.day() === 6) {
                  days--;
                }
                if (startDateMoment.day() === 7) {
                  days--;
                }
                return days;
              }
              try{
                let movedToTimestamp = this.ticket.lastMoved['to'].timestamp;
                let currentTime = moment( moment().unix() * 1000);
                
                let duration = moment.duration(currentTime.diff(movedToTimestamp));
                let totalTimeInDays = duration.asDays();

                var hours = 0;
                if(totalTimeInDays > 1){ //Ticket has been in progress for at least a full day and therefore time caluclation needs to adjust for business hours.
                    let totalWorkingDays = getBusinessDays(currentTime,movedToTimestamp);
                    let diff = parseFloat(((totalTimeInDays - totalWorkingDays) < 1? totalTimeInDays - totalWorkingDays :(totalTimeInDays - totalWorkingDays) - 1).toFixed());
                    let days = totalTimeInDays - diff;
                    hours = parseFloat((days * 8).toFixed(2)) + this.ticket.time['progress'];
                }else{
                    hours = duration.asHours() + this.ticket.time['progress'];
                }
                this.time['progress'] = hours;
              }catch(e){
                  console.log(e);
              }

        }
        if(this.ticket.unitsAdjusted){ //@todo if the unitsadjusted exist than recheck for the warnings

        }

        //@todo add check for duedate
        if(this.authenticatedUser.checkPermission([PermissionSet.isStakeholder, PermissionSet.modifyTicketQuote], this.ticket.stakeholders) && this.warnings){
            if(this.warnings.halfway){
                this.warning.message = "You have reached the halfway point of the work.  If you don't beleive you will be done within the alloted time please reach out to the CMS to discuss options";
                this.warning.title = "Halfway Point"
               
            }
            if(this.warnings.nearComplete){
                this.warning.message = `This ticket is nearing its maximum allowed hours. A total of ${this.ticket.time['progress']} of the alloted ${ this.ticket.unitsAllocated / 2} have been used.  Please wrap up this project. If you don\'t beleive you can complete this work in the time alloted you need discuss with your manager to make arrangments with the CMS and client`;
                this.warning.title = "Nearing fulfilled allocation"

                
            }
            if(this.warnings.overdue){
                this.warning.message = `This ticket is overdue. Total time spent on this is currently ${this.ticket.time['progress']} while only ${ this.ticket.unitsAllocated / 2} was alloted for this work.  Arraingments should have been made with your manager and the client CMS prior to this warning.`;
                this.warning.title = "Ticket is Overdue"

                
            }
        }
        var totalTmpTime = 0.00;
        var altData = []
        for(let phase in this.time){
            this.time[phase] = parseFloat(this.time[phase]).toFixed(2);
            if(phase == "open"){
                continue;
            }
            totalTmpTime += parseFloat(this.ticket.time[phase]);
            let data = {
                name: phase,
                value: parseFloat(this.ticket.time[phase])
            };
            if(data.value > 0){
                let name = phase.replace(/([a-z])([A-Z])/g, '$1 $2');
                name = name.charAt(0).toUpperCase() + name.slice(1);
                var stat = TicketStatus[phase] == this.ticket['status'].toFixed();
                altData.push({name: name, y: data.value, exploded: stat});
            }
            this.pieChartData.push(data)
        }
        if(totalTmpTime == 0){
            let data = {
                name: 'open',
                value: 1
            };

            this.pieChartData.push(data)
        }
        
        this.totalTimeChart = new CanvasJS.Chart("chartContainer-totalTime", {
            // exportEnabled: true,
            animationEnabled: true,
            title:{
                text: "Time Breakdown"
            },
            legend:{
                cursor: "pointer"
            },
            data: [{
                type: "pie",
                showInLegend: true,
                toolTipContent: "{name}: <strong>{y} Hours</strong>",
                indexLabel: "{name}: {y} H",
                dataPoints: altData
     
            }]
        });
        this.totalTimeChart.render();
        
        var progressData = [{
            type: "bar",
            showInLegend: true,
            name: "Actual Units",
            color: "teal",
            
            dataPoints: [
                { y: this.ticket.time['progress'] * 2, label: "Unis Group",indexLabelOrientation: "vertical" }
    
            ]
        },
        {
            type: "bar",
            showInLegend: true,
            name: "Allocated Units",
            color: "silver",
            dataPoints: [
                { y: this.ticket.unitsAllocated? parseFloat(this.ticket.unitsAllocated.toString()) : 0, label: "Unis Group",indexLabelOrientation: "vertical" }
            ]
        }];
        if(this.ticket.unitsAdjusted){
            progressData.push({
                    type: "bar",
                    showInLegend: true,
                    name: "Adjusted Units",
                    color: "lightcoral",
                    dataPoints: [
                        { y: parseFloat(this.ticket.unitsAdjusted.toString()), label: "Unis Group",                    indexLabelOrientation: "vertical"}
                    ]
                })
        }
        var progressChart = new CanvasJS.Chart("chartContainer-progress", {
            animationEnabled: true,
            // exportEnabled: true,
            title:{
                text: "Unit Progress"
            },
            axisY: {
                title: "Units"
            },
            axisX: {
                // title: "Units Group"
            },
            legend: {
                cursor:"pointer",
                // itemclick : toggleDataSeries
            },
            toolTip: {
                shared: true,
                // content: toolTipFormatter
            },
            data: progressData
        });
        progressChart.render();
        

        //hit the core api and see if the account manager has changed so we can update the stakeholder and account manager automatically.
        this.coreApiService.getAccountInfo(this.ticket.account['id'], this.ticket.account['_brand']).pipe(takeUntil(this.destroySubs$)).subscribe(
            account=>{
                if( account.ownerAccountManager && (account.ownerAccountManager.email != this.ticket.accountManager.email) ){
                    //update the account manager and the stakeholders.
                    this.coreApiService.getUserProfileByEmail(account.ownerAccountManager.email).pipe(takeUntil(this.destroySubs$)).subscribe(
                        userProfile=>{
                            let updatedTicket:TicketUpdateModel = {};
                            updatedTicket.accountManager = userProfile;
                            updatedTicket.stakeholders = [...this.ticket.stakeholders, userProfile];
                            this.ticketService.updateTicket(this.ticket.id, updatedTicket).pipe(takeUntil(this.destroySubs$)).subscribe(
                                _updatedTicket=>{
                                    this.ticket = _updatedTicket;
                                }
                            )
                        }
                    )
                    
                }
            }
        )
        this.ticketService.getTicketById(this.ticket.id).subscribe(
            checkTicketDetails=>{
                if(checkTicketDetails[0] && (checkTicketDetails[0].lastModifiedDate != this.ticket.lastModifiedDate)){
                    this.ticket = checkTicketDetails[0];
                }   
            }
        )
        if(this.authenticatedUser.checkPermission([PermissionSet.isTechTeam])){
            this.checkForAtlasPhases(); 
        }
    }
    ngOnDestroy(){
        this.destroySubs$.next(true);
        this.destroySubs$.complete()
        this.destroySubs$.unsubscribe();
        this.subs.forEach(sub=>{
            sub.unsubscribe();
        })
        if(this.deleted){
            this._dialog.close(null);
        }else{
            this._dialog.close(this.ticket);
        }
    }
    getAtlasAssets(project){
        var outcomes_assets = project.outcomes;
        // console.log(outcomes_assets);
        var assetIds = outcomes_assets.map(outcome => outcome.objectId);
        assetIds = assetIds.filter(asset=>asset != null);
        // console.log(assetIds);
        this.assetSetsService.ListAsync(1, 20, null,null, assetIds).subscribe(files=>{
            // console.log(files);
            this.atlasAssets = files.items;
            // console.log(this.atlasAssets);
        })
        // outcomes_assets.forEach(asset => {
        //     console.log(asset);

        //     this.assetService.GetAsync(asset.objectId).subscribe(asset_info=>{
        //         console.log(asset_info);
        //     })    
        // });
        
    }
    checkForAtlasPhases(){
        this.techDeliverablesService.GetProjectAsync(this.ticket.atlasId).subscribe(
            (project)=>{
                console.log(project);
                this.getAtlasAssets(project);
                if(project && project.currentPhases.length > 0 && project.currentPhases[0].module.id == "Tech"){
                    var currentPhase;
                    if(project.currentPhases[0]['phases']){
                        currentPhase = project.currentPhases[0]['phases'].filter(phase=>phase.workReadyStatus.id == WorkReadyStatus.ReadyToWorkOn.id)[0];
                    }else{
                        currentPhase = project.phases.find(phase=>phase.workReadyStatus.id == WorkReadyStatus.ReadyToWorkOn.id);
                    }
                    console.log(currentPhase);
                    //@ts-ignore
                    if(currentPhase && currentPhase.module.id == 'Tech' && (currentPhase.owner.id == this.currentUserId || this.authenticatedUser.checkPermission([PermissionSet.isAdmin]))){
                        //check if there are phases to this phase?
                        //@ts-ignore
                        let index = this.ticket.tasks.findIndex(task => task.atlasPhaseId == currentPhase.id);
                        if(index < 0){
                            //@ts-ignore
                            // console.log(currentPhase['owner']);
                            var owner = currentPhase['owner']? currentPhase['owner'] : "";
                            // console.log(owner);
                            let options:ConfirmationDialogOptions = {
                                message: `It appears that a new untracked phase needs to be added to this kanban ticket from Atlas.<br/><br/>Do you want to add the atlas phase <b>${currentPhase.name}</b> to your ticket. It is currently assigned to <b>${owner.name}</b>.`,
                                title: `Add Atlas Phase for ${owner.name}`
                            }
                            //popup notice a new phase that should be assigned to dev.
                            
                            this.dialog.open(ConfirmationDialogComponent,{
                                data:options,
                                width:'500px'
                            }).afterClosed().subscribe(
                                response=>{
                                    if(response){
                                        if(owner && owner.$id){
                                            delete owner.$id;
                                            delete owner.team;
                                        }
                                        var task:_TaskCreateModel = {
                                            title: currentPhase.name,
                                            completed: false,
                                            atlasPhaseId: currentPhase.id,
                                            allocatedUnits: currentPhase.unitsInTotal.availableUnits,
                                            locked: false,
                                            assignedUser: owner,
                                            taskType: TaskType.atlas
                                            
                                        }
                                        this.ticketService.createNewTask(this.ticket.id, task).subscribe(
                                            task=>{
                                                this.ticket.tasks.push(task);
                                                this.markPhaseStarted(task);
                                            }
                                        )
                                    }
                                }
                            )
                            //add phase and mark as started.
                        }
                    }
                }
            }
        )
    }
    toggleHistory(){

    }
    buildHistoryTable($event){
        let moving = [...this.ticket.moveHistory, this.ticket.lastMoved];
        this.moveHistoryDataSource = new MatTableDataSource<any>(moving);
        this.moveHistoryDataSource.sort = this.sort;
    }
    getReadableHours(hours){
        return hours? hours.toFixed(2): 0;
    }
    /**
     * use popup with html editor to update scope
     */
    updateScope($event, action){
        this.displayOptions = false;
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Update Ticket Scope",
                placeholder: "Ticket Scope",
                value: this.ticket.scope
            },
            width: "50%"
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            (textContent:string|boolean)=>{
                if(!textContent){
                    return;
                }
                // console.log(textContent);
                let scope = textContent.toString();
                
                this.ticketService.updateTicket(this.ticket.id, {
                    scope
                }).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticketChange.emit({
                            action: TicketActions.UpdateScope,
                            data: {
                                scope: scope
                            },
                            ticket: updatedTicket
                        })
                        this.ticket = updatedTicket;
                        
                    }
                )
                // $event.source.value = null;
            }
        )
    }
    /**
     * serach for other users to add to this ticket.  
     */
    addStakeholder($event, action){
        this.displayOptions = false;
        this.dialog.open(FindUserDialogComponent,{
            data: {
                title: "Add Stakeholder"
            },
            width:"50%"
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            selectedUsers=>{
                if(selectedUsers.length == 0){
                    return;
                }
               selectedUsers.forEach(selectedUser=>{
                if(this.ticket.stakeholders.findIndex(user=>user.id == selectedUser.id) === -1){
                    this.ticketService.addStakeholder(this.ticket.id, selectedUser).pipe(takeUntil(this.destroySubs$)).subscribe(
                        _ticket=>{
                            if(_ticket instanceof DaedelusError){
                                this.toasty.error(`${selectedUser.name} is already a stakeholder on this ticket`, "Stakeholder exists");
                            }else{
                                this.ticket = <Ticket>_ticket;
                            }
                        }
                    )
                }else{
                   this.toasty.error(`${selectedUser.name} is already a stakeholder on this ticket`, "Stakeholder exists");
                }
               })
                // $event.source.value = null;
            }
        )
    }
    craftMessage(message = null, value = null):Observable<CommentCreateModel>{
        this.displayOptions = false;
        return this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Ticket Comment",
                message: message || "You can tag users by typing '@' along with the users name. Only tagged users will receive email messages.",
                placeholder: "Comment",
                value: value,
                users: this.ticket.stakeholders
            },
            width: "50%"
        }).afterClosed().pipe(map(
            
            (textContent:string|boolean)=>{
                
                if(textContent === false || !textContent){
                    return null;
                }
                textContent = textContent.toString();
                
                let createComment:CommentCreateModel = {
                    user: this.authenticatedUser.currentUser,
                    message: textContent,
                    createdDate: new Date().getTime(),
                    ticketId: this.ticket.id,
                };
                return createComment;
            }
        ));
    }
    updateTicketStatus(validOptions = null):Observable<any>{
        this.displayOptions = false;
        let phase = this.ticket.status;
        var inValid = {
            label: "Not Authorized",
            value: null};
        let validStatus = validOptions || this.getValidMovementOptions() || [inValid];
        return this.dialog.open(ConfirmationDialogComponent,{
            data: {
                title: "Move Ticket",
                message: `<p>Would you like to move this ticket out of the <i>"${TICKETSTATUS[phase]}"</i> stage?</p>`,
                customOptions: validStatus
            }
        }).afterClosed().pipe(map( selection=>{
            if(selection){
                if(selection !== inValid && selection !== "NO"){
                    if(selection == TicketStatus.approval || selection == TicketStatus.closed){
                        let closable = this.ticket.tasks? this.ticket.tasks.filter(task=>!task.completed): [];
                        if(closable.length > 0){
                            //a task is not completed.
                            let message = "You have tasks that are not completed. Please ensure you have finished this work to spec and mark all tasks as completed.";
                            let atlasPhases = closable.filter(task=>task.atlasPhaseId);
                            if(atlasPhases.length > 0){
                                message = "You have Atlas phases that need to be completed. Be sure to start and complete each phase individually under tasks before moving";
                            }
                            this.toasty.warning(message, "Unfinished Work");
                            return null;
                        }
                    }
                    var movement = {
                        from: phase,
                        to: selection
                    };
                    // let status = TICKETSTATUS.findIndex(_status=>_status == selection);
                    let status = selection;
                    //update the ticket status
                    this.ticketService.updateTicket(this.ticket.id, { status}).pipe(takeUntil(this.destroySubs$)).subscribe(
                        updatedTicket=>{

                            if(this.ticket.status === TicketStatus.open){
                                //status was open without a dev need to assign a dev.
                                // this.changeDeveloper(null);
                            }
                            this.ticket = updatedTicket;
                            
                            if(this.ticket.status === TicketStatus.progress && (!this.ticket.leadTime)){
                                this.setLeadTime();
                            }
                        }
                    )
                    return movement;
                }
                //reverse engineer the status enum
            }
            return null;
        }))
    }
    /**
     * use same popup from update scope but store the value elsewhere
     */
    ticketMessage($event){
        this.displayOptions = false;
        this.craftMessage().pipe(takeUntil(this.destroySubs$)).subscribe(
            createComment=>{
                if(createComment){
                    this.updateTicketStatus().pipe(takeUntil(this.destroySubs$)).subscribe(
                        move=>{
                            createComment.move = move;
                            
                            this.ticketService.createNewTicketComment(this.ticket.id, createComment).pipe(takeUntil(this.destroySubs$)).
                                subscribe(
                                    comment=>{
                                        if(comment.id){
                                            this.ticketComments.splice(0, 0, comment);
                                        }
                                    }
                                )
                            if(move){
                                if(move.to === TicketStatus.progress){
                                    this.checkForStartingAtlasPhases();
                                }else if(move.to === TicketStatus.woam){
                                    //check for completing atlas phases
                                }else if(move.to === TicketStatus.approval){
                                    //check for completing atlas phases
                                }
                            }

                        }
                    )     
                }           
            }
        )
        // $event.source.value = null;
    }
    
    getValidMovementOptions():Array<{label:string,value:any}>{
        var validStatus = TICKETSTATUSLABELS.map((item, index)=>{
            return {
                "label": item,
                "value": index
            };
        })
        validStatus = validStatus.filter(s=>s.value!==TicketStatus.open);
        validStatus = validStatus.filter(s=>s.value!==TicketStatus.closed);
        let No = {"label": "No", "value": false};
        if(!this.ticket.developer && !this.authenticatedUser.checkPermission([PermissionSet.isTechTeam])){
            return null;
        }
        if(this.ticket.status !== TicketStatus.open){
            let currentStatus = validStatus.findIndex(s=>s.value == this.ticket.status);
            validStatus.splice(currentStatus, 1);
        }
        validStatus = validStatus.filter(op=>op.value !== TicketStatus.closed);
        if( (this.ticket.status === TicketStatus.design) || (this.ticket.status === TicketStatus.devReady) ){
            //the status is either design or dev ready keep those options.
            return [...validStatus.filter(op=>op.value == TicketStatus.design || op.value == TicketStatus.devReady || op.value == TicketStatus.pending), No];
        }else{
            validStatus = validStatus.filter(op=>op.value !== TicketStatus.design);
            validStatus = validStatus.filter(op=>op.value !== TicketStatus.devReady);
        }
        //If user is on the tech team (10) return all remaining status
        if(this.authenticatedUser.currentUser.teamId == 10 ){
            validStatus = validStatus.map(item=>{
                if(item.value == TicketStatus.pending){
                    item.label = "Put In Pending";
                }
                return item;
            })
            return [...validStatus, No];
        }
        //since we know the user isn't a dev, superadmin, or dev admin we can remove progress,tech hold, and woam status'
        validStatus = validStatus.filter((s,i)=>s.value !== TicketStatus.progress);
        validStatus = validStatus.filter((s)=>s.value !== TicketStatus.techHold);
        validStatus = validStatus.filter((s)=>s.value !== TicketStatus.woam);
        validStatus = validStatus.filter((s)=>s.value !== TicketStatus.techHold);
        if(this.ticket.status == TicketStatus.techHold){
            validStatus = validStatus.map(item=>{
                        if(item.value == TicketStatus.pending){
                            item.label = "Nudge the Developer";
                        }
                        return item;
                    })
        }
        if(this.authenticatedUser.checkPermission([PermissionSet.readAllTickets, PermissionSet.isStakeholder], this.ticket.stakeholders)){
            return [...validStatus, No];
        }
        return null;
    }
    /**
     * use popup to ask where you want to move the ticket. First call this.ticketMessage and ask for a message
     */
    moveTicket($event){
  
        this.displayOptions = false;
        this.updateTicketStatus().pipe(takeUntil(this.destroySubs$)).subscribe(
            move=>{
                
                if(move){
                    var defaultMessage = null;
                    if(move.from != TicketStatus.open){
                        if(move.to == TicketStatus.progress){
                            if(move.to == TicketStatus.progress && !this.ticket.unitsAllocated && this.ticket.workCategory !== "Quote"){
                                var obs = this.setExpectedHours(null);
                                obs.pipe(takeUntil(this.destroySubs$)).subscribe(res=>{
                                    this.checkForStartingAtlasPhases();
                                })
                                
                            }
                            this.checkForStartingAtlasPhases();
                            return;
                        }
                        //do the message deal.
                        this.craftMessage("Provide context as to why the ticket is being moved", defaultMessage).pipe(takeUntil(this.destroySubs$)).subscribe(
                            msg=>{

                                if(msg){
                                    msg.move = move;
                                    this.ticketService.createNewTicketComment(this.ticket.id, msg).pipe(takeUntil(this.destroySubs$)).
                                        subscribe(
                                            comment=>{
                                                if(comment.id){
                                                    this.ticketComments.splice(0, 0, comment);
                                                }
                                            }
                                        )
                                }
                                
                                //@todo check if we have started but not completed phases and if so ask if this phase is completed yet?
                                if(move.to === TicketStatus.woam){
                                    //check for completing atlas phases
                                    this.checkForCompletingAtlasPhase();
                                }else if(move.to === TicketStatus.approval){
                                    //check for completing atlas phases
                                    this.checkForCompletingAtlasPhase();
                                }
                            }
                        )
                    }else{
                        
                        if(this.ticket.developer == null){
                            this.changeDeveloper(null);
                        }
                    }
                }
            }
        )
        // $event.source.value = null;
    }
    /**
     * use popup to handle file uploads
     */
    uploadFiles($event){
        this.displayOptions = false;
        //popup for file upload
        this.dialog.open(FileUploadComponent,{
            data:{
                ticketId: this.ticket.id
            },
            width: '50%'
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            filesInfo=>{
                console.log(filesInfo);
               //add the file url to the list of uploaded files.
               if(filesInfo && filesInfo.length > 0){
                if(!this.ticket.assets){
                    this.ticket.assets = [];
                }
                this.ticket.assets.push(...filesInfo)
                this.ticketService.attachFilesToTicket(this.ticket.id, filesInfo).pipe(takeUntil(this.destroySubs$)).subscribe(
                    resp=>{
                        console.log(resp);
                    }
                );
               }
            }
        )
        // $event.source.value = null;
    }
    /**
     * mark ticket as a swimmer
     */
    makeSwimmer($event){
        this.displayOptions = false;
        //@todo change to send request notification rather than just make a swimmer. 
        var updateTicket:TicketUpdateModel = {};
        updateTicket.swimmer = true;
        updateTicket.priority = this.ticket.priority - 1 < 0? 0 : this.ticket.priority - 1;
        this.ticketService.updateTicket(this.ticket.id, updateTicket).pipe(takeUntil(this.destroySubs$)).subscribe(
            updatedTicket=>{
                this.ticket = updatedTicket;

                this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: "Ticket Prioritization",
                        message: "<p>A request has been sent to prioritize this ticket.</p>", 
                        customOptions: [{"label": "Ok", "value": "Ok"}]
                    }
                });
            }
        )
        // $event.source.value = null;
    }
    /**
     * use popup to give list of devs and select the dev
     */
    changeDeveloper($event){
        this.displayOptions = false;
        this.coreApiService.listTechnicalServicesProfiles().pipe(takeUntil(this.destroySubs$)).subscribe(
           (devs:Array<User>)=>{
                this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: "Choose a Developer",
                        customOptions: devs.map( user=>{
                            return {
                                "label": user.name,
                                "value": user.email
                            };
                        })
                    }
                }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
                    res=>{
                        var previousDev = this.ticket.developer;
                        if(res){
                            let choosenDev = devs.find( _dev=>{
                                return _dev.email === res
                            });
                            
                            // let devIndex = this.ticket.stakeholders.findIndex(u=>u.email==choosenDev.email);
                            // this.ticket.stakeholders.splice(devIndex, 1);
                            this.ticketService.updateDeveloper(this.ticket.id,choosenDev).pipe(takeUntil(this.destroySubs$)).subscribe(
                                devTicket=>{
                                    this.ticket = devTicket;
    
                                }
                            )
                        }
                    }
                )
            }
        );
        if($event){
            // $event.source.value = null;
        }
    }
    /**
     * use popup to bring up html editor and inputs for hours and dollar amount
     */
    provideQuote($event){
        this.displayOptions = false;
        // $event.source.value = null;
        if(!this.ticket.isQuote){
            this.toasty.warning("This is not a quote ticket. You can only provide quotes on quote tickets");
            return;
        }
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Input Scope",
                placeholder: "Developer Statement of work to complete",
                message:"Provide a complete and thourough statement of work you will be completing. You can tag users by typing '@' along with the users name. Only tagged users will receive email messages.",
                users: this.ticket.stakeholders
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            scope=>{
                if(scope){
                    //providing a scope of work next provide an amount. 
                    this.dialog.open(TextNumberPopUpDialogComponent, {
                        data: {
                            title: "Quote Hours",
                            message: "How many Hours do you expect this work to take?",
                            placeholder: "Hours"
                        }
                    }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
                        hours=>{
                            if(hours){
                                //set the scope of work as the latest comment adding in the quoted hours and calculate the dollar amount
                                // hours * 200;
                                let createComment:CommentCreateModel = {
                                    user: this.authenticatedUser.currentUser,
                                    message: scope + "<b>Please note that the complete quote details are listed in the ticket under 'Review Quote'<br/> Total of "+ hours * 2 + " Units .<br/>",
                                    createdDate: new Date().getTime(),
                                    ticketId: this.ticket.id,
                                };
                                this.ticketService.createNewTicketComment(this.ticket.id, createComment).pipe(takeUntil(this.destroySubs$)).subscribe(
                                    comment=>{
                                        this.ticketComments.splice(0, 0, comment);
                                        var ticketUpdate:TicketUpdateModel = {
                                            quoteHours: hours,
                                            quoteDollar: hours * 200,
                                            quoteScope: scope,
                                            quoteUnits: hours * 2
                                        };
                                        this.ticketService.updateTicket(this.ticket.id, ticketUpdate).pipe(takeUntil(this.destroySubs$)).subscribe(
                                            updatedTicket=>{
                                                // console.log(updatedTicket);
                                                this.ticket = updatedTicket;
                                            }
                                        )
                                    }
                                )
                            }
                        }
                    )
                }
            }
        )
    }
    // first time being moved into progress. need to note the hours its been ready and waiting for a developer to work on it.
    setLeadTime(){
        this.displayOptions = false;
        let updatedTicket:TicketUpdateModel = {
            leadTime: this.ticket.time['pending'] + this.ticket.time['devReady']
        };
        this.ticketService.updateTicket(this.ticket.id, updatedTicket).pipe(takeUntil(this.destroySubs$)).subscribe(_updatedTicket=>{
            
            this.ticket = _updatedTicket;
        });
    }
    /**
     * for dev to set hours they expect this to take
     */
    setExpectedHours($event){
        this.displayOptions = false;
        
        if(!this.authenticatedUser.checkPermission([PermissionSet.modifyTicketQuote]) || this.ticket.unitsAllocated ){
            this.toasty.info("Unable to set Expected Hours. Only developers can set the expected hours while it is still as of yet unset.");
            return;
        }
        var dialogPopupObs = this.dialog.open(TextNumberPopUpDialogComponent, {
            data: {
                title: "Expected Hours", 
                message: "How many hours do you expect this work to take? this is just an estimation"
            }
        }).afterClosed();
        dialogPopupObs.pipe(takeUntil(this.destroySubs$)).subscribe(
            res=>{
                if(res){
                    let updatedTicket:TicketUpdateModel = {
                        unitsAllocated: (res * 2)
                    };
                    this.ticketService.updateTicket(this.ticket.id, updatedTicket).pipe(takeUntil(this.destroySubs$)).subscribe(
                        _updatedTicket=>{
                            this.ticket = _updatedTicket;
                        },
                        (e)=>{
                            console.log(e);
                            this.toasty.error("Unable to set the expected hours. Please see the system administrator");
                        }
                    )
                }
            }
        )
        return dialogPopupObs;
    }
    /**
     * confirm they want to close ticket and mark ticket closed
     */
    closeTicket($event){
        this.displayOptions = false;
        // $event.source.value = null;
        let closable = this.ticket.tasks? this.ticket.tasks.filter(task=>!task.completed).length : 0;
        if(closable > 0){
            //@todo alert message that this cannot be completed until all tasks/subtickets are also completed.
            
            this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: "Open Ticket Tasks",
                    message: "This ticke has open tasks that must be closed in order to approve and close this ticket.",
                    confirm: true
                }
            });
            return;
        }
        if(!this.authenticatedUser.checkPermission([PermissionSet.closeTicket,PermissionSet.isStakeholder], this.ticket.stakeholders)){
            //@todo alert message that user doesn't have permision to close this ticket
            this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: "Access Denied",
                    message: "<p>You must be a stakeholder or have elevated close permissions to close this ticket.</p>",
                    confirm: true
                }
            });     
            return;
        }
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Approve and Close Ticket",
                message: "<p>Has this work been completed to scope? Are you sure you want to close this ticket</p>"
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            res=>{
                if(res){
                    //would you like to leave a ticket comment
                    this.craftMessage("Would you like to leave a ticket message to accompany closing this ticket.").pipe(takeUntil(this.destroySubs$)).subscribe(
                        comment=>{
                            
                            if(comment){
                                comment.move = [];
                                comment.move['from'] = TICKETSTATUS[this.ticket.status];
                                comment.move['to'] = "closed";
                                this.ticketService.createNewTicketComment(this.ticket.id, comment).pipe(takeUntil(this.destroySubs$)).subscribe(
                                    comment=>{
                                        this.ticketComments.push(comment);
                                    }
                                )
                        }
                        this.ticketService.closeTicket(this.ticket.id).pipe(takeUntil(this.destroySubs$)).subscribe(
                            closed=>{
                                this.ticket = closed;
                            },
                            (e)=>{
                                console.log(e);
                            }
                        )
                    })
                    

                }
            }
        )
    }
    // @todo flesh out this method. 
    adjustUnits(){
        this.displayOptions = false;
        //adjusts the units allowed for the job.  adjusted units just provide a buffer of time to complete a piece of work beyond the allocated/charged units.  
        //Only a dev admin or superadmin can adjust units. All variances are calcuated off of the adjustedUnits not allocated units
        let options:TextNumberPopUpDialogOptions = {
            title: "Adjust Allocated Units",
            message: "Change the allocated units made available for this project",
        };
        this.dialog.open(TextNumberPopUpDialogComponent, {data: options}).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            adjustedHours=>{
                
                let update:TicketUpdateModel = {
                    unitsAdjusted: adjustedHours
                }
                this.ticketService.updateTicket(this.ticket.id, update).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    // @todo flesh out this method
    setDueDate($event){
        this.displayOptions = false;
        //sets a due date for work that is allowed a due date. only previously quoted work is allowed a due.

        this.dialog.open(TextCalanderPopUp, {
            data: {
                title: "Request a Due Date"
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            date=>{
                let _date = moment(date).unix();
                this.ticketService.updateTicket(this.ticket.id, {dueDate: _date}).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    deleteTicket($event){
        let options:ConfirmationDialogOptions = {
            title: "Delete Ticket",
            message: "Are you sure you want to delete this ticket?"
        };
        this.dialog.open(ConfirmationDialogComponent, {
            data: options
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            response=>{
                if(response){
                    this.ticketService.deleteTicket(this.ticket.id).pipe(takeUntil(this.destroySubs$)).subscribe(
                        response=>{
                            
                            if(response.success){ //@todo still need to set off event from api to remove ticket from remote lists of other instance of the app
                                this.deleted = true;
                                this._dialog.close();
                            }
                        }
                    )
                }
            }
        )
    }
    duplicateTicket($event){ //creates an exact duplicate of the ticket except comments but creates a "task" in the parent ticket so they are intrensicly linked.
        var copy:TicketCreateModel = Object.assign(new TicketCreateModel(), this.ticket);
        copy['id'] = null;
        copy.isClosed = false;
        copy.subject = "Duplicate: "+copy.subject;
        let options:ConfirmationDialogOptions = {
            message: "Are you sure you want to copy this ticket?",
            subtitle: "This ticket will not be affiliated with any cloned tickets"
        };
        this.dialog.open(ConfirmationDialogComponent, {
            data: options
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            response=>{
                if(response){
                    this.ticketService.createTicket(copy).pipe(takeUntil(this.destroySubs$)).subscribe(
                        freshTicket=>{
                            //create a tash for the created ticket.
                            let newTask:_TaskCreateModel = {
                                title: "Duplicate Ticket for "+this.ticket.subject,
                                completed: false
                            };
                                        this.ticketService.createNewTask(this.ticket.id, newTask).pipe(takeUntil(this.destroySubs$)).subscribe(
                                            task=>{
                                                task.ticketId = freshTicket.id;
                                                this.ticketService.updateTask(this.ticket.id, task.id, task).pipe(takeUntil(this.destroySubs$)).subscribe(
                                                    updatedTask=>{
                                                        console.log(updatedTask);
                                                        //need to try to add the tupdated task to the fresh ticket but it needs to be able to reflect on the board.
                                                    }
                                                )
                                            }
                                        )                          
                    
                        }
                    )
                }
            }
        )


    }
    private createTask($taskName = null):Observable<Task>{
        let newTask:_TaskCreateModel = {
            title:"QA (Quality Assurance)",
            completed: false
        };
        return this.ticketService.createNewTask(this.ticket.id, newTask).pipe(map(
            task=>{
                if(!this.ticket.tasks){
                    this.ticket.tasks = [];
                }
                this.ticket.tasks.push(task);
                return task;
            }
        ))
    }
    requestQA($event){ //creates a child ticket with type of QA and a task in the parent ticket so they are linked
        this.createTask("QA (Quality Assurance)").pipe(takeUntil(this.destroySubs$)).subscribe(
            task=>{
                let newTicket:TicketCreateModel = {
                    accountManager: this.ticket.accountManager,
                    developer: null,
                    department: Department.Tech,
                    stakeholders: this.ticket.stakeholders,
                    paymentType: 'free',
                    unitsAllocated: 0,
                    status: TicketStatus.open,
                    isClosed: false,
                    account: this.ticket.account,
                    workType: this.ticket.workType,
                    workCategory: this.ticket.workCategory,
                    subject: "QA for "+this.ticket.subject,
                    scope: this.ticket.scope,
                    priority: TicketPriority.A,
                    createdBy: this.authenticatedUser.currentUser,
                    isQuote: false,
                    quotedWorkTicket: false,
                    createdDate: new Date().getTime(),
                    parentTicketId: this.ticket.id
                };
                this.ticketService.createTicket(newTicket).pipe(takeUntil(this.destroySubs$)).subscribe(
                    createdTicket=>{
                        task.ticketId = createdTicket.id;
                        this.ticketService.updateTask(this.ticket.id, task.id, task).pipe(takeUntil(this.destroySubs$)).subscribe(
                            updatedTask=>{
                                // console.log(updatedTask);
                                let index = this.ticket.tasks.findIndex(t=>t.id==task.id);
                                this.ticket.tasks[index] = updatedTask[0];
                                
                            }
                        )
                    }
                )
            }
        )
        
    }
    connectToSalesforce(){
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Salesforce ID",
                simpleInput: true
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            _id=>{
                this.ticketService.updateTicket(this.ticket.id, {salesforceId: _id}).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    createDaedelusProject(){
        
    }
    connectToAtlas(){
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Atlas ID",
                simpleInput: true
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            _id=>{
                if(_id){
                    this.ticketService.updateTicket(this.ticket.id, {atlasId: _id}).pipe(takeUntil(this.destroySubs$)).subscribe(
                        updatedTicket=>{
                            this.ticket = updatedTicket;
                        }
                    )
                }
            }
        )
    }
    openAtlas(){ 
        let url = 'https://atlas.brafton.com/produce/productions/'+this.ticket.atlasId; //atlas url
        window.open(url, '_blank');

    }
    renderText(text:string){
        return text;
    }
    connectToDaedelus(){
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Daedelus Project ID",
                simpleInput: true
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            _id=>{
                this.ticketService.updateTicket(this.ticket.id, {daedelusId: _id}).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    connectToTrello(){
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Trello Project url",
                subtitle: "This can be found under 'share'",
                simpleInput: true
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            _id=>{
                this.ticketService.updateTicket(this.ticket.id, {trelloId: _id}).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    openDaedelus(){
        let url = `http://daedelus.brafton.com/projects/`+this.ticket.daedelusId;
        window.open(url, '_blank');
    }
    convertToWork(){ //convert button shouldn't show up if the ticket isn't a quote but perform the check first just in case.
        if(this.ticket.workCategory != "Quote" || this.ticket.quoteScope == null){ //check first if the ticket is even a quote ticket and also if quote information has been provided yet.
            console.log("This ticket can not be converted to work.");
            return;
        }
        this.dialog.open(TextAreaPopUpDialogComponent, {
            data: {
                title: "Please provide a title for this work",
                simpleInput: true
            },
            height: '50%'

        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(title=>{
            if(title){
                let stakeholders = [this.ticket.accountManager];
                if(this.authenticatedUser.currentUser.email != this.ticket.accountManager.email){
                    stakeholders.push(this.authenticatedUser.currentUser);
                }
                var newTicket:TicketCreateModel =  {
                    accountManager: this.ticket.accountManager,
                    developer: null,
                    stakeholders: stakeholders,
                    status: TicketStatus.open,
                    account: this.ticket.account,
                    scope: this.ticket.quoteScope,
                    subject: title,
                    unitsAllocated: this.ticket.quoteUnits,
                    pricePaid: this.ticket.quoteDollar,
                    paymentType: 'paid',
                    workType: TicketWorkType.Contract,
                    workCategory: "Previously Quoted Work",
                    createdDate: new Date().getTime(),
                    createdBy: this.authenticatedUser.currentUser,
                    department: Department.Tech,
                    priority: 3,
                    isQuote: false,
                    isClosed: false,
                    quotedWorkTicket: true,
                    quoteTicketId: this.ticket.id,
                    assets: this.ticket.assets || null
                };
              
                this.ticketService.createTicket(newTicket).pipe(takeUntil(this.destroySubs$)).subscribe(created=>{
                    this.ticketListService.addNewTicket(created);
                    this.ticketService.closeTicket(this.ticket.id).pipe(takeUntil(this.destroySubs$)).subscribe(
                        closed=>{
                            this.ticket = closed;
                        },
                        (e)=>{
                            console.log(e);
                        }
                    )
                    this.dialog.open(ConfirmationDialogComponent, {
                        data: {
                            title: "Your New Ticket has been created",
                            subtitle: "This ticket will still need to be reviewed and assigned before work will begin.",
                            confirm: true
                        }
                    })
                })
            }else{
                console.log("You must provide a title")
            }
        })
    }
    getFileIcon(asset){
        let file = "assets/images/ext-icons/";
        try{
            let type = asset.type.split('/')[1];
            if(types.find(a=>a==type)){
                file += type+'.png';
            }else{
                file += "file.png";
            }
        }catch(e){
            file += "file.png";
        }
        return file;
    }
    addTags(){ //add tags for help in clasification of tickets  to group tickets of similar work together
        this.dialog.open(TagsFormComponent,{
            data: {
                tags: this.ticket.tags || []
            },
            width: '500px'
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            _ticketTags=>{
                this.ticketService.updateTicket(this.ticket.id,{tags: _ticketTags}).pipe(takeUntil(this.destroySubs$)).subscribe(
                    updatedTicket=>{
                        this.ticket = updatedTicket;
                    }
                )
            }
        )
    }
    updateWorkType(){
        this.dialog.open(WorkTypePopUpDialogComponent, {
            width:'50%',
            data: {
                workType: this.ticket.workType,
                workCategory: this.ticket.workCategory
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            newInfo=>{
                // console.log(newInfo);
                if(newInfo){
                    let udpated:TicketUpdateModel = {};
                    udpated.workType = newInfo.type;
                    udpated.workCategory = newInfo.cat;
                    if(udpated.workCategory == "Quote"){

                        udpated.isQuote = true;
                    }
                    this.ticketService.updateTicket(this.ticket.id, udpated).pipe(takeUntil(this.destroySubs$)).subscribe(
                        updatedTicket=>{
                            this.ticket = updatedTicket;
                        }
                    )
                }
            }
        )
    }
    private loadAtlasPhases(){
        return this.ticket.tasks.filter(task=>task.atlasPhaseId).sort((a,b)=>{
            if(a.order < b.order){
                return -1;
            }
            if(a.order > b.order){
                return 1;
            }
            return 0;
        });
    }
    partiallyCompletePhase(phase:Task){
        // var atlasPhases = this.loadAtlasPhases();
        //     var startedPhase = atlasPhases.find(phase=>phase.started);
        //     if(!startedPhase){
        //         return;
        //     }
        //     // this.partiallyCompletePhase(startedPhase);
        this.dialog.open(PhaseCompletedDialogComponent, {
            width: "500px",
            data: {
                title: "Complete in Atlas",
                subtitle: "Mark this phase as completed in Atlas",
                message: `The task labeled as <b>${phase.title}</b> has been the actively worked on phase.<br/><br/>How would you like to perform a completion?`,
                totalUnits: phase.allocatedUnits
            }
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(response=>{
            console.log(response);
            if(response){
                console.log("marking the phase completed");
                if(response.totalUnits){
                    this.markPhaseCompleted(phase, response.totalUnits);
                }else{
                    this.markPhaseCompleted(phase);
                }
            }
        })
    }
    checkForCompletingAtlasPhase(){
        if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin]) || this.authenticatedUser.currentUser.id == this.ticket.developer.id){
            //catch and release clause if is admin or is the developer continue through method
            
        }else{
            return;
        }
        if(this.ticket.atlasId){
            var atlasPhases = this.loadAtlasPhases();
            var startedPhase = atlasPhases.find(phase=>phase.started);
            if(!startedPhase){
                return;
            }
            this.partiallyCompletePhase(startedPhase);
        }
    }
    markPhaseCompleted(phase:Task, units = 0){
        var completePhases:CompletePhaseRequest = {
            deliverableId: phase.atlasPhaseId,
            completedDate: moment().utc().format()
        };
        if(units){
            completePhases.partialUnits = units;
        }
        this.techDeliverablesService.CompletePhaseAsync(completePhases).pipe(take(1)).subscribe(
            deliverableResponse=>{
                console.log(deliverableResponse);
                phase.started = false;
                phase.completed = true;
                if(units){
                    phase.allocatedUnits = units;
                }
                var delCrit:DeliverableCriteria = {
                    field: SearchableField.WorkReadyStatus,
                    criteriaTarget: CriteriaTarget.CurrentDeliverable,
                    value: WorkReadyStatus.ReadyToWorkOn
                }
                this.deliverablesService.GetAsync(this.ticket.atlasId, false, [delCrit]).pipe(take(1)).subscribe(
                    projectResponse=>{
                        console.log(projectResponse);
                        var conversion = new atlasPhasesToTasks;
                        conversion.setWorkReadyStatus(WorkReadyStatus.ReadyToWorkOn);
                        var unassignedTasks = conversion.convert(projectResponse['phases']);
                        // console.log(unassignedTasks,conversion.listPhases());
                        if(unassignedTasks.length == 1){
                            //add the phase automatically
                            this.ticketService.createNewTask(this.ticket.id, unassignedTasks[0]).subscribe(
                                newTask=>{
                                    // console.log(newTask);
                                    this.ticket.tasks.push(newTask);
                                }
                            )
                        }else if(unassignedTasks.length > 1){
                            //provide interface to display the phases and ensure the correct one is added.
                            console.log(unassignedTasks);
                            this.dialog.open(PhaseConfirmationDialogComponent, {
                                data: {
                                    phases: unassignedTasks
                                }
                            }).afterClosed().subscribe(
                                selectedPhases=>{
                                    console.log(selectedPhases);
                                }
                            )

                        }
                        console.log(unassignedTasks)
                    }
                )
                this.ticketService.updateTask(this.ticket.id, phase.id, phase).pipe(take(1)).subscribe(
                    (updatedTask:Array<Task>)=>{
                        var task = updatedTask[0];
                        var index = this.ticket.tasks.findIndex(_task=>_task.id==task.id);
                        this.ticket.tasks[index] = task;                        
                        this.taskUpdate += 1;
                    }
                )
            },
            er=>{
                console.log(er);
                this.toasty.error(er.details.details.message, "Error Completing phase");
            }
        )
    }
    markPhaseStarted(phase:Task){
        this.techDeliverablesService.StartPhaseAsync(phase.atlasPhaseId, 
            moment().utc().format()).pipe(take(1)).subscribe(
                deliverableResponse=>{
                    // console.log(deliverableResponse);
                    phase.started = true;
                    this.ticketService.updateTask(this.ticket.id, phase.id, phase).pipe(take(1)).subscribe(
                        (updatedTask:Array<Task>)=>{
                            var task = updatedTask[0];
                            var index = this.ticket.tasks.findIndex(_task=>_task.id==task.id);
                            this.ticket.tasks[index] = task;                            
                            this.taskUpdate += 1;
                        }
                    )
                },
                er=>{
                    console.log(er);
                    this.toasty.error(er.details.details.message, "Can not Start this phase");
                }
            )
        
    }
    checkForStartingAtlasPhases(){
        if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin]) || this.authenticatedUser.currentUser.id == this.ticket.developer.id){
            //catch and release clause if is admin or is the developer continue through method
            
        }else{
            return;
        }
        if(this.ticket.atlasId){
            var atlasPhases = this.loadAtlasPhases();
            var startedPhase = atlasPhases.find(phase=>phase.started);
            if(startedPhase){
                return ;
            }
            if(atlasPhases.length === 1 && atlasPhases[0].completed === false){ //just start phase.
                console.log("marking the sole phase as started");
             
                this.markPhaseStarted(atlasPhases[0]);
               
            }else if(atlasPhases.length > 1){
                var uncompletedPhase = atlasPhases.find(phase=>!phase.completed);
                if(uncompletedPhase){
                    this.dialog.open(ConfirmationDialogComponent, {
                        width: "500px",
                        data: {
                            title: "Start in Atlas",
                            subtitle: "Mark this phase as started in Atlas",
                            message: `The task labeled as <b>${uncompletedPhase.title}</b> has not yet been started.<br/><br/> Would you like to start this phase now?<br/>`
                        }
                    }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(response=>{
                        console.log(response);
                        if(response){
                            console.log("marking the phase started");
                            this.markPhaseStarted(uncompletedPhase);
                        }
                    })
                }
            }

        }
    }
    shareUrl($event){
        var domain = window.location.origin;
        var url = `${domain}/ticket/${this.ticket.id}`;
        this.dialog.open(ConfirmationDialogComponent, {
            width: '700px',
            data: {
                title: "Sharable Ticket URL",
                subtitle: "You can copy and paste the below URL to share with internal Brafton team members. You can right click and select copy Link address or highlight the text and use 'ctrl + c'",
                message: `<br/><a target="_blank" href="${url}">${url}</a>`,
                customOptions: [{label: "OK", value: true}]
            }
        })
    }
    updateTasks(taskList){
        
        this.ticket.tasks = taskList;
    }
    taskUnitAllocationChange(unitChange){
        var unitsAllocated = parseFloat(this.ticket.unitsAllocated.toString()) + unitChange;
        this.ticketService.updateTicket(this.ticket.id, {unitsAllocated: unitsAllocated}).pipe(takeUntil(this.destroySubs$)).subscribe(ticket=>{
            this.ticket = ticket;
        })
    }
    ticketActions($event){
        var action = this.TICKETACTIONS[$event.value];
        // action.method.bind(this); @todo put this back only after refactoring actions array into seperate class
       action.method($event, action)
    }
    
}
interface ActionsObject{
    name: string,
    permission: Array<PermissionSet>,
    method($event, action):void,
    info: string,
    icon: string,
    location:number,
    condition(action,location):boolean
}