import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckbox } from '@angular/material';
import { TicketService, FileService } from '../../KanbanService';
import {types} from '../assetTypes';
@Component({
    selector: 'file-uploader',
    templateUrl: './fileUpload.component.html',
    styleUrls: ['./fileUpload.scss']
})
export class FileUploadComponent{
    @ViewChild('fileuploadField') fileuploadField:ElementRef;
    public uploadedFileErrors = [];
    public doingSomethingForTheLoader = false;
    public uploadingTheFiles = false;
    public uploadedRawFiles = false;
    public fileStrings = "";
    public filesOnExit = [];
    public urlsOnExit = [];
    private ticketId;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any,
        private dialogRef:MatDialogRef<FileUploadComponent>,
        private ticketService:TicketService,
        private fileService:FileService
        )
    {
        this.ticketId = data.ticketId;
    }
    ngOnDestroy(){
        
        this.filesOnExit.push(...this.urlsOnExit);
        this.urlsOnExit = [];
        
        this.dialogRef.close(this.filesOnExit);
    }
    externalFiles($event){
        this.fileStrings = $event.target.value;
    }
    fileUploaded(input){
        this.doingSomethingForTheLoader = true;
        this.uploadingTheFiles = true;

        this.fileService.uploadRawFiles(this.ticketId, input.files).subscribe(
            (files:Array<any>)=>{

                files = files.filter(file=>{
                    if(file.error){
                        this.uploadedFileErrors.push(file);
                        return false;
                    }
                    return true;
                })
                this.doingSomethingForTheLoader = false;
                this.uploadingTheFiles = false;
                this.uploadedRawFiles = true;

                this.filesOnExit.push(...files);
                console.log(this.filesOnExit);
            },
            error=>{
                console.log(error);
                this.doingSomethingForTheLoader = false;
            },
            ()=>{
                this.doingSomethingForTheLoader = false;
                this.uploadingTheFiles = false;
            }
        )
    }
    addNewFileUrl(){ // add new field fill for url/name/icon drop down
        this.urlsOnExit.push({
            fileUrl: "",
            id: Math.random().toString(36).substr(2, 9),
            name: "",
            ticketId: this.ticketId,
            type: "type/file",
            submited: false
        });
        
    }
    changeUrlDetails($event, asset){
        
        let field = $event.source? $event.source.id : $event.target.id;
        let index = this.urlsOnExit.findIndex(u=>u.id==asset.id);
        this.urlsOnExit[index][field] = $event.source? $event.value :$event.target.value;
        if(this.urlsOnExit[index].fileUrl && this.urlsOnExit[index].name){
            this.urlsOnExit[index].submited = true;
        }
    }
    changeFileName($event, file){ //change uploaded files name
        //fine entry in filesonexit and update the name field accordinly. 

        
        let newVal = $event.target.value;
        let index = this.filesOnExit.findIndex(f=>f.id==file.id);
        this.filesOnExit[index].name = newVal;
    }
    isImage(asset){
        let type = asset.type.split('/');
        
        if(type[0] == 'image'){
            return true;
        }
        return false;
    }
    getFileIcon(asset){
        let file = "assets/images/ext-icons/";
        try{
            let type = asset.type.split('/')[1];
            if(types.find(a=>a==type)){
                file += type+'.png';
            }else{
                file += "file.png";
            }
        }catch(e){
            file += "file.png";
        }
        return file;
    }
}