import { DataService } from "../shared/data.service";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { WorkType, WORKTYPE } from "../models/enums/WorkType";
import { WorkCategoryCreateModel } from "../models/requestCreateModels/WorkCategoryCreateModel";
import { WorkCategory } from "../models/responseModels/WorkCategory";
import { WorkCategoryUpdateModel } from "../models/requestUpdateModels/WorkCategoryUpdateModel";

@Injectable()
export class WorkService extends DataService{

    listWorkCategories(workType: WorkType = null){
        let url = `workcategories`;
        if(workType !== null){
            url += `?workType=${workType}`;
        }
        return this.makeGetRequest(url);
    }
    getWorkCategoryByID(workId:string|number){
        let url = `workcategories/${workId}`;
        return this.makeGetRequest(url);
    }
    createWorkCategory(workCategory:WorkCategoryCreateModel){
        let url = `workcategories`;
        return this.makePostRequest(url, workCategory);
    }
    getWorkCategoryTasks(workId:string|number){
        let url = `workcategories/${workId}/tasks`;
        return this.makeGetRequest(url);
    }
    updateWorkCategory(workId:string|number, workCategory:WorkCategoryUpdateModel){
        let url = `workcategories/${workId}`;
        return this.makePutRequest(url, workCategory).pipe(map(item=>item[0]));
    }
    deleteWorkCategory(workId:string|number){
        let url = `workcategories/${workId}`;
        return this.makeDeleteRequest(url);
    }
}

