import { Component, Input, AfterContentInit } from '@angular/core';
//for number of items that fall in a category ie. complted = 2 progress = 5 waiting = 1 ect.
@Component({
  selector: 'bf-chart-donut',
  templateUrl: './bf-chart-donut.component.html',
  styleUrls: ['./bf-chart-donut.component.css']
})
export class BfChartDonutComponent implements AfterContentInit{

  @Input() items: Array<Item> = [];
  @Input() radius: number = 40;
  @Input() width: number = 20;
  @Input() fontColor: string = "black";
  @Input() fontSize: number = 10;
  public currentItem: Item;

  constructor() {
    
  }
  ngAfterContentInit() {
    this.currentItem = this.items[0];
  }

  get perimeter() {
    return Math.PI * 2 * this.radius;
  }

  get total() {
    return this.items.map(a => a.count).
      reduce((x, y) => x + y);
  }

  get center() {
    return this.radius + (this.width / 2);
  }

  get viewBox() {
    return "0 0 " + (this.center * 2).toString() + " " + (this.center * 2).toString()
  }

  getOffset(index: number): number {

    let percent: number = index === 0 ? index : this.items.slice(0, index).map(a => a.count).
      reduce((x, y) => x + y);
    return this.perimeter * percent / this.total;

  }

  getColourStyle(item: Item, index: number)
  {
    if (item.color != null)
      return 'colour-' + item.color;

    let pick = index % 5;
    return 'colour' + pick;
  }

  mouseHover(item: Item) {
    this.currentItem = item;
  }

}

export interface Item {
  name: string;
  count: number;
  color: string;
}
