import { PermissionSet } from './../authenticatedUser.service';
import { AuthenticatedUser } from '../authenticatedUser.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';

import { BfAuthService } from '@brafton/skynet-angular-security-service';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private bfAuth:BfAuthService, private authenticatedUser:AuthenticatedUser) {}

    public canActivate(): Observable<boolean> {
        
        if (this.authenticatedUser.currentUser.email) { 
            if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
                return of(true);
            }else{
                return of(false); 
            }
        }

        let subject = new Subject<boolean>();
        let subscription = this.authenticatedUser.userAsync.subscribe(user => {
            
            if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
                subject.next(true);
                subject.complete();

                subscription.unsubscribe();
            }
        });

        return subject;
    }
}