import {  Inject, Injectable  } from '@angular/core';
import { Auth0, DelegateClient, BfAuthConfig } from '@brafton/skynet-angular-security-service';
import { Observable,Subject, of, throwError  } from 'rxjs';
import {catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { KanbanApiConfig } from './KanbanService/shared/api.config';
import { KanbanEventApiConfig } from './event.config';
import { DeliverableClientConfig } from '@brafton/deliverables-client';
import { IApiConfig } from '@brafton/assets-api';

@Injectable()
export class AppKanbanConfig{

    private static config: Object = null;
    private static env: EnvData = null;

    constructor(private http: HttpClient) { }

    public static get KanbanGuiEndpoint(): string {
        return AppKanbanConfig.getConfig("kanbanGuiEndpoint");
    }
    public static get Auth0Domain():string{
        return AppKanbanConfig.getConfig('auth0:Domain');
    }
    public static get Auth0GuiClientId():string{
        return AppKanbanConfig.getConfig('auth0:Gui:ClientId');
    }
    public static get Auth0GuiAppName():string{
        return AppKanbanConfig.getConfig('auth0:Gui:AppName');
    }
    public static get Auth0ApiClientId(): string {
        return AppKanbanConfig.getConfig("auth0:Api:ClientId");
    }
    public static get Auth0ApiAppName(): string {
        return AppKanbanConfig.getConfig("auth0:Api:AppName");
    }
    public static get KanbanApiEndpoint(): string{
        return AppKanbanConfig.getConfig("kanbanApiEndpoint");
    }
    public static get KanbanApiEventEndpoint():string{
        return AppKanbanConfig.getConfig("kanbanApiEventEndpoint");
    }
    // Deliverables Region
    public static get DeliverablesAuth0ApiClientId(): string {
        return AppKanbanConfig.getConfig("deliverables:Api:Auth0:ClientId");
    }

    public static get DeliverablesAuth0ApiAppName(): string {
        return AppKanbanConfig.getConfig("deliverables:Api:Auth0:AppName");
    }

    public static get DeliverablesApiEndpoint(): string {
        return AppKanbanConfig.getConfig("deliverables:Api:Endpoint");
    }
     // Assets Api Region
    public static get AssetsAuth0ApiClientId(): string {
        return AppKanbanConfig.getConfig('assets:Api:Auth0:ClientId');
    }

    public static get AssetsAuth0ApiAppName(): string {
        return AppKanbanConfig.getConfig('assets:Api:Auth0:AppName');
    }

    public static get AssetsApiEndpoint(): string {
        return AppKanbanConfig.getConfig('assets:Api:Endpoint');
    }
    public static get PassshackApiEndpoint(): string{
        return AppKanbanConfig.getConfig("passshackApiEndpoint");
    }
    public static get Auth0PassshackApiClientId(): string {
        return AppKanbanConfig.getConfig("auth0:PassshackApi:ClientId");
    }
    public static get Auth0PassshackApiAppName(): string {
        return AppKanbanConfig.getConfig("auth0:PassshackApi:AppName");
    }
    public static getConfig(key: any) {
        return AppKanbanConfig.config[key];
    }
    public static getEnv(key: any) {
        return AppKanbanConfig.env[key];
    }
    public load() {
        return new Promise((resolve, reject) => {
            this.http.get<any>('/assets/environments/env.json')
              .pipe(
                catchError((error: any): any => {
                  console.log('Configuration file "env.json" could not be read');
                  resolve(true);
                  return throwError(error.error || 'Server error');
                })
              )
              .subscribe((envResponse: EnvData) => {
                AppKanbanConfig.env = envResponse;
                let request: any = null;
                request = this.http.get<any>('./assets/environments/env.' + AppKanbanConfig.env.env + '.json');
                if (request) {
                  request
                    .pipe(
                      catchError((error: any): any => {
                        console.error('Error reading ' + AppKanbanConfig.env + ' configuration file');
                        resolve(error);
                        return throwError(error.error || 'Server error');
                      })
                    )
                    .subscribe((responseData) => {
                        AppKanbanConfig.config = responseData;
                      resolve(true);
                    });
                } else {
                  console.error('Env config file "env.json" is not valid');
                  resolve(true);
                }
              });
          });
    }
}
class EnvData {
    env: string;
}

@Injectable()
export class KANBAN_AUTH_CONFIG implements BfAuthConfig {
    constructor() { }

    Auth0: Auth0 = {
        Domain: AppKanbanConfig.Auth0Domain,
        ClientId: AppKanbanConfig.Auth0GuiClientId,
        AppName: AppKanbanConfig.Auth0GuiAppName
    };

    DelegateClients: DelegateClient[] = [
        {
            Auth0: {
                ClientId: AppKanbanConfig.Auth0ApiClientId,
                AppName: AppKanbanConfig.Auth0ApiAppName
            },
            
        },
        {
            Auth0: { // Deliverables delegate settings
              ClientId: AppKanbanConfig.DeliverablesAuth0ApiClientId,
              AppName: AppKanbanConfig.DeliverablesAuth0ApiAppName,
              Required: true
            }
          },
          {
            Auth0: { // Assets-Api delegate settings
              ClientId: AppKanbanConfig.AssetsAuth0ApiClientId,
              AppName: AppKanbanConfig.AssetsAuth0ApiAppName,
              Required: true
            }
          },
          {
              Auth0:{
                  ClientId: AppKanbanConfig.Auth0PassshackApiClientId,
                  AppName: AppKanbanConfig.Auth0PassshackApiAppName
              }
          }
    ];
    AutoLogin: boolean = true;
    LoginPageURL: string = '/login';
    HomePageURL: string = '/dashboard';
    // ApplicationNameOnAuth0: string = AppDaedelusConfig.Auth0GuiAppName;
    // CallbackURL: string = AppDaedelusConfig.DaedelusGuiEndpoint;
};

@Injectable()
export class KANBAN_AUTH_CONFIG_OPTIONS implements Auth0LockConstructorOptions {
    
    closable: boolean = false;
    autoclose:boolean = true;
    languageDictionary: any = { title: "Log in to Kanban" };
    auth: Auth0LockAuthOptions = {
        params: {
            scope: 'openid given_name family_name email picture app_metadata'
        },
        sso: true,
        redirectUrl: AppKanbanConfig.KanbanGuiEndpoint,
        responseType: 'token id_token'
    };
    allowedConnections: string[] = ['Staff-Users', 'brafton-com', 'castleford-com-au'];
    defaultDatabaseConnection: string = 'Staff-Users';
    theme: Auth0LockThemeOptions = {
        logo: '/assets/images/brafton-b.svg',
        primaryColor: '#8bc34a'
    }
    constructor() {
        if (window.location.pathname && window.location.pathname.length > 1) {
            let queryString = window.location.href.split('?')[1]
            let url = (queryString != null && queryString.length >= 2)
                ? `${window.location.pathname}?${queryString}` : window.location.pathname;


            let encodedState = encodeURIComponent(`ru=${url}`);

            this.auth.params.state = encodedState;
        }
    }
};
@Injectable()
export class KanbanConfigApi extends KanbanApiConfig{
    baseUrl = AppKanbanConfig.KanbanApiEndpoint
  }
@Injectable()
export class KanbanEventConfig extends KanbanEventApiConfig {
baseUrl = AppKanbanConfig.KanbanApiEventEndpoint
}

export class DELIVERABLES_CLIENT_APP_CONFIG implements DeliverableClientConfig {
    Auth0ApiAppName: string = AppKanbanConfig.DeliverablesAuth0ApiAppName;
    DeliverablesApiURL: string = AppKanbanConfig.DeliverablesApiEndpoint;
  }

  export class ASSETS_API_CLIENT_APP_CONFIG implements IApiConfig {
    Auth0ApiAppName: string = AppKanbanConfig.AssetsAuth0ApiAppName;
    AssetsApiEndpoint: string = AppKanbanConfig.AssetsApiEndpoint;
    constructor() { }
  }