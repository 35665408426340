
import { MultiselectDialogComponent } from './shared/multiselect/multiselect.component';
import { DateRangeDialogComponent } from './admin/reports/popups/dateRange/date.range.component';
import { PhaseConfirmationDialogComponent } from './shared/phaseConfirmation/phaseConfirmation.component';
import { PhaseCompletedDialogComponent } from './shared/phaseCompleted/phaseCompleted.component';
import { TicketView } from './ticket/ticket.component';
import { WorkTypePopUpDialogComponent } from './singleTicket/workTypePopup/workTypePopup.component';
import { TagsComponent } from './admin/settings/tags.component';
import { AdminGuard } from './shared/securityAuth/adminGuard';
import { KanbanSettings } from './admin/settings/settings.component';
import { TaskListComponent } from './tasks/task.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { EventService } from './shared/event.service';
import { TextNumberPopUpDialogComponent } from './shared/textnumberPopUp/textNumberPopUp.component';
import { FindUserDialogComponent } from './shared/findUser/findUser.component';
import { ConfirmationDialogComponent } from './shared/confirmation/confirmation.component';
import { CoreApiAccountService } from './services/core/core-api.service';
import { KANBAN_CONFIG } from './KanbanService/shared/api.config';
import { KanbanServiceModule } from './KanbanService/index';
import { SingleTicketView } from './singleTicket/singleTicket.component';
import { Toasty, ToastNotify } from './shared/toastNotifications/toasty.service';
import { AllTicketsComponent } from './ticketBoard/allTickets/allTickets.component';

import { CommonModule } from '@angular/common';

// import { daedelusConfig, daedelusEventConfig } from './app.daedelus.config';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogContainer, MatStepperModule } from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NectarModule } from '@brafton/nectar';
import * as delService from '@brafton/deliverables-client';

// import { EditorModule } from 'derykediter';

import { AppComponent }  from './app.component';
import { Routes } from './routes'

import { LoginComponent } from './login/login.component';

import {ToastrModule } from 'ngx-toastr';
import {
  BfAuthServiceModule, BfAuthGuard, BF_AUTH_CONFIG, BF_AUTH_CONFIG_OPTIONS 
} from '@brafton/skynet-angular-security-service';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { DndModule } from 'ngx-drag-drop';
import { NewTicketDialog } from './singleTicket/newTicket/newTicket.component';
import { AuthenticatedUser } from './shared/authenticatedUser.service';
import { KANBAN_AUTH_CONFIG, KANBAN_AUTH_CONFIG_OPTIONS, AppKanbanConfig, KanbanConfigApi, KanbanEventConfig, DELIVERABLES_CLIENT_APP_CONFIG, ASSETS_API_CLIENT_APP_CONFIG } from './app.config';
import { TextAreaPopUpDialogComponent } from './shared/textareaPopUp/textAreaPopUp.component';
import { UserChipComponent } from './shared/userChip/userChip.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TicketListService } from './shared/ticketListService';
// import { QuillEditorModule } from 'ngx-quill-editor';
import {  QuillModule } from 'ngx-quill';
import { FileUploadComponent } from './shared/fileUploader/fileUpload.Component';
import { UnassignedTicketsComponent } from './ticketBoard/unassignedTickets/unassignedTickets.component';
import { PreviewTicketComponent } from './singleTicket/previewTicket/previewTicket.component';
import { TicketBoardComponent } from './ticketBoard/ticketBoard.component';
import { ActivationGuard } from './shared/activationGuard';
import { BfChartDonutComponent } from './shared/bf-chart-donut/bf-chart-donut.component';
import {NgxChartsModule } from '@swimlane/ngx-charts';
import { KANBAN_EVENT_CONFIG } from './event.config';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import { NewTaskDialog } from './tasks/newTask/newTask.componet';
import { WorkCategoryComponent } from './admin/settings/workCategory.component';
import { WorkCategoryForm } from './admin/settings/workCategory.form.component';
import { TextCalanderPopUp } from './shared/textCalanderPopUp/textCalanderPopUp';
import { CoreApiInterceptor } from './services/core/core-api.interceptor';
import { TagsFormComponent } from './admin/settings/tags.form.component';
import { SingleAccountComponent } from './admin/accounts/singleAccount/singleAccount.component';
import { FindUserComponent } from './shared/findUser/baseComponent/base.findUser.component';
import { PassShackService } from './services/core/pass-shack.service';
import { AssetsAuthInterceptorModule, AssetsModule, API_CONFIG } from '@brafton/assets-api';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};
@NgModule({
  imports: [ 
    NgxChartsModule,
    BrowserModule,
    Routes,
    HttpClientModule  ,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatStepperModule,
    NectarModule,
    MatNativeDateModule,
    // ToastrModule.forRoot(),
    BfAuthServiceModule,
    MatDialogModule,
    DndModule,
    KanbanServiceModule.forRoot(),
    QuillModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added

    //deliverable client modules
    delService.DeliverablesAuthInterceptorModule, delService.TechDeliverableServiceModule.forRoot(), delService.ProductsServiceModule.forRoot(),
    delService.ToDoServiceModule.forRoot(),
    AssetsAuthInterceptorModule, AssetsModule.forRoot(),
    
    ],
    
  declarations: [ 
    AppComponent, 
    AccountsComponent,
    LoginComponent,
    TicketBoardComponent,
    AllTicketsComponent, 
    UnassignedTicketsComponent,
    SingleTicketView,
    PreviewTicketComponent,
    NewTicketDialog,
    ConfirmationDialogComponent,
    TextAreaPopUpDialogComponent,
    TextNumberPopUpDialogComponent,
    TextCalanderPopUp,
    FindUserDialogComponent,
    UserChipComponent,
    ReportsComponent,
    DashboardComponent,
    FileUploadComponent,
    ToastNotify,
    BfChartDonutComponent,
    TaskListComponent,
    NewTaskDialog,
    KanbanSettings,
    WorkCategoryComponent,
    WorkCategoryForm,
    TagsComponent,
    TagsFormComponent,
    SingleAccountComponent,
    WorkTypePopUpDialogComponent,
    FindUserComponent,
    TicketView,
    PhaseCompletedDialogComponent,
    PhaseConfirmationDialogComponent,
    DateRangeDialogComponent,
    MultiselectDialogComponent
    ],

    providers: [ 
      TicketListService,
      CoreApiAccountService,
      Toasty,
      AuthenticatedUser,
      BfAuthGuard,
      AppKanbanConfig,
      PassShackService,
      KANBAN_AUTH_CONFIG,
      { provide: BF_AUTH_CONFIG, useClass: KANBAN_AUTH_CONFIG },
      { provide: BF_AUTH_CONFIG_OPTIONS, useClass: KANBAN_AUTH_CONFIG_OPTIONS },
      { provide: APP_INITIALIZER, useFactory: configFactory, deps: [AppKanbanConfig], multi: true },
      { provide: KANBAN_CONFIG, useClass: KanbanConfigApi},
      { provide: KANBAN_EVENT_CONFIG, useClass: KanbanEventConfig},
      { provide: delService.DELIVERABLE_CLIENT_CONFIG, useClass: DELIVERABLES_CLIENT_APP_CONFIG },
      { provide: API_CONFIG, useClass: ASSETS_API_CLIENT_APP_CONFIG },
      ActivationGuard,
      AdminGuard,
      EventService,
      {
        provide: PERFECT_SCROLLBAR_CONFIG, 
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      { provide: HTTP_INTERCEPTORS, useClass: CoreApiInterceptor, multi: true }
    ],
  bootstrap: [ AppComponent ],
  entryComponents: [AppComponent, SingleTicketView, NewTicketDialog, ConfirmationDialogComponent, TextCalanderPopUp ,TextAreaPopUpDialogComponent,TextNumberPopUpDialogComponent, FindUserDialogComponent,FileUploadComponent, ToastNotify, NewTaskDialog, WorkCategoryForm, TagsFormComponent,WorkTypePopUpDialogComponent,PhaseCompletedDialogComponent,PhaseConfirmationDialogComponent,DateRangeDialogComponent,MultiselectDialogComponent]
})
export class AppModule { }

export function configFactory(config: AppKanbanConfig) {
  return function configFunction() {
      return config.load()
  }
}
