import { Task } from "../../responseModels/Task";
import { WorkType } from "../../enums/WorkType";
import { Asset } from "../../responseModels/Asset";

export abstract class _WorkCategory {
  id: string;
  name: string;
  description: string;
  min?: number;
  requirements?: Array<string>;
  tasks?: Array<Task>;
  workType: WorkType;
  files: Array<Asset>;
}