export enum TicketActions{
    UpdateScope,
    AddStakeholder,
    LeaveMessage,
    MoveTicket,
    AttachFiles,
    RequestExpedited,
    ChangeDeveloper,
    ProvidedQuote,
    SetExpectedHours,
    CloseWindow,
    ApproveCloseTicket,
    DeletedTicket
}