
export class DaedelusError{
    constructor(public error?:any){}

    static handleError(error?:any){

    }
}

/**
 * 400 Error
 */
export class BadRequestError extends DaedelusError{

}
/**
 * 401 Error
 */
export class UnauthorizedAccessError extends DaedelusError {

}
/**
 * 404 Error
 */
export class ResourceNotFoundError extends DaedelusError{

}
/**
 * 410 Endpoint Gone
 */
export class ResourceGoneError extends DaedelusError{

}

/**
 * 500 Error
 */
export class ServerError extends DaedelusError{

}

/**
 * 502 Error
 */
export class ServiceUnavailableError extends DaedelusError{

}
