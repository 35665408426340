import { NgModule, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CoreApiInterceptor implements HttpInterceptor {

  constructor() { }

  /**
   * Get the auth token from the service.
   * Clone the request and replace the original headers with
   * cloned headers, updated with the authorization.
   * send cloned request with header to the next handler.
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("core-api")) {
      
      let token = localStorage.getItem(`id_token_delegate_core-api`);
      const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
      const returnMimeReq = authReq.clone({ headers: authReq.headers.set('Content-Type', 'application/json') });
      return next.handle(returnMimeReq);
    }else if(req.url.includes("passshack-api")){
      let token = localStorage.getItem(`id_token_delegate_passshack-api`);
      const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
      const returnMimeReq = authReq.clone({ headers: authReq.headers.set('Content-Type', 'application/json') });
      return next.handle(returnMimeReq);
    }
    const returnMimeReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    return next.handle(req);
  }
}

// @NgModule({
//   providers: [
//     { provide: HTTP_INTERCEPTORS, useClass: AssetsAuthInterceptor, multi: true }
//   ]
// })
// export class AssetsAuthInterceptorModule { }