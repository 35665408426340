import { User } from "./User";
import { Client } from "./Client";
import { Asset } from "./Asset";
import { Comment } from "./Comment";
import { TICKETSTATUS} from "../enums/TicketStatus";
import { Department} from "../enums/Department";
import { TicketStatus} from "../enums/TicketStatus";
import { TicketStages} from "../enums/TicketStages";
import { TicketPriority} from "../enums/TicketPriority";
import { _Ticket } from "../interfaces/responseModels/_Ticket";

export class Ticket extends _Ticket{
    // account:Client;
    // id:string;
    // accountManager:User;
    // developer:User;
    // stakeholders:Array<User>;
    // //Tech, Ops, Sys
    // department:Department;
    // // free, paid, swap
    // paymentType:string;
    // //$ amount paid if applicable
    // pricePaid?:number;
    // //Units allocated. if empty and pricePaid is greater than 0 this will calculate automatically
    // unitsAllocated?:number;
    // status:TicketStatus;
    // isClosed:boolean;
    // stage:TicketStages;
    // // account:Object;
    // // support, contract, rolling
    // workType:string;
    // //bucket of preselected work Categories
    // workCategory:string;
    // //user entered string of what the work title is
    // subject:string;
    // scope:string;
    // assets?:Array<Asset>;
    // //if there is a daedelus project this is the url.. this platform will link directly with daedelus so a ticket can be opened from deadelus automatically.
    // daedelusUrl?:string;
    // comms?:Array<Comment>;
    // //fills from an api call to passshack to list the credentials for this account.
    // creds?:Array<Object>;
    // time = { //each time property is updated when the ticket is moved out of that phase.
    //     open: 0, //each time ticket is saved this updated.
    //     techHold: 0,
    //     progress: 0,
    //     woam: 0,
    //     pending: 0,
    //     approval: 0,
    //     design: 0,
    //     devReady: 0
    // };
    // daysOpen:number = 0; //this is dynamically calculated based on the created date
    // leadTime?:number; //this is the amount of time the ticket was open before work began (ticket moved to progress)
    // cycleTime?:number; //calculates when the project is first moved into progress and the dev sends the ticket to appoval. recalucaltes each time the ticket hits approval.
    // lastModifiedBy:User;
    // lastModifiedDate:number;
    // createdDate:number;
    // dueDate?:number; //for project work a due date is allowed and encouraged. 
    // closedDate?:number;
    // createdBy:User;
    // salesforceId?:String; //matches up to a ticket in salesforce if a matching ticket exists
    // escalated:boolean = false; //if ticket has become a priority and needs to be next worked on, authorized by a manager only
    // priority?:TicketPriority; //If the ticket is escalated this allows to prioritize above other escalated tickets
    // swimmer:Boolean = false; //Ticket is on fire. do this now!!!
    // unresponsive:Boolean = false; //if the ticket has not received an update within the alloted 20 days
    // projectUrl?: String;
    // isQuote?:boolean = false; //if the ticket is for a quote
    // quoteConvertedToWork?:boolean; //marked if the quote ticket gets a work ticket
    // quotedWorkTicket?:boolean; //if this ticket stems from a quoted ticket
    // quoteTicketId?:String; //if a work ticket what is the quote ticket id
    // quoteDollar?:number; //if quote ticket enter a $ amount of the quote.
    // quoteUnits?:number; //If quote ticket enter the number of responsive $100 units (calcualtes auto if quoteDollar is filled in)
    // quoteHours?:number; //number of quoted hours this work will take
    // quotedDifferenceHours?:number; //this will take quoted hours and time.progress and determine if we are over or under on hours
    // hoursDifference?:number; //this will take the unitsAllocated / 2 to create the number of hours allocated and subtract the time.progress to determine if we are over or under on hours allocated
    // lastMoved:Object;
    constructor(ticket?){
        super();
        Object.assign(this, ticket);
    }
    statusName(){
        return TICKETSTATUS[this.status].replace(/ /g, "-");
    }
}