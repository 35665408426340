import { DaedelusError } from './../../../../../Kanban.Services.Angular/src/shared/error.handler';
import { CommentCreateModel } from './../models/requestCreateModels/CommentCreateModel';
import { Ticket } from './../models/responseModels/Ticket';
import { TicketStatus, Department } from '../models/enums';
import { DataService } from './../shared/data.service';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { TicketCreateModel } from '../models/requestCreateModels/TicketCreateModel';
import { Comment } from '../models/responseModels/Comment';

// import * as fs from 'fs';
import { daysBetween } from '../../shared/utils';
import { _Comment } from '../models/interfaces/responseModels/_Comment';
import { _CommentCreateModel } from '../models/interfaces/requestCreateModels/_CommentCreateModel';
import { TicketUpdateModel } from '../models/requestUpdateModels/TicketUpdateModel';
import { Task } from '../models/responseModels/Task';
import { _TaskCreateModel } from '../models/requestCreateModels/_TaskCreateModel';
import { isNull } from 'lodash';
// var tickets = require('../test/ticketDataJson.json');
@Injectable()
export class TicketService extends DataService{

    private today = new Date();
    listTickets(filterCriteria?:TicketFilterCriteria):Observable<Array<Ticket>>{
        var url = `tickets`;
        url = this.urlParameters(filterCriteria, url);
        return this.makeGetRequest(url).pipe(map( (tickets)=>{
            return tickets.map(this.formatTicket.bind(this))
        }))

    }
    //@todo impliment correctly
    getTicketById(ticketId):Observable<Array<Ticket>>{
        let url = `tickets/${ticketId}`;
        return this.makeGetRequest(url).pipe(map( (tickets)=>{
            return tickets.map( this.formatTicket.bind(this))
        }))
    }
    createTicket(createTicket:TicketCreateModel){
        // var newTicket = new Ticket(createTicket);
        // // newTicket.id = Date.now().toString();
        // return Observable.from([newTicket]);
        let url = 'tickets';
        return this.makePostRequest(url, createTicket)
            .pipe(map( (tickets)=>{
                tickets[0].daysOpen = daysBetween(
                    new Date( tickets[0].createdDate ), this.today
                );
                return new Ticket(tickets[0]);
            }))
    }
    updateTicket(ticketId, ticket:TicketUpdateModel){

        let url = `tickets/${ticketId}`;
        return this.makePutRequest(url, ticket)
            .pipe(map( tickets=>{
                tickets[0].daysOpen= daysBetween(
                    new Date( tickets[0].createdDate ), this.today
                );
                return new Ticket(tickets[0]);
            }))

    }
    deleteTicket(ticketId){
        //@todo impliment delete ticket....does not actually delete ticket
        let url = `tickets/${ticketId}`;
        return this.makeDeleteRequest(url);
    }
    closeTicket(ticketId):Observable<any>{
        let url = `tickets/${ticketId}/closeticket`;
        return this.makePutRequest(url, {})
            .pipe(map( tickets=>{
                return new Ticket(tickets[0]);
            }))
        // return null;
    }
    updateDeveloper(ticketId, developer){
        let url = `tickets/${ticketId}/updateDeveloper`;

        return this.makePutRequest(url, {developer})
        .pipe(map( tickets=>{
            tickets[0].daysOpen= daysBetween(
                new Date( tickets[0].createdDate ), this.today
            );
            return new Ticket(tickets[0]);
        }))

    }
    addStakeholder(ticketId, stakeholder){
        let url = `tickets/${ticketId}/addstakeholder`

        return this.makePutRequest(url, stakeholder)
            .pipe(map( tickets=>{
                if(tickets.message){
                    return new DaedelusError(tickets);
                }
                tickets[0].daysOpen= daysBetween(
                    new Date( tickets[0].createdDate ), this.today
                );
                return new Ticket(tickets[0]);
            }))
    }
    removeStakeholder(ticketId, userEmail:string){
        //@todo impliment removal of stakeholder
        let url = `tickets/${ticketId}/removestakeholder/${userEmail}`;
        return this.makeDeleteRequest(url)
            .pipe(map(ticket =>new Ticket(ticket[0])));
    }
    listTicketComments(ticketId ):Observable<Array<_Comment>>{
        let url = `tickets/${ticketId}/comments`;
        return this.makeGetRequest(url)
            .pipe(map( comments=>{
              return (comments.map( comment=>new Comment(comment) ) as Array<Comment>).reverse();
            }));
    }
    createNewTicketComment(ticketId, comment:_CommentCreateModel):Observable<_Comment>{
        let url = `tickets/${ticketId}/comments`;
        return this.makePostRequest(url, comment)
            .pipe(map( comments=>{
                return new Comment(comments[0]);
                // return comments.map(comment=>new Comment(comment));
            }));
    }
    attachFilesToTicket(ticketId, files:Array<any>){
        let url = `tickets/${ticketId}/assets/attach`;
      
        return this.makePostRequest(url, {files});
    }
    requestImmediateService(ticketId){

        //@todo hit api endpoint to send notification to the developer asking can you prioritize this... if response is no sends to director of tech for further eval
    }
    createNewTask(ticketId, task:_TaskCreateModel):Observable<Task>{
        let url = `tickets/${ticketId}/tasks`;
        return this.makePostRequest(url, task); 
        // return null;
    }
    updateTask(ticketId, taskId, task){
        let url = `tickets/${ticketId}/tasks/${taskId}`;
        return this.makePutRequest(url, task);
    }
    deleteTask(ticketId, taskId):Observable<Task[]>{
        let url = `tickets/${ticketId}/tasks/${taskId}`;
        return this.makeDeleteRequest(url);
    }
    saveTaskList(ticketId, tasks:Array<Task>):Observable<Array<Task>>{
        return null;
    }
    getClosedStats(closedDateStart, closedDateEnd, fieldsToReturn = []):Observable<Array<Ticket>>{
        let url = `tickets/report/closedTickets`;
        url = this.urlParameters({fields: fieldsToReturn, closedDateStart, closedDateEnd}, url);
        return this.makeGetRequest(url);
        
    }
    getOpenedStats(createdDateStart, createdDateEnd, fieldsToReturn = []):Observable<Array<Ticket>>{
        let url = `tickets/report/openedTickets`;
        url = this.urlParameters({fields: fieldsToReturn, createdDateStart, createdDateEnd}, url);
        return this.makeGetRequest(url);
    }
    private formatTicket(ticket){
        ticket.daysOpen= daysBetween(
            new Date( ticket.createdDate ), this.today
        );
        return new Ticket(ticket);
    }
}
export interface FilterCriteria {

}
export abstract class TicketFilterCriteria implements FilterCriteria{
    status?:TicketStatus;
    developerId?:string;
    developerEmail?:string;
    stakeholderEmail?:Array<string>;
    stakeholderId?:Array<string>;
    department?:Department;
    paymentType?:string;
    workType?:string;
    createdDateStart?:number;
    createdDateEnd?:number;
    closedDateStart?:number;
    closedDateEnd?:number;
    createdByUserEmail?:string;
    createdByUserId?:string;
    salesforceId?:string;
    isQuote?:boolean;
    isClosed?:boolean;
    accountId?:number;
    activityStart?:number;
    activityEnd?:number;
    modifiedStart?:number;
    modifiedEnd?:number;
    /**
     * Import and Use BusinessUnit static properties as the string value
     */
    businessUnit?:string;
}