import { Tag } from './../../KanbanService/models/responseModels/Tag';
import { WorkCategory } from './../../KanbanService/models/responseModels/WorkCategory';
import { TagsService } from './../../KanbanService/services/tags.service';
import { Component, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { WORKTYPE } from 'app/KanbanService/models/enums/WorkType';
import { WorkCategoryForm } from './workCategory.form.component';
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from 'app/shared/confirmation/confirmation.component';
import { _TagCreateModel } from 'app/KanbanService';

@Component({
    selector: 'tags-component',
    templateUrl: `./views/tags/ticketTags.html`,
    styleUrls:[`./views/tags/ticketTags.scss`]
})
export class TagsComponent{
    displayedColumns = ['name', 'description'];
    dataSource: MatTableDataSource<any>;
    WORKTYPE = WORKTYPE;
    @ViewChild('tagName') tagName;
    @ViewChild('tagDescription') tagDescription;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(private tagService:TagsService, private dialog:MatDialog){
        this.dataSource = new MatTableDataSource<Element>([]);

    }
    ngOnInit(){
        this.tagService.listTags().subscribe(tags=>{
            this.dataSource = new MatTableDataSource<Tag>(tags);
            this.dataSource.paginator = this.paginator;
        })
    }
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    createTag($event){
        let newTag:_TagCreateModel = {
            name: this.tagName.nativeElement.value,
            description: this.tagDescription.nativeElement.value
        }
        if(!newTag.name){
            console.log('needs a name');
        }else{
            console.log(newTag);
            this.tagService.createTag(newTag).subscribe(
                _createdTag=>{
                    console.log(_createdTag);
                    const data = this.dataSource.data;
                    data.push(_createdTag);
                    this.dataSource.data = data;
                    this.tagName.nativeElement.value = "";
                    this.tagDescription.nativeElement.value = "";
                }
            )
        }
    }
    pageEvent($event){

    }
    altEvent($event){

    }
  }