import { TicketService } from './../KanbanService/services/ticket.service';
import { DialogPosition, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { TicketListService } from './../shared/ticketListService';
import { Component } from '@angular/core';
import { TicketStatus, Ticket } from 'app/KanbanService';
import { SingleTicketView } from 'app/singleTicket/singleTicket.component';

@Component({
    selector: 'ticket-view',
    template: ``
})
export class TicketView{

    constructor(private ticketService:TicketService, private ticketList:TicketListService, private activatedRoute:ActivatedRoute, private dialog:MatDialog ){
        this.activatedRoute.params.subscribe(
            param=>{
                if(param.ticketId){
                    this.ticketService.getTicketById(param.ticketId).pipe(map(tickets=>tickets[0])).subscribe(ticket=>{
                        this.showTicket(ticket);
                    })
                }
            }
        )

    }
    showTicket(ticket){ //@todo if appendPhase than i need to show open tasks when i open the ticket and remov ethe phase when im done.
        var previousTicketStatus = ticket.statusName();
        // this.ticketOpen = true;
        let pos:DialogPosition = {
            right: "0",
            // bottom: "0",
            // top: "69px"
        }
        let openData = {
            ticket: ticket,
            phase: null
        }
        const dial = this.dialog.open(SingleTicketView, {
            position: pos,
            data: openData,
            width:"60%",
            // height: "91%",
            panelClass: "ticket-details"
        });
        dial.afterClosed().subscribe((tix:Ticket)=>{
            let item = this.ticketList.listTickets().find(_tix=>_tix.id===ticket.id);
            if(!tix && item){ //ticket was deleted
                this.ticketList.updateTicket(item, true);

            }else if(item && tix.status !== TicketStatus.open){
                
                this.ticketList.updateTicket(tix);
            }
        })
    }
}