export var Contract = [
    {
        name: "Integration",
        description: "Install and configure a clients importer as well as update/redesign the blog to be in line with SEO standards",
        min: 3500,
        requirements: []
    },
    {
        name: "Upload/Update Content",
        description: "Upload content to the client site",
        min: 100,
        requirements: []
    },
    {
        name: "Interative Graphics",
        description: "Quizzes and other interactive content",
        min: 800,
        requirements: []
    },
    {
        name: "Site Build",
        description: "A Site Redesign, in conjunction with Consulatancy.  CONSULTANTS MUST OPEN THIS TICKET",
        min: null,
        requirements: []
    },
    {
        name: "HTML Email",
        description: "HTML email for use in a third party Email Platform. NOTE: This does NOT mean this can be a reusable template",
        min: null,
        requirements: []
    }
]

export var Unpaid = [
    {
        name: "General Support",
        description: "Aid in troubleshooting any issues, including style, related to brafton content provided to client by us.",
        min: null,
        requirements: []
    },
    {
        name: "Quote",
        description: "Quotes are needed for any work outside the scope of any of the other Unpaid services. If your work doesn't fit squarely inside the scope of the other Unpaid services your will REQUIRE a quote.",
        min: null,
        requirements: []
    },
    {
        name: "Tracking Analytics (gtag, social, ect)",
        description: "Add or Update any trackign codes for a site.  Select this option for any items related to any social tracking, Google Analytics or any other tracking.",
        min: null,
        requirements: []
    },
    {
        name: "Consultation",
        description: "Schedule a call with a developer or request research.",
        min: null,
        requirements: []
    },
    {
        name: "Broken Delivery",
        description: "Content did not get imported into the client site automatically as it was supposed to. ",
        min: null,
        requirements: []
    },
    {
        name: "Arch",
        description: "Work with Braftons Arch/Marpro/Pumpkin product",
        min: null,
        requirements: []
    },
    {
        name: "DNS (CNAME, A Record, ect)",
        description: "Create a cname (media subdomain) or other DNS work",
        min: null,
        requirements: []
    },
    {
        name: "SSL Certificate for Arch (media subdomain)",
        description: "If the client has a secure site ( https ) their video and picture urls must be as well. the media subdomain create for brafton content must have an ssl certificate.",
        min: null,
        requirements: []
    },
    {
        name: "Importer Setup",
        description: "Just setup of an importer on a clients site.  If ANY work is required in the client site this work either needs to be Quoted or a full integration price paid.",
        min: null,
        requirements: []
    },
    {
        name: "Prevously Quoted Work",
        description: "Custom Quoted work that has an accompanying Quote ticket",
        min: null,
        requirements: []
    }
]

export var Rolling = [
    {
        name: "Maintanance Package (Wordpress)",
        description: "Keep wordpress and all plugins up to date.",
        min: null,
        requirements: []
    },
    {
        name: "Maintanance Package (Drupal)",
        description: "Keep drupal and all modules up to date",
        min: null,
        requirements: []
    }
]
export var Ops = [

]

export var Sys = [

]