import { AuthenticatedUser } from 'app/shared/authenticatedUser.service';
import { AccountNoteService } from './../../../KanbanService/services/accountNote.service';
import { TICKETSTATUS } from './../../../KanbanService/models/enums/TicketStatus';
import { Component, ViewChild } from '@angular/core';
import { SingleTicketView } from 'app/singleTicket/singleTicket.component';
import { MatDialog, DialogPosition, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Ticket, TicketService } from 'app/KanbanService';
import { ActivatedRoute } from '@angular/router';
import { CoreApiAccountService } from 'app/services/core/core-api.service';
import * as CanvasJS from '../../../shared/canvasjs.min';
import * as moment from 'moment';
import { AccountNotesComponent } from '../../../KanbanService'
@Component({
    selector: 'single-account-component',
    templateUrl: './singleAccount.component.html',
    styleUrls: ['./singleAccount.scss']
})
export class SingleAccountComponent{
    public tickets:Ticket[] = [];
    public feeds:[] = [];
    public videoCreds = null;
    public account;
    public accountId;
    public TICKETSTATUS = TICKETSTATUS
    public loadingTickets = true;
    public loadingTicketCountChart = true;
    displayedColumns = ['subject', 'WorkCategory','unitsAllocated', 'Hours', 'createdDate', 'closedDate', 'status', 'actions'];
    dataSource:MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor( public dialog: MatDialog,protected _activatedRoute:ActivatedRoute,private _coreService:CoreApiAccountService, private _ticketService:TicketService, private _accountNoteService:AccountNoteService, public authenticatedUser:AuthenticatedUser
        ){
            this.dataSource = new MatTableDataSource<Element>([]);

        }
        
        ngAfterViewInit(){
            this._activatedRoute.params.subscribe(
                param=>{
                    console.log(param);
                    if(param.accountId){
                        this.accountId = param.accountId;
                        if(this._coreService.isReady){
                            this.getTickets();
                            // this.getAccountNotes();
                        }else{
                            this._coreService.authenticated().subscribe(
                                auth=>{
                                    if(auth){
                                        this.getTickets();
                                        // this.getAccountNotes();
                                    }
                                }
                            )
                        }
                    }
                }
            )
            // this.buildChart();
        }
        buildChart(openedTotals){
            var actual = []
            var start = moment().subtract(11, 'months');
            // console.log(allocatedUnits, usedUnits);
            for(var i=0;i<12;i++){
                actual.push({
                    x: new Date(start.year(), start.month()),
                    y: (openedTotals[start.month()])
                })
                start.add(1, 'month');
            }
            var chart = new CanvasJS.Chart("chartContainer", {
                animationEnabled: true,
                backgroundColor: null,
                title:{
                    text: "Rolling 12 Month Tickets Opened"
                },
                axisY :{
                    includeZero: false,
                    prefix: ""
                },
                toolTip: {
                    shared: true
                },
                legend: {
                    fontSize: 13
                },
                data: [{
                    type: "splineArea",
                    showInLegend: true,
                    name: "Tickets",
                    yValueFormatString: "#,##0.0#\" Tickets\"",                   
                    xValueFormatString: "MMM YYYY",
                    dataPoints: actual
                 }]
            });
            this.loadingTicketCountChart = false;
            chart.render();
        }
        convertTickets(feed){
            
        }
        // getAccountNotes(){
        //     this._accountNoteService.listAccountsNotes(this.accountId).subscribe(
        //         accountNotes=>{

        //         }
        //     )
        // }
        // createAccountNote(){

        // }
        getTickets(){
            
            this._coreService.getFeeds(this.accountId).subscribe(
                feeds=>{
                    console.log(feeds);
                    this.feeds = feeds;
                    this.feeds.map((feed:any)=>{
                        this._coreService.getCdnInfo(feed.id).subscribe(cdn=>{
                            console.log(cdn);
                            feed.cdn = cdn;
                        })
                    })
                }
            );
            this._coreService.getVideoCreds(this.accountId).subscribe(
                vid=>{
                    console.log(vid);
                    this.videoCreds = vid;
                }
            )
            this._coreService.getAccountInfo(this.accountId).subscribe(
                account=>{
                    console.log(account);
                    this.account = account;
                    this._ticketService.listTickets({accountId: this.accountId}).subscribe(
                        _tickets=>{
                            // console.log(_tickets);
                            this.tickets = _tickets.sort((a,b)=>{
                                if(a.createdDate < b.createdDate){return -1}
                                if(a.createdDate > b.createdDate){return 1}
                                return 0;
                            });
                            this.loadChartData();
                            this.dataSource = new MatTableDataSource<Ticket>(this.tickets);
                            this.dataSource.paginator = this.paginator;
                            this.dataSource.sort = this.sort;
                            this.loadingTickets = false;
                        }
                    )
                }
            )
            
        }
        loadChartData(){
            let start = moment().subtract(11, 'months').unix() * 1000;
            let today = moment().unix() * 1000;
            var tickets = this.tickets.filter(tix=>{
                return tix.createdDate > start && tix.createdDate < today;
            })
            var totalOpened = new Array(12).fill(0);
            tickets.map(tix=>{
                let createdMonth = moment(tix.createdDate).month();
                ++totalOpened[createdMonth];
            });
            // console.log(totalOpened);
            this.buildChart(totalOpened);

        }
    showTicket(ticket){
        console.log(ticket);
        var previousTicketStatus = ticket.statusName();
        let pos:DialogPosition = {
            right: "0",
            // bottom: "0",
            // top: "69px"
        }
        this.dialog.open(SingleTicketView, {
            position: pos,
            data: {
                ticket: ticket
            },
            width:"65%",
            // height: "91%",
            panelClass: "ticket-details"
        }).afterClosed().subscribe((tix:Ticket)=>{
            console.log(tix);

            //closed not being removed from list???? 
            
        })
    }
    pageEvent($event){

    }
    altEvent($event){

    }
}