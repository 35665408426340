import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { KanbanApiConfig } from 'app/KanbanService/shared/api.config';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PassShackService{
    public isReady = false;
    private subject:Subject<any> = new Subject();
    private baseUrl = "https://passshack-api.staging.tech.brafton.com/api/v1/";

    constructor(private _http:HttpClient
    ){}

    getClientCreds(client_id){
        let url = this.baseUrl + `clients/findByAccountId/${client_id}`;
        return this._http.get(url).pipe(
            map(response=>response[0]));
    }

}
