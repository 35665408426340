import { AccountNoteUpdateModel } from './../../models/requestUpdateModels/AccountNoteUpdateModel';
import { CreateAccountNoteDialog } from './createAccountNote/createAccountNote.component';
import { MatDialog } from '@angular/material';
import { AccountNoteService } from './../../services/accountNote.service';
import { Component, Input, AfterViewInit, SimpleChanges } from '@angular/core';
import { AccountNote } from 'app/KanbanService/models/responseModels/AccountNote';
import { User } from 'app/KanbanService/models/responseModels/User';
import { AccountNoteCreateModel } from 'app/KanbanService/models/requestCreateModels/AccountNoteCreateModel';


@Component({
    'selector': 'account-notes',
    'templateUrl': './accountNotes.component.html',
    'styleUrls': ['./accountNotes.scss']
})
export class AccountNotesComponent implements AfterViewInit{
    @Input() accountId:string;
    @Input() userId:string|number;
    public accountNotes:Array<AccountNote>;
    constructor(private _accountNotesService:AccountNoteService, private _dialog:MatDialog){

    }
    ngAfterViewInit(){
    //    this.retrieveNotes();
    if(this.accountId){
        this.retrieveNotes();
    }
    }
    retrieveNotes(){
        this._accountNotesService.listAccountsNotes(this.accountId).subscribe(
            accountNotes=>{
                console.log(accountNotes);
                this.accountNotes = accountNotes;
            }
        )
    }
    ngOnChanges(changes:SimpleChanges){
        console.log(changes, this.accountId);
        if(changes.accountId && changes.accountId.currentValue !== changes.accountId.previousValue){
            this.retrieveNotes();
        }
    }
    createAccountNote(){
        this._dialog.open(CreateAccountNoteDialog).afterClosed().subscribe(
            (note:AccountNoteCreateModel)=>{
                if(note != null){
                    note.accountId = this.accountId.toString();
                    note.createdBy = this.userId.toString();
                    this._accountNotesService.createAccountNote(note).subscribe(
                        cratedNote=>{
                            this.accountNotes.push(cratedNote);
                        }
                    )
                }
            }
        )
    }
    updateAccountNote(note:AccountNote){
        console.log(note);
        this._dialog.open(CreateAccountNoteDialog, {
            data: {
                note:note
            }
        }).afterClosed().subscribe(
            (_note:AccountNoteUpdateModel)=>{
                if(_note != null){
                    _note.id = note.id;
                    _note.accountId = note.accountId.toString();
                    _note.createdBy = note.createdBy.id;
                    _note.updatedBy = this.userId.toString();
                    this._accountNotesService.updateAccountNote(_note).subscribe(
                        updatedNote=>{
                            let index = this.accountNotes.findIndex(note=>note.id ==updatedNote.id);
                            this.accountNotes[index] = updatedNote;
                        }
                    )
                }
            }
        )
    }
    deleteAccountNote(){

    }
}