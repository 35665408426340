import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

@Component({
    selector: 'multi-select-dialog',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.scss']
})
export class MultiselectDialogComponent{

    public message = "";
    public title = "";
    public subtitle = null;
    public options = [];
  
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<MultiselectDialogComponent>, private toasty:Toasty){
        
        this.message =  data.message || "";
        this.title = data.title || "";
        this.subtitle = data.subtitle || null;
        this.options = data.options;
 

    }
    updateOptions($event,option){
        let index = this.options.findIndex(op=>op.name==option.name);
        this.options[index].selected = $event.checked;

    }

    closeTrue(){
        this.dialogRef.close(this.options);
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}

export interface ConfirmationDialogOptions{
    message:string;
    title?:string;
    confirm?:boolean;
    subtitle?:string;
    options?:[{name:string,selected:boolean}]
}