import { Subscription } from 'rxjs';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Department, Ticket } from 'app/KanbanService';
import { DialogPosition, MatDialog } from '@angular/material';
import { SingleTicketView } from '../singleTicket.component';
import { TicketActions } from '../ticketActionsEnum';
import * as moment from 'moment';
import { EventService } from 'app/shared/event.service';

@Component({
    selector: 'preview-ticket',
    templateUrl: './previewTicket.component.html',
    styleUrls: ['./previewTicket.scss']
})
export class PreviewTicketComponent{

    @Input() Ticket:Ticket;
    @Input() Column = {};
    @Output() previewTicketChange:EventEmitter<any> = new EventEmitter<TicketChanges>(); 
    public _Department = Department;
    public overdue = false;
    public halfway = false;
    public nearComplete = false;
    public newMessage = false;
    private subs:Subscription[] = [];
    private ticketOpen = false;
    constructor(private dialog:MatDialog, private _eventService:EventService){

    }
    ngOnInit(){
        this.determineTimeLeft();
        this.subs.push(this._eventService.newTicketCommentAsync().subscribe(notificationEvent=>{
            if(notificationEvent.entity == this.Ticket.id){
                this.newMessage = true;
            }
        }),
        this._eventService.newDeveloperAsync().subscribe(
            notificationEvent=>{
                if( (notificationEvent.entity == this.Ticket.id) && !this.ticketOpen){
                    //udpate just the dev.
                    // console.log("new dev assigned");
                    // console.log(notificationEvent);
                    this.Ticket = new Ticket(notificationEvent.data.ticket);
                }
            }
        ),
        this._eventService.ticketStatusAsync().subscribe(
            notificationEvent=>{
                if(notificationEvent.entity == this.Ticket.id){
                    if(!this.ticketOpen){
                        this.Ticket = new Ticket(notificationEvent.data.ticket);
                        this.previewTicketChange.emit({
                            action: TicketActions.CloseWindow,
                            data: null,
                            ticket: this.Ticket
                        });
                    }
                }
            }
        ),
        this._eventService.newStakeholderAsync().subscribe(
            notifictionEvent=>{
                if(notifictionEvent.entity == this.Ticket.id){
                    if(!this.ticketOpen){
                        console.log(notifictionEvent);
                        this.Ticket.stakeholders = notifictionEvent.data.stakeholders;
                        this.previewTicketChange.emit({
                            action: TicketActions.CloseWindow,
                            data: null,
                            ticket: this.Ticket
                        });
                    }
                }
            }
        ))
        //create listener for if a new comment is set this way that belongs to this ticket if so turn on the blinking message button
    }
    ngOnDestroy(){

    }
    determineTimeLeft(){
        if(this.Ticket.unitsAllocated){
            let expectedTime = this.Ticket.unitsAdjusted? this.Ticket.unitsAdjusted / 2 : this.Ticket.unitsAllocated / 2; 
            let dueDate = this.Ticket.dueDate;
            if(this.Ticket.time['progress'] >= expectedTime){ //overdue major callout that persists
                this.overdue = true;
            }else if( this.Ticket.time['progress'] >= (expectedTime * .75)) { //3/4 of the time have elapsed. should be finishing up reminder callout that persists
                this.nearComplete = true;
            }else if( this.Ticket.time['progress'] >= (expectedTime / 2)){ // 1/2 way through the project. simple notification
                this.halfway = true;
            }
        }
    }
    showTicket(){
        // console.log(this.Ticket);
        var previousTicketStatus = this.Ticket.statusName();
        this.ticketOpen = true;
        let pos:DialogPosition = {
            right: "0",
            // bottom: "0",
            // top: "69px"
        }
        const dial = this.dialog.open(SingleTicketView, {
            position: pos,
            data: {
                ticket: this.Ticket,
                warnings: {
                    overdue: this.overdue,
                    halfway: this.halfway,
                    nearComplete: this.nearComplete
                }
            },
            width:"65%",
            // height: "91%",
            panelClass: "ticket-details"
        });
        dial.afterClosed().subscribe((tix:Ticket)=>{
            console.log(tix);
            if(!tix){
                console.log("ticket was deleted");
                this.previewTicketChange.emit({
                    action: TicketActions.DeletedTicket,
                    data: null,
                    ticket: this.Ticket
                });
            }else{
                this.newMessage = false;
            this.ticketOpen = false;
            this.Ticket = tix;
            this.previewTicketChange.emit({
                action: TicketActions.CloseWindow,
                data: null,
                ticket: tix
            });
            }
           
        })
    }
    
}

export interface TicketChanges{
    action:TicketActions;
    data:any;
    ticket:Ticket;
}