import { takeUntil } from 'rxjs/operators';
import { Component, Inject } from '@angular/core';
import { WorkService, WorkCategoryCreateModel } from 'app/KanbanService';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkType, WORKTYPE } from 'app/KanbanService/models/enums/WorkType';
import { WorkCategoryUpdateModel } from 'app/KanbanService/models/requestUpdateModels/WorkCategoryUpdateModel';
import { FileUploadComponent } from 'app/shared/fileUploader/fileUpload.Component';
import { Subject } from 'rxjs';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
@Component({
    selector: 'workcategory-form',
    templateUrl: './views/workCategory/workCategory.form.html',
    styleUrls: ['./views/workCategory/workCategory.form.scss']
})
export class WorkCategoryForm{
    private destroySubs$ = new Subject<boolean>();
    selected = 2;
    verb = "Create";
    formFields:FormGroup;
    typeList = WORKTYPE;
    taskList = [];
    requirementList = [];
    files = [];
    catId = null;
    constructor(@Inject(MAT_DIALOG_DATA) private data, private dialog:MatDialogRef<WorkCategoryForm>, 
    private _formBuilder:FormBuilder, private workcategoryService: WorkService, private popup:MatDialog, private toasty:Toasty){
        if(data.workCategory){
            this.verb = "Update";
            this.catId = this.data.workCategory.id;
            this.taskList = this.data.workCategory.tasks;
            this.requirementList = this.data.workCategory.requirements;
            this.files = this.data.workCategory.files? this.data.workCategory.files: []; 
        }
    }
    ngOnInit(){
        // console.log(WORKTYPE);
        this.formFields = this._formBuilder.group({
            name: [''],
            description: [''],
            min:[''],
            requirements: [''],
            tasks: [''],
            workType: []
        })
        if(this.data.workCategory){
            this.formFields.get('name').setValue(this.data.workCategory.name);
            this.formFields.get('description').setValue(this.data.workCategory.description);
            this.formFields.get('min').setValue(this.data.workCategory.min);
            this.formFields.get('workType').setValue(parseInt( this.data.workCategory.workType) );
        }
    }
    addTask($event){
        if($event.code == "Enter"){
            let newTask = $event.target.value;
            this.taskList.push(newTask);
            $event.target.value = '';
        }

    }
    addRequirment($event){
        if($event.code == "Enter"){
            let newReq = $event.target.value;
            this.requirementList.push(newReq);
            $event.target.value = '';
        }
    }
    remove(list, index){
        this[list].splice(index, 1);
    }
    submitForm($event){
        if(this.formFields.invalid || $event.code == "Enter"){
            return false;
        }
        
        let workCategory:WorkCategoryCreateModel = {
            name: this.formFields.get('name').value,
            description: this.formFields.get('description').value,
            workType: parseInt(this.formFields.get('workType').value)
        };
        workCategory.tasks = this.taskList;
        workCategory.requirements = this.requirementList;
        workCategory.min = this.formFields.get('min').value;
        if(this.files.length > 0){
            workCategory.files = this.files;
        }
        if(this.catId){
            let updateWorkCategory:WorkCategoryUpdateModel = <WorkCategoryUpdateModel>workCategory;
            this.workcategoryService.updateWorkCategory(this.catId, updateWorkCategory).subscribe(
                updated=>{
                    this.dialog.close(updated);
                }
            )
        }else{
            //start the loading bar
            this.workcategoryService.createWorkCategory(workCategory).subscribe(
                created=>{
                    //end the loading bar
                    this.dialog.close(workCategory);
                }
            )
        }
        return false;
    }
    uploadFiles($event){
        //popup for file upload
        var name = this.formFields.get('name').value;
        if(!name){
            this.toasty.error("You must enter a name first to continue");
            return;
        }
        this.popup.open(FileUploadComponent,{
            data:{
                ticketId: this.formFields.get('name').value
            },
            width: '50%'
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            filesInfo=>{
                console.log(filesInfo);
               //add the file url to the list of uploaded files.
               if(filesInfo && filesInfo.length > 0){
                this.files.push(...filesInfo);
               }
            }
        )
        // $event.source.value = null;
    }
}