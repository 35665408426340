import { Injectable, ViewContainerRef, Component, Inject } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material';
import { ToastrService, ActiveToast, IndividualConfig, ToastrConfig } from 'ngx-toastr';
@Injectable()
export class Toasty{
    private vcr:ViewContainerRef = null;
    // public config:MatSnackBarConfig =  {};
    public t_config:IndividualConfig = {
        disableTimeOut: false,
        timeOut: 5000,
        closeButton: true,
        extendedTimeOut: 2000,
        progressBar: true,
        progressAnimation: 'decreasing',
        enableHtml: true,
        toastClass: 'ngx-toastr',
        positionClass: 'toast-top-right',
        titleClass: 'toast-title',
        messageClass: 'toast-message',
        easing: 'ease-in',
        easeTime: 300,
        tapToDismiss: true,
        onActivateTick: false
    };
    constructor(
        private _toasty:ToastrService, 
        // public snackBar: MatSnackBar
        ){
        // this.config.duration = 6000;
        // this.config.panelClass = [];
        // this.config.panelClass.push("toasty");
        this.t_config.closeButton = true;
        this.t_config.progressBar = true;

    }

    // setViewContainerRef(vcr:ViewContainerRef){
    //     if(this.vcr){
    //         return "already set";
    //     }
    //     this.vcr = vcr;
    //     // this._toasty.setRootViewContainerRef(vcr)
    // }
    warning(message:string, title?:string, options:object = {}):ActiveToast<any>{
        
        var config = Object.assign({},options, JSON.parse(JSON.stringify(this.t_config) ));
        // config.panelClass.push("snackbar-warning");
        // config.data = {
        //     message: message,
        //     title: title,
        //     icon: "warning"
        // }
        // return this.snackBar.openFromComponent(ToastNotify, config);
        return this._toasty.warning(message, title, config);
    }
    error(message:string, title?:string, options:object = {}):ActiveToast<any>{
        
        var config = Object.assign({},options,JSON.parse(JSON.stringify(this.t_config) ));
        // config.panelClass.push("snackbar-error");
        // config.data = {
        //     message: message,
        //     title: title,
        //     icon: "error"
        // }
        // return this.snackBar.openFromComponent(ToastNotify,config);
        return this._toasty.error(message, title, config);
    }
    success(message:string, title?:string, options:object = {}):ActiveToast<any>{
        
        var config = Object.assign({},options,JSON.parse(JSON.stringify(this.t_config) ));
        
        // config.panelClass.push("snackbar-success");
        // config.data = {
        //     message: message,
        //     title: title,
        //     icon: "check"
        // }
        // return this.snackBar.openFromComponent(ToastNotify,config);
        return this._toasty.success(message, title, config)
    }
    custom(message:string, title?:string, options:object = {}):ActiveToast<any>{
        var config = Object.assign({},options,JSON.parse(JSON.stringify(this.t_config) ));
        // config.panelClass.push("snackbar-custom");
        // config.data = {
        //     message: message,
        //     title: title
        // }
        // return this.snackBar.openFromComponent(ToastNotify,config);
        return this._toasty.info(message, title, options)
    }
    info(message:string, title?:string, options:object = {}):ActiveToast<any>{
        var config = Object.assign({},options, JSON.parse(JSON.stringify(this.t_config) ));
        // // config.panelClass.push("snackbar-info");
        // config.data = {
        //     message: message,
        //     title: title,
        //     icon: "info"
        // }
        // return this.snackBar.openFromComponent(ToastNotify, config);
        return this._toasty.info(message, title, config);
    }
    // getToasty():ToastrService{
    //     return this._toasty;
    // }
}
@Component({
    selector: 'toast-notify',
    template: `<h4 *ngIf="title">{{title}}</h4>
        <div class="content">
        <i *ngIf="icon" class="material-icons">{{icon}}</i>
            <p>{{message}}</p>
        </div>`,
    styles: [`
        h4{
            font-size:20px;
        }
        .content{
            display:flex;

        }
        .content p{
            margin:0px;
        }
        .material-icons{
            font-size: 2em !important;
            margin-right:20px;
        }
    `]
})
export class ToastNotify{
    public title = null;
    public icon = null;
    public message = "";
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any){
        this.title = data.title || null;
        this.icon = data.icon || null;
        this.message = data.message;
    }
}