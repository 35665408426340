import { Ticket, User } from "../KanbanService";

export class TicketSorting{

    /**
     * Filter Tickets by a single field
     * @param tickets array of tickets to sort through
     * @param field field name for compare
     * @param value Value can be an array of values to search for as well as a single value
     */
    public static filterByField(tickets:Array<Ticket>, field:string, value:any):Array<Ticket>{
        try{
            if(Array.isArray(value)){
                //loop thorugh the values and check for them all
                return tickets.filter(ticket=>value.indexOf(ticket.status) > -1);
            }
            return tickets.filter(ticket=>ticket[field]==value);
        }catch(e){
            console.log(e);
            return [];
        }
    }

    /**
     * filter tickets by a field contained in an array from a list of tickets
     * @param tickets array of ticket to filter
     * @param field the array field name
     * @param property the property in the array to filter by
     * @param value the value to filter
     */
    public static filterByFieldArray(tickets:Array<Ticket>, field:string, property:string, value:string|number):Array<Ticket>{
        try{
            return tickets.filter((ticket:Ticket)=>{
                return ticket[field].findIndex(prop=>{
                    return prop[property] === value;
                }) > -1
            })
        }catch(e){
            console.log(e);
            return [];
        }
    }
}