import { ConfirmationDialogOptions } from 'app/shared/confirmation/confirmation.component';
import { ConfirmationDialogComponent } from './shared/confirmation/confirmation.component';
import { TicketListService } from './shared/ticketListService';
import { AuthenticatedUser, PermissionSet } from './shared/authenticatedUser.service';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, Inject, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { MatIconModule, MatIconRegistry, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
//Brafton theme files
import { NectarModule } from '@brafton/nectar';
import { BfSidenav } from '@brafton/nectar/bf-sidenav/index';

import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
//operators
import { DOCUMENT } from "@angular/platform-browser";
import { TicketPriority, TicketStatus, Ticket } from './KanbanService';
import { NewTicketDialog } from './singleTicket/newTicket/newTicket.component';
import { AppKanbanConfig } from './app.config';

const { version: appVersion } = require('../../package.json');

@Component({
    moduleId: module.id,
    selector: 'my-app',
    providers: [ MatIconRegistry],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy { 
    @ViewChild(BfSidenav) private sidenav: BfSidenav;
    // @ViewChild('newProjectPopup') private newProjectPopup:PopUpComponent;
    public destUrl:string;
    public userIcon:string = "";
    public userIconColor:string = 'orange';
    private colors:string[]= [
        'orange',
        'blue',
        'yellow',
        'pink',
        'red'
    ];
    public ws:WebSocket;
    // public testing;
    public subscriptions:Subscription = new Subscription();
    public appVersion;
    public projectName = null;
    public isAdmin = false;
    private env;
    public PermissionSet = PermissionSet;
    // public ticketNumbers = this._ticketList.ticketCounts;
    constructor(
        public authenticatedUser:AuthenticatedUser,
        private changeDet:ChangeDetectorRef,
        public bfAuth:BfAuthService,
        vcr:ViewContainerRef,
        private _router:Router,
        private _route:ActivatedRoute, 
        public _http:HttpClient, 
        public dialog: MatDialog,
        @Inject(DOCUMENT) private document:any,
        public _ticketList:TicketListService,
        ){
            this.env = AppKanbanConfig.getEnv('env');
            
            this.bfAuth.handleAuthentication();
            this.appVersion = appVersion;
         _router
            .events
            .pipe(filter(e => e instanceof NavigationStart))
            .subscribe((e: NavigationStart) => {
                this.destUrl = e.url;
            });
        this.userIconColor = this.colors[Math.floor(Math.random()*this.colors.length)]
    }
    ngOnInit(){
        this.onWindowResize();
        if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin, PermissionSet.readSettings])){
            this.isAdmin = true;
        }
        //determine if there are "unassigned" tickets and a developer is logged in... do a popup notifying them there is an unassigned ticket. please review and assign yourself if appropriate.
        if(this.bfAuth.authenticated() && this.env == "dev"){
            //popup that this env is no longer used. please go to the correct one
            this.dialog.open(ConfirmationDialogComponent, {
                panelClass: "dev-env",
                backdropClass: "dev-env-drop",
                data: {
                    title: "THIS IS A DEVELOPMENT ENVIRONMENT",
                    message: 'Prior to 4/30/2020 this environment was actively used by memebers of brafton. Moving forward you will need to use <a href="https://kanban.brafton.com">https://kanban.brafton.com</a> as this is now a development sandbox. If you continue to use this site your work will not be recognized and therefore will not be scheduled. No responses will be given to any messages left in this system and all data here is considered outdated and obsolete.'
                }
            })
        }
        
    }

    ngOnDestroy(){}

    navigate(pageName:string, parameters:Object = null){
        if(parameters){
            this._router.navigate([pageName], {queryParams: parameters});
        }else{
            this._router.navigate([pageName]);
        }
    }
    newProjectCreated(projectId){
        this.navigate('projects', [projectId]);
    }
  
    clickMail(){
        console.log("mail");
    }
    openTicket(){
        //do a popup for the fun of opening a new ticket, inside be srue to pass to the observable of newTickets that will register when you open a new ticket for dashboard component. 
        //check if dev or admin
        console.log(this.authenticatedUser.currentUser.teamId);
        if(this.authenticatedUser.currentUser.teamId == 10 || this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
            this.dialog.open(NewTicketDialog, {
                data: {
    
                },
                height: '70%'
            }).afterClosed().subscribe(res=>{
                console.log(res);
            })

        }else{
            var data:ConfirmationDialogOptions = {
                title: "Atlas Setup is required.",
                message: "Quotes and \"Free\" work must be setup in Atlas with units assigned. An Altas project must be setup for:<br/> <ul><li><b>A quote</b>: Please create an Atlas proposal request and complete a brief to attach to the proposal project.</li><li><b>Free Work</b>: Work that would be \"free work\" ie. Analtyics tracking setup, Broken Delivery of content via our API please create a 1 unit SOW#0 atlas project under tech work.</li><li><b>General Troublshooting</b> For general issues with a clients site there is a debug and troubleshoot project type in atlas that starts at 4 units, this would be chargable to the client.</li></ul><br/> If you have any questions or are unsure how to proceed please email techmanagment@brafton.com for additional guidance around your specific request.",
                confirm: true
            }
            this.dialog.open(ConfirmationDialogComponent, {
                data: data
            })
        }
        


        //fake implimentation
        
    }
    menuClick(){ //show/hide side navigation
        this.sidenav.toggleMenu();
    }
    logUserOut(){
        this.bfAuth.logout();
        this._router.navigate(["/login"]);
    }
    @HostListener('window:resize') onWindowResize(){
        let width = window.innerWidth;
        let height = window.innerHeight;
        this.document.body.style.height = (height - 64)+'px';
    }
}
