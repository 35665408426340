import { MatTableDataSource } from '@angular/material';
export class Group {
    level = 0;
    parent: Group;
    expanded = true;
    totalCounts = 0;
    get visible(): boolean {
      return !this.parent || (this.parent.visible && this.parent.expanded);
    }
  }

export class GroupDataTables{
        _alldata:any[];
        columns: any[];
        displayedColumns: string[];
        groupByColumns: {field:string,name: string}[] = [];
        public dataSource = new MatTableDataSource<any | Group>([]) ;
        getValue(object, keys){
            
            return {obj: object, value: keys.split('.').reduce((o, k) => (o || {})[k], object)};
        }
        ///grouping functionality from https://stackblitz.com/edit/angular-material-table-row-grouping?file=src%2Fapp%2Fapp.component.ts
        groupBy(event, column) {
            event.stopPropagation();
            this.checkGroupByColumn(column, true);
            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
            console.log(this.groupByColumns);
            this.dataSource.filter = performance.now().toString();
          }
        
          checkGroupByColumn(_column, add ) {
            let found = null;
            for (const column of this.groupByColumns) {
              if (column.field === _column.field) {
                found = this.groupByColumns.findIndex(column=>column.field == _column.field, 0);
              }
            }
            if (found != null && found >= 0) {
              if (!add) {
                this.groupByColumns.splice(found, 1);
              }
            } else {
              if ( add ) {
                this.groupByColumns.push(_column);
              }
            }
          }
        
          unGroupBy(event, column) {
            event.stopPropagation();
            this.checkGroupByColumn(column, false);
            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
            this.dataSource.filter = performance.now().toString();
          }
        
          // below is for grid row grouping
          customFilterPredicate(data: any | Group, filter: string): boolean {
            return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
          }
        
          getDataRowVisible(data: any): boolean {
            const groupRows = this.dataSource.data.filter(
              row => {
                if (!(row instanceof Group)) {
                  return false;
                }
                let match = true;
                this.groupByColumns.forEach(column => {
                    // console.log(row, data, column);
                  if (!row[column.field] || !this.getValue(data,column.field) || row[column.field] !== this.getValue(data,column.field)) {
                    match = false;
                  }
                });
                // console.log(match);
                return match;
              }
            );
        
            if (groupRows.length === 0) {
              return true;
            }
            const parent = groupRows[0] as Group;
            return parent.visible && parent.expanded;
          }
        
          groupHeaderClick(row) {
              console.log(row);
            row.expanded = !row.expanded;
            this.dataSource.filter = performance.now().toString();  // bug here need to fix
          }
        
          addGroups(data: any[], groupByColumns: {field:string,name:string}[]): any[] {
            const rootGroup = new Group();
            rootGroup.expanded = true;
            return this.getSublevel(data, 0, groupByColumns, rootGroup);
          }
        
          getSublevel(data: any[], level: number, groupByColumns: {field:string,name:string}[], parent: Group): any[] {
            if (level >= groupByColumns.length) {
              return data;
            }
            console.log(groupByColumns, data);
            const groups = this.uniqueBy(
              data.map(
                row => {
                  
                  const result = new Group();
                  result.level = level + 1;
                  result.parent = parent;

                  for (let i = 0; i <= level; i++) {
                    if(groupByColumns[i].field == 'developer.name'){
                      result['totalUnits'] = 0;
                      result['timeProgress']  = 0;
                    }
                    result[groupByColumns[i].field] = this.getValue(row, groupByColumns[i].field);
                  }
                  // if(result['timeProgress']){
                  //   result['timeProgress'] = result['timeProgress'] / 60;
                  // }
                  return result;
                }
              ),
              JSON.stringify);
        
            const currentColumn = groupByColumns[level];
            let subGroups = [];
            groups.forEach(group => {
              const rowsInGroup = data.filter(row => {
                //   console.log(group);
                  return group[currentColumn.field] === this.getValue(row,currentColumn.field)
              })
              rowsInGroup.forEach(ticket=>{
                 if(group['totalUnits'] === 0 || group['totalUnits'] > 0){
                  group['totalUnits'] = group['totalUnits'] + (ticket.unitsAllocated? parseFloat(ticket.unitsAllocated): 0);
                  group['timeProgress'] = group['timeProgress'] + ticket.time.progress;
                    }
              })
              group.totalCounts = rowsInGroup.length;
              //data manipulation needs to go here
              const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
              subGroup.unshift(group);
              subGroups = subGroups.concat(subGroup);
            });
            return subGroups;
          }
        
          uniqueBy(a, key) {
            const seen = {};
            return a.filter((item) => {
              const k = key(item);
              return seen.hasOwnProperty(k) ? false : (seen[k] = true);
            });
          }
        
          isGroup(index, item): boolean {
            return item.level;
          }
}