import { AccountCreateModel } from './../models/requestCreateModels/AccountCreateModel';
import { DataService } from './../shared/data.service';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators';
import { Account } from '../models/responseModels/Account';
import { _Tag } from '../models/interfaces/responseModels/_Tag';
import { Tag } from '../models/responseModels/Tag';
import { _TagCreateModel } from '../models/interfaces/requestCreateModels/_TagCreateModel';

@Injectable()
export class TagsService extends DataService {
    // constructor(_http:Http) {
    //     super(_http);
    // }
    listTags():Observable<Array<Tag>>{
        let url = `tags`;
        return this.makeGetRequest(url)
            .pipe(map( (tags)=>{
                return tags.map(tag=>{
                    return new Tag(tag);
                })
            }))
    }
    createTag(tag:_TagCreateModel):Observable<Tag>{
        let url = `tags`;
        return this.makePostRequest(url, tag)
            .pipe(map( (tags)=>{
                return new Tag(tags[0])
            }))
    }
}
