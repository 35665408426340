import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';

@Component({
    selector: 'phaseConfirmation-dialog',
    templateUrl: './phaseConfirmation.component.html',
    styleUrls: ['./phaseConfirmation.scss']
})
export class PhaseConfirmationDialogComponent{

    public phaseList = [];
    public displayedColumns = ["select", "title", "allocatedUnits", "assignedUser"];
    public selectedPhase = null;
    public dataSource:MatTableDataSource<_TaskCreateModel>;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<PhaseConfirmationDialogComponent>, private toasty:Toasty){
        
        this.phaseList = data.phases || [];
    }
    ngOnInit(){
        this.dataSource = new MatTableDataSource<_TaskCreateModel>(this.phaseList);
    }
    selectPhase($event){
        this.selectPhase = $event.value;
    }
    closeTrue(){
        this.dialogRef.close(this.selectedPhase);
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}