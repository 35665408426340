
import { User } from "../responseModels/User";

import { Client } from "../responseModels/Client";
import { Asset } from "../responseModels/Asset";
import { Comment } from "../responseModels/Comment";
import { Department } from "../enums/Department";
import { TicketStatus } from "../enums/TicketStatus";
import { _TicketCreateModel } from "../interfaces/requestCreateModels/_TicketCreateModel";
export class TicketCreateModel extends _TicketCreateModel{
    // accountManager:User;
    // developer:User;
    // department:Department;
    // stakeholders?:Array<User>;
    // // free, paid, swap
    // paymentType:string;
    // //$ amount paid if applicable
    // pricePaid?:number;
    // //Units allocated. if empty and pricePaid is greater than 0 this will calculate automatically
    // unitsAllocated?:number;
    // status:TicketStatus;
    // account?:Client;
    // // support, contract, rolling
    // workType:string;
    // //bucket of preselected work Categories
    // workCategory:string;
    // //user entered string of what the work title is
    // subject:string;
    // scope:string;
    // assets?:Array<Asset>;
    // //if there is a daedelus project this is the url.. this platform will link directly with daedelus so a ticket can be opened from deadelus automatically.
    // daedelusUrl?:string;

    // createdDate:number;
    // dueDate?:number; //for project work a due date is allowed and encouraged. 
    // priority:number;
    // createdBy:User;
    // salesforceId?:String; //matches up to a ticket in salesforce if a matching ticket exists

    // projectUrl?: String;
    // isQuote?:boolean = false; //if the ticket is for a quote
    // quotedWorkTicket?:boolean; //if this ticket stems from a quoted ticket
    // quoteTicketId?:String; //if a work ticket what is the quote ticket id
    // quoteDollar?:number; //if quote ticket enter a $ amount of the quote.
    // quoteUnits?:number; //If quote ticket enter the number of responsive $100 units (calcualtes auto if quoteDollar is filled in)
    // quoteHours?:number; //number of quoted hours this work will take
}