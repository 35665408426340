import { CommonModule } from '@angular/common';
import { Component, Input, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
import { CoreApiAccountService } from '../../../services/core/core-api.service';
import { User } from '../../../KanbanService';

@Component({
    selector: 'findUser-component',
    templateUrl: './base.findUser.component.html',
    styleUrls: ['./base.findUser.scss']
})
export class FindUserComponent{
    @Output() addUser = new EventEmitter();
    public confirm = false;
    public standard = true;
    public userOptions = [];
    public customOptions;
    public selectedUser = null;
    @ViewChild('stakeholders') stakeholders;
    constructor(private toasty:Toasty, private coreApiService:CoreApiAccountService){}

    sortUsers($event, inputField){
        if($event.keyCode == 40){
            return;
        }
        this.coreApiService.listUserByQuery(inputField.value).subscribe(
            users=>{
                
                this.userOptions = users.filter( user=>{
                    return user.type.id === 1;
                });
                console.log(this.userOptions);
            }
        )
    }
    selectThisUser($event, stakeholders){
        this.selectedUser = $event.option.value;
        this.getUserProfile().subscribe(
            userProfile=>{
                var user = new User(userProfile);
                this.addUser.next(user);
                this.stakeholders.nativeElement.value = null;
                this.userOptions = null;
            }
        )
    }
    displayUser(user?){
        return user? user.name : "";
    }
    getUserProfile(){
        return this.coreApiService.getUserProfileByEmail(this.selectedUser.username);
    }
}