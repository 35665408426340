import { Asset } from 'app/KanbanService';
import { Task } from "../../responseModels/Task";
import { WorkType } from "../../enums/WorkType";

export abstract class _WorkCategoryCreateModel {
  name: string;
  description: string;
  min?: number;
  requirements?: Array<string>;
  tasks?: Array<Task>;
  workType: WorkType;
  files?: Array<Asset>;
}