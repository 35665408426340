import { TechDeliverableService, CompletePhaseRequest } from '@brafton/deliverables-client';
import { ConfirmationDialogComponent } from './../shared/confirmation/confirmation.component';
import { Toasty } from './../shared/toastNotifications/toasty.service';
import { TicketCreateModel } from './../KanbanService/models/requestCreateModels/TicketCreateModel';
import { FindUserDialogComponent } from './../shared/findUser/findUser.component';
import { AuthenticatedUser, PermissionSet } from './../shared/authenticatedUser.service';
import { NewTaskDialog } from './newTask/newTask.componet';
import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Task } from 'app/KanbanService/models/responseModels/Task';
import { TicketService, Ticket, Department, TicketStatus, TicketPriority } from 'app/KanbanService';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
@Component({
    selector: 'ticket-tasks',
    templateUrl: './task.component.html',
    styleUrls: ['./task.scss']
})
export class TaskListComponent implements OnChanges{
    private _tasks:Array<Task> = [];
    @Output() spawnNewTicket:EventEmitter<Ticket> = new EventEmitter();
    @Output() tasksModified:EventEmitter<Array<Task>> = new EventEmitter();
    @Output() changeUnitAllocation:EventEmitter<any> = new EventEmitter();
    @Output() partialComplete:EventEmitter<any> = new EventEmitter();
    @Input('unitsAvailable') unitsAvailable = 0;
    @Input('detectChange') detectChange = 0;
    @Input('tasks') 
    set Tasks(Tasks:Array<Task>){
        this._tasks = Tasks;
    }
    get Tasks():Array<Task>{
        return this._tasks;
    }
    @Input('ticketId') ticketId;
    private Ticket:Ticket = null;
    public taskList: BehaviorSubject < any[] >  = new BehaviorSubject<any[]>([]);
    public dataSource: TaskSource;
    public columnDef = [ 'completed','title', 'allocatedUnits', 'assigned', 'actions'];
    public isAdmin = false;
    constructor(private ticketService:TicketService, private dialog:MatDialog, private authenticatedUser:AuthenticatedUser, private toasty:Toasty,private techDeliverableService:TechDeliverableService){}
    ngOnInit(){
        this.Tasks = this.Tasks.sort((a,b)=>{
            if(a.order < b.order){
                return -1;
            }
            if(a.order > b.order){
                return 1;
            }
            return 0;
        })
        this.dataSource  = new TaskSource(this.taskList);

        this.taskList.next(this.Tasks);
        if(this.ticketId){
            this.ticketService.getTicketById(this.ticketId).pipe(take(1)).subscribe(
                ticket=>{
                    console.log(ticket);
                    this.Ticket = ticket[0];
                }
            )
        }
        this.isAdmin = this.authenticatedUser.checkPermission([PermissionSet.isAdmin]);
    }
    ngOnChanges(changes:SimpleChanges){
        console.log(changes);
        this.taskList.next(this.Tasks);
    }
    createTask($event){
        let allowedUnits = this.Tasks.map(t=>t.allocatedUnits).reduce((a,b)=>a+b,0);
        //@todo need to check if this person has permission (is the dev || admin || isAccountOwner);
        this.dialog.open(NewTaskDialog,{
            data:{
                unitsAvailable: this.unitsAvailable - allowedUnits,
                pageTitle: "Create New Task"
            }
        }).afterClosed().subscribe(
            task=>{
                console.log(task);
                if(task){
                    let newTask:_TaskCreateModel = task;
                    this.ticketService.createNewTask(this.ticketId, newTask).subscribe(
                        task=>{
                            this.Tasks.push(task);
                            this.bubbleUpTasks();
                        }
                    )
                }
            }
        )
    }
    editTask(task:Task){
        if(task.completed){
            return;
        }
        this.dialog.open(NewTaskDialog,{
            data:{
                unitsAvailable: this.unitsAvailable - this.Tasks.map(t=>t.allocatedUnits).reduce((a,b)=>a+b,0),
                title: task.title,
                units: task.allocatedUnits,
                assigned: task.assignedUser,
                pageTitle: "Update Task"
            }
        }).afterClosed().subscribe(
            updatedTask=>{
                console.log(updatedTask);
                if(updatedTask){
                    this.ticketService.updateTask(this.ticketId, task.id,updatedTask).subscribe(
                        updatedTaskList=>{
                            this.Tasks = updatedTaskList;
                            this.bubbleUpTasks();
                            
                        },er=>{
                            console.log("here be errors", er);
                        }
                    )
                }
            }
        )
    }
    spawnTicket(task:Task){
        if(task.completed){
            return;
        }
        //@todo need to limit ticket creation to the assigned developer or admin
        this.ticketService.getTicketById(this.ticketId).subscribe(
            (ticketDataArray:Array<Ticket>)=>{
                let ticketData:Ticket = ticketDataArray[0];
                if(ticketData.developer.id == this.authenticatedUser.currentUser.id || this.authenticatedUser.checkPermission([])){
                    let newTicket:TicketCreateModel = {
                        accountManager: ticketData.accountManager,
                        developer: task.assignedUser || null,
                        department: Department.Tech,
                        stakeholders: ticketData.stakeholders,
                        paymentType: 'free', //@todo need to adjust this properly and remove the units if assigned from old ticket and add to new ticket.
                        unitsAllocated: task.allocatedUnits || 0,
                        status: TicketStatus.open,
                        isClosed: false,
                        account: ticketData.account,
                        workType: ticketData.workType,
                        workCategory: ticketData.workCategory,
                        subject: task.title + " for "+ticketData.subject,
                        scope: ticketData.scope,
                        priority: TicketPriority.A,
                        createdBy: this.authenticatedUser.currentUser,
                        isQuote: false,
                        quotedWorkTicket: false,
                        createdDate: new Date().getTime(),
                        parentTicketId: this.ticketId
                    };
                    if(ticketData && ticketData.atlasId){
                        newTicket.atlasId = ticketData.atlasId;
                        newTicket.atlas = ticketData.atlas || null;
                    }
                    this.ticketService.createTicket(newTicket).pipe(take(1)).subscribe(
                        createdTicket=>{
                            task.ticketId = createdTicket.id;
                            if(task.allocatedUnits && task.allocatedUnits > 0){
                                task.allocatedUnits = 0;
                                this.changeUnitAllocation.next(-createdTicket.unitsAllocated);
                                
                            }
                            if(task.atlasPhaseId){
                                let newTicketTask = Object.assign({}, task);
                                delete newTicketTask.id;
                                delete newTicketTask.ticketId;
                                task.atlasPhaseId = null;
                                this.ticketService.createNewTask(createdTicket.id, newTicketTask).pipe(take(1)).subscribe(
                                    newTicketTasks => {
                                        console.log(newTicketTasks)
                                    },
                                    er=>{
                                        console.log(er);
                                    }
                                );
                                
                            }
                            this.ticketService.updateTask(this.ticketId, task.id, task).pipe(take(1)).subscribe(
                                updatedTasks=>{
                                    console.log(updatedTasks);
                                    this.Tasks = updatedTasks;
                                    this.bubbleUpTasks();

                                    
                                }
                            )
                        }
                    )
                }else{
                    console.log("insufficent permissions");
                    //@todo permission alert
                }
               
            }
        )
    }

    deleteTask(task:Task){
        if(!this.authenticatedUser.checkPermission([PermissionSet.isAdmin]) && task.atlasPhaseId){
            this.toasty.warning("You cannot delete this phase since it is directly linked to Atlas. If you want to reassign this task simply spawn a new ticket for this item.", "Unable to Delete");
            return;
        }
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Delete Task/Phase",
                message: `Are you sure you want to delete this phase?`
            }
        }).afterClosed().pipe(take(1)).subscribe(
            response=>{
                if(response){
                    this.ticketService.deleteTask(this.ticketId, task.id).pipe(take(1)).subscribe(
                        taskList=>{
                            console.log(taskList);
                            this.Tasks = taskList;
                            this.bubbleUpTasks();
                        }
                    )
                }
            }
        )
    }

    toggleCompleted($event,taskId){
        //mark the task completed and bubble up the event.
        let task = this.Tasks.find(_task=>_task.id === taskId);
        if(task.ticketId){
            this.toasty.info("This task has been turned into a child ticket. You can't close a task with ticket. You must close the ticket");
            $event.source.checked = !$event.checked;
            return;
        }
        if(task.completed && task.atlasPhaseId){
            this.toasty.info("Once a task linked to Atlas has been completed it can NOT be undone. Please visit Atlas or speak with the PM to determine if a new phase in this project needs to be created and/or added to this ticket.", "Can not undo completed status");
            $event.source.checked = true;
            return;
        }
        task.completed = !task.completed;
        if(task.atlasPhaseId){
           if(task.completed){
               this.markPhaseCompleted(task);
           }
        }else{
            this.ticketService.updateTask(this.ticketId, taskId, task).pipe(take(1)).subscribe(
                updatedStuff=>{},
                er=>{
                    console.log(er);
                }
            )
        }
        
        this.bubbleUpTasks();
    }
    checkUnits($event){ //checks live if there are enough units available if not top incrimenting. 
        let totalUsed = this.Tasks.map(task=>{
            return task.allocatedUnits
        }).reduce( (t, i)=>t+i);
        console.log(totalUsed);
    }

    bubbleUpTasks(){
        this.taskList.next(this.Tasks);
        this.tasksModified.emit(this.Tasks);
    }
    partialyComplete(phase:Task){
        this.partialComplete.next(phase);
    }
    markPhaseStarted(phase:Task){
        this.techDeliverableService.StartPhaseAsync(phase.atlasPhaseId, 
            moment().utc().format()).pipe(take(1)).subscribe(
                deliverableResponse=>{
                    console.log(deliverableResponse);
                    phase.started = true;
                    this.ticketService.updateTask(this.ticketId, phase.id, phase).pipe(take(1)).subscribe(
                        (updatedTask:Array<Task>)=>{
                            
                            this.Tasks = updatedTask;
                            this.bubbleUpTasks();
                            this.toasty.success(`${phase.title} has been started successfully in Atlas`, 'Phase Started');
                        }
                    )
                },
                er=>{
                    console.log(er);
                    this.toasty.error(er.details.details.message, "Can not Start this phase");
                }
            )
        
    }
    markPhaseCompleted(phase:Task){
        var completePhases:CompletePhaseRequest = {
            deliverableId: phase.atlasPhaseId,
            completedDate: moment().utc().format()
        };
        this.techDeliverableService.CompletePhaseAsync(completePhases).pipe(take(1)).subscribe(
            deliverableResponse=>{
                console.log(deliverableResponse);
                phase.started = false;
                phase.completed = true;
                this.ticketService.updateTask(this.ticketId, phase.id, phase).pipe(take(1)).subscribe(
                    (updatedTask:Array<Task>)=>{
                        this.Tasks = updatedTask;
                        this.bubbleUpTasks();
                    }
                )
            },
            er=>{
                console.log(er);
                this.toasty.error(er.details.details.Message + "<br/>Visit Atlas for further details.", "Error Completing phase");
                phase.completed = false;
            }
        )
    }
}


export class TaskSource extends DataSource < any > {
    public sort;
    constructor(private _data: Observable < any > ) {
      super();
  
    }
    connect(): Observable < any > {
  
      return this._data;
    }
  
    disconnect() {}
  
    getSortedData(): Subscription[] {
      return null;
    }
  }