import { UserUpdateModel } from './../models/requestUpdateModels/UserUpdateModel';
import { UserCreateModel } from './../models/requestCreateModels/UserCreateModel';
import { UserRequestModel } from './../models/requestQueryModels/UserRequestModel';
import { User } from './../models/responseModels/User';
import { DataService } from './../shared/data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable()
export class UserService extends DataService {
    // constructor(_http:Http) {
    //     super(_http);
    // }
    listUsers(conditions?:UserRequestModel){
        let url = `users` + this.toQueryString(conditions);
        return this.makeGetRequest(url)
            .pipe(map( (users)=>{
                return users.map(user=>{
                    return new User(user);
                })
            }))
    }
    createUser(user:UserCreateModel){
        let url = `users`;
        return this.makePostRequest(url, user)
            .pipe(map( (users)=>{
                return new User(users[0])
            }))
    }

    getUserById(user_id){
        let url = `users/${user_id}`;
        return this.makeGetRequest(url)
            .pipe(map( (users)=>{
                return new User(users[0])
            }))
    }
    getUserByEmail(email){ //@todo impliment server side
        let url = `users?email=` + email;
        return this.makeGetRequest(url)
            .pipe(map( (users)=>{
                return users.map(user=>{
                    return new User(user);
                })
            }))
    }

    updateUser(user_id, user:UserUpdateModel){
        let url = `users/${user_id}`;
        return this.makePutRequest(url, user)
            .pipe(map( (users)=>{
                return new User(users[0])
            }))
    }

    deleteUser(user_id){
        let url = `users/${user_id}`;
        return this.makeDeleteRequest(url);
    }
    setUserProfile(user_id, profile:Object){
        let url = `users/${user_id}/setUserProfile`;
        return this.makePostRequest(url, profile)
            .pipe(map( (users)=>{
                return new User(users[0]);
            }))
    }
    getUserProfile(user_id){ //@todo impliment server side

    }
    addRecentViewedProject(user_id, project_id){
        let url = `users/${user_id}/recent_project/${project_id}`;
        return this.makePutRequest(url, {})
            .pipe(map( (users)=>{
                return new User(users[0]);
            }))
    }
    addWatchEntity(user_id, watch_entity_id){
        let url = `users/${user_id}/add_watch_entity`;
        return this.makePutRequest(url,{entity_id:watch_entity_id})
            .pipe(map( (users)=>{
                return new User(users[0]);
            }))
    }
}
