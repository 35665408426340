import { Toasty } from './toastNotifications/toasty.service';
import { Injectable } from '@angular/core';

import { TicketService, UserService, TicketStatus, Ticket, TicketFilterCriteria } from '../KanbanService';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { AuthenticatedUser, PermissionSet } from './authenticatedUser.service';
import { Subject, Observable, Subscription, of, from } from 'rxjs';
import { TicketSorting } from './ticketSorting';
import { EventService } from './event.service';

@Injectable()
export class TicketListService{

    private _ticketList = [];
    private _newSingleTicket = new Subject<any>();
    private _ticketsLoaded = new Subject<any>();
    private _currentSortCriteria = {
        status:null,
        stakeholderId:null,
        accountId:null
    };
    private subs:Array<Subscription> = []; 
    public get unassignedTickets(){
        return this._ticketList.filter(t=>t.status===0).length;
    }
    public get myTickets(){
        return this._ticketList.filter(t=>{
            try{
                return t.stakeholders.findIndex(u=>u.email==this.authenticatedUser.currentUser.email) > -1;
            }catch(e){
                console.log(e);
                console.log(t, t.stakeholders);
                // return t.stakeholders.findIndex(u=>u.email==this.authenticatedUser.currentUser.email) > -1;
            }
        }).length;
    }
    public get teamTickets(){

        return this._ticketList.filter(t=>{
            var team = false;
            if(t.stakeholders == undefined || t.stakeholders == null){
                console.log(t);
                return team;
            }
            t.stakeholders.forEach(user => {
                try{
                    if(user.team.id == this.authenticatedUser.currentUser.teamId){
                        team = true;
                    }
                }catch(e){

                }
            });
            if(!team){
                // console.log("Not on team", t);
            }
            return team;
        }).length;
    }
    public get allTickets(){
        return this._ticketList.length;
    }
    constructor(
        private ticketService:TicketService, 
        private userService:UserService,
        private authenticatedUser:AuthenticatedUser,
        private eventService:EventService,
        private toast:Toasty
    ){
        if(this.authenticatedUser.currentUser.id){
            this._retrieveTickets();
            this.listenForTickets();
        }else{
            this.authenticatedUser.userAsync.subscribe(
                user=>{
                    // console.log("there be users",user);
                    this._retrieveTickets();
                    this.listenForTickets();
                }
            )       
        }
        
    }
    ngOnInit(){
        
    }
    private _retrieveTickets(criteria = null){
        var sortCrit:TicketFilterCriteria = {
            isClosed: false
        };
        this.ticketService.listTickets(sortCrit).subscribe(this.loadTickets.bind(this));

    }
    private loadTickets(tickets){
        this._ticketList = tickets;
        this._ticketsLoaded.next(this.sortTickets());
    }
    /**
     * returns observable with tickets meeting criteria
     * @param staus 
     * @param stakeholderId 
     * @param accountId 
     */
    public listTicketsAsync(status:Array<TicketStatus> = null, stakeholderId = null, accountId = null):Observable<Array<Ticket>>{
        //set the current sorting criteria
        this._currentSortCriteria.status = status;
        this._currentSortCriteria.stakeholderId = stakeholderId;
        this._currentSortCriteria.accountId = accountId;
        
        if(this._ticketList.length > 0){
            //get the sorting
            return from([this.sortTickets()]);
        }
        return this._ticketsLoaded.asObservable();
    }
    public listTickets(status:Array<TicketStatus> = null, stakeholderId = null, accountId = null):Array<Ticket>{
        this._currentSortCriteria.status = status;
        this._currentSortCriteria.stakeholderId = stakeholderId;
        this._currentSortCriteria.accountId = accountId;

        return this.sortTickets();
    }
    private sortTickets(ticket?){
        
        var sortedTickets = this._ticketList;
        if(ticket){
            sortedTickets = [ticket];
        }
        
        if(this._currentSortCriteria.status){
            sortedTickets = this.sortTicketsByStatus(sortedTickets,  this._currentSortCriteria.status);
        }
        //accomodate for array of these things
        if(this._currentSortCriteria.stakeholderId){
            sortedTickets = this.sortTicketsByStakeholder(sortedTickets, this._currentSortCriteria.stakeholderId)
        }
        if(this._currentSortCriteria.accountId){
            //need to figure out implimentation of this ???
        }

        return sortedTickets;
    }
    public sortTicketsByStatus(tickets:Array<Ticket>, status:Array<TicketStatus>){
        return TicketSorting.filterByField(tickets, 'status',  status);
    }
    public sortTicketsByStakeholder(tickets:Array<Ticket>, stakeholderId){
        return TicketSorting.filterByFieldArray(tickets, 'stakeholders', 'id',stakeholderId);
    }
    public sortTicketsByAccount(tickets:Array<Ticket>, accountId){
        return TicketSorting.filterByFieldArray(tickets, 'account', 'id', accountId);
    }
    /**
     * listens on socket for new tickets added by other users
     */
    private listenForTickets(){
        // this._ws = new WebSocket('');

        //only push the ticket if it meets the sort criteria
        //this._newSingleTicket.next();
        this.subs.push(this.eventService.newTicketsAsync().subscribe(
            newTicket=>{
                // Check if the ticket is in the list already
                if(newTicket){ // ensure ticket data was sent over
                    if(!this._ticketList.find(t=>t.id==newTicket.id)){ //check if the ticket already exists in the list.
                        // check if the user is a stakeholder in the current ticket or they are a ticket admin  
                        if(this.authenticatedUser.checkPermission([PermissionSet.readAllTickets, PermissionSet.readTeamTickets, PermissionSet.isStakeholder], newTicket.stakeholders)){
                            this.addNewTicket(newTicket);
                            this.toast.info("A new ticket has been opened and added to your board.", "New Ticket");
                        }
                    }   
                }     
            }
        ),
        this.eventService.newDeveloperAsync().subscribe(
            notificationEvent=>{
                let newTicket = new Ticket( notificationEvent.data.ticket);
                //if ticket is not currently in the list check if the current user is the new developer
                if(!this._ticketList.find(t=>t.id==newTicket.id) && newTicket.developer.email == this.authenticatedUser.currentUser.email){ 
                    this.addNewTicket(newTicket);
                    this.toast.info("You have been assigned a new Ticket for work.", "New Ticket Assigned");
                }else if(this._ticketList.find(t=>t.id==newTicket.id) && (newTicket.developer.email != this.authenticatedUser.currentUser.email)){ //ticket is in the list already but current user is NOT the developer
                    this.updateTicket(newTicket);
                }
            }
        ),
        this.eventService.newStakeholderAsync().subscribe(
            notificationEvent=>{
                let newTicket = new Ticket( notificationEvent.data.ticket);
                // if ticket is not currently in the list check if the current user is a new stakeholder
                if(!this._ticketList.find(t=>t.id==newTicket.id) && this.authenticatedUser.checkPermission([PermissionSet.isStakeholder], newTicket.stakeholders)){ 
                    this.addNewTicket(newTicket);
                    this.toast.info("You have been added to a Ticket as a stakeholder.", "Welcome to a new Project");
                }else if(this._ticketList.find(t=>t.id==newTicket.id)){ //if the ticket is in the list just update the stakeholders.
                    this.updateTicket(newTicket);
                }
            }
        ))
    }
    /**
     * Adds a new ticket to the array and the observable listening for new ticket in the app
     * @param ticket 
     */
    public addNewTicket(ticket){
        this._ticketList.push(ticket);

        //pass the new ticket through the current sort crit to see if the new ticket needs to be passed to the observable
        var qualifiedTickets = this.sortTickets(ticket)
        if(qualifiedTickets.length === 1){
            this._newSingleTicket.next(qualifiedTickets[0]);
        }
    }
    /**
     * 
     */
    public subscribeToTickets():Observable<any>{
        return this._newSingleTicket.asObservable();
    }
    public updateTicket(ticket:Ticket, remove:boolean = false){
        //find the ticket in the array of tickets and replace
        let index = this._ticketList.findIndex(tix=>tix.id ==ticket.id);

        if(remove){
            this._ticketList.splice(index, 1);
        }else{
            this._ticketList[index] = ticket;
        }
    
        // this._newSingleTicket.next(ticket);
        //OR maybe i should just listen for ticke tupdates from the server via the listenForTickets socket and update from there?????
    }
}