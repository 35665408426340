import { DataService } from './../shared/data.service';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';


@Injectable()
export class NotificationService extends DataService {
    emailNotification(project_id:string, event:NotificationEvents, entity):Observable<any>{
        let url = `notification/addEmail/`;
        let data = {
            projectId: project_id,
            event: event,
            entity: entity
        }
        return this.makePostRequest(url, data);
    }
    eventNotification(project_id:string, event:NotificationEvents, entity):Observable<any>{
        let url = `notification/addEvent/`;
        let data = {
            projectId: project_id,
            event: event,
            entity: entity
        }
        return this.makePostRequest(url, data);
    }
}

export class NotificationEvents{

    public static $newTicket = 'newTicket';
    public static $assignedTicket = 'assignedTicket';
    public static $newTicketComment = 'newTicketComment';
    public static $ticketMoved = 'ticketMoved';
    public static $newStakeholder = 'newStakeholder';
    public static $sendNotificationEmail = 'sendNotificationEmail';
}