import { MultiselectDialogComponent } from './../../shared/multiselect/multiselect.component';
import { CoreApiAccountService } from './../../services/core/core-api.service';
import { takeUntil, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from './../../shared/confirmation/confirmation.component';
import { MatDialog, MatTableDataSource, DialogPosition } from '@angular/material';
import { Component } from '@angular/core';
import { Ticket, TicketFilterCriteria, User, TicketService, Department, TicketStatus } from '../../KanbanService';
import { DateRangeDialogComponent } from './popups/dateRange/date.range.component';
import * as moment from 'moment';
import { CoreBusinessUnit } from 'app/KanbanService/models/enums/BusinessUnit';
import { GroupDataTables } from './groupDataTables';
import { SingleTicketView } from 'app/singleTicket/singleTicket.component';

@Component({
    selector: "reports-component",
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.scss']
})
export class ReportsComponent extends GroupDataTables{
    public filterByOptions = [
        {
            title: 'Date Range', //createdDate, ClosedDate, active Range (looks up move history)
            action: this.dateRangePopup.bind(this)
        },
        {
            title: 'Developer',
            action: this.selectDeveloper.bind(this)
        },
        {
            title: 'Account',
            action: this.selectAccount.bind(this)
        },
        {
            title: 'Work Type',
            action: this.selectWorkType.bind(this)
        },
        {
            title: 'Quotes',
            action: this.isQuote.bind(this)
        },
        {
            title: 'Tags',
            action: this.selectTags.bind(this)
        },
        {
            title: 'Business Unit',
            action: this.selectBusinessUnit.bind(this)
        }
    ];
    public filters = [];
    public display = [];
    public group = [];
    public groupings = {
        filters: 'filters',
        displayFields: 'display',
        group: 'group'
    }
    ticketResults: Ticket[];
    displayOptions: any[] = [
        {field: 'id',
         name: 'Action',
         selected: true,
         display: ({obj,value})=>{
            // console.log(value);
            //  return `<span class="material-icons" data-id="${value.id}" (click)="showTicket(value)">visibility</span>`;
            return;
         }
        },
        {field: 'account.name',
         name: 'Account',
         selected: true,
         display: ({obj,value})=> value
        },
        {field: 'account.businessUnit.name',
         name: 'Business Unit',
         selected: true,
         display: ({obj,value})=> value
        },
        {field: 'developer.name',
         name: 'Developer',
         selected: true,
         display: ({obj,value})=> value
        },
        {field: 'department',
         name: 'Department',
         selected: true,
         display: ({obj,value})=>{
             return Department[value];
         }
        },
        {field: 'pricePaid',
         name: '$ Price',
         selected: true,
         display: ({obj,value})=>value
        },
        {field: 'unitsAllocated',
         name: 'Units Allocated',
         selected: true,
         display: ({obj,value})=>value
        },
        {field: 'unitsAdjusted',
         name: 'Adjusted Units',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'status',
         name: 'Status',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'workType',
         name: 'Work Type',
         selected: true,
         display: ({obj,value})=>value
        },
        {field: 'workCategory',
         name: 'Work Category',
         selected: true,
         display: ({obj,value})=>value
        },
        {field: 'subject',
         name: 'Subject',
         selected: true,
         display: ({obj,value})=>value
        },
        {field: 'daedelusId',
         name: 'Daedelus',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'atlasId',
         name: 'Atlas',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'scope',
         name: 'Scope',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'daysOpen',
         name: 'Days Open',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'time.pending',
         name: 'Time in pending',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'time.progress',
         name: 'Time in Progress',
         selected: true,
        display: ({ obj, value }) => {
            // console.log(obj, this.filters);
            var filterindex;
            if ((filterindex = this.filters.findIndex(f => f.name === 'In Progress Date')) > -1) {
                let totalTimeInHours = 0;
                for (const move of obj.moveHistory) {
                    console.log(move.from.state === TicketStatus.progress,move.to.timestamp >= this.filters[filterindex].requestObject.activityStart, move.to.timestamp <= this.filters[filterindex].requestObject.activityEnd);
                    if (move.from.state === TicketStatus.progress && (move.to.timestamp >= this.filters[filterindex].requestObject.activityStart && move.to.timestamp <= this.filters[filterindex].requestObject.activityEnd)) {
                       console.log("apply time");
                        totalTimeInHours += move.hours;
                    }
                }
                return Number(totalTimeInHours).toFixed(2);
            }
            return value;
        }
        },
        {field: 'time.woam',
         name: 'Time in WOAM',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'leadTime',
         name: 'Lead Time',
         selected: false,
         display: ({obj,value})=>value
        },
        {field: 'createdDate',
         name: 'Created Date',
         selected: true,
         display: ({obj,value})=>{
             if(!value)return '';
             value = value / 1000;
             return moment.unix(value).format('MMM D,YYYY');
         }
        },
        {field: 'closedDate',
         name: 'Closed Date',
         selected: true,
         display: ({obj,value})=>{
            if(!value)return '';
            value = value / 1000;
            return moment.unix(value).format('MMM D,YYYY');
         }
        },
        {field: 'tags',
         name: 'Tag List',
         selected: false,
         display: ({obj,value})=>value
        }
        
    ];

    constructor(private dialog:MatDialog, private coreApiService:CoreApiAccountService, private ticketService:TicketService){
        super();
        this.columns = [...this.displayOptions.filter(op=>op.selected)];
        this.displayedColumns = this.columns.map(column=>column.field);
        this.display = this.columns;

    }
    showTicket(ticket){ //@todo if appendPhase than i need to show open tasks when i open the ticket and remov ethe phase when im done.
        console.log(ticket);
        var previousTicketStatus = ticket.statusName();
        // this.ticketOpen = true;
        let pos:DialogPosition = {
            right: "0",
            // bottom: "0",
            // top: "69px"
        }
        let openData = {
            ticket: ticket
        }
        const dial = this.dialog.open(SingleTicketView, {
            position: pos,
            data: openData,
            width:"60%",
            // height: "91%",
            panelClass: "ticket-details"
        });
    
    }
    createPopup(option){

    }
    createTab(area, name, displayValue, requestObject){
        this[area].push({
            name,
            displayValue,
            requestObject
        });
        console.log(this[area]);
    }
    removeTab(area, index){
        console.log(area, index);
        this[area].splice(index, 1);
        if(area == this.groupings.displayFields){
            //re render table
            this.columns = this.display;
            this.displayedColumns = this.display.map(col=>col.field);
        }
    }
    dateRangePopup(f){
        console.log(f);
        this.dialog.open(DateRangeDialogComponent,{data:{}}).afterClosed().subscribe(
            response=>{
                if(response){
                    console.log(response);
                    var start = moment(response.range.Start).unix();
                    var end = moment(response.range.End).unix();
                    var responseObject = {}
                    responseObject[response.field.field+'Start'] = start * 1000;
                    responseObject[response.field.field+'End'] = end * 1000;
                    this.createTab(this.groupings.filters, response.field.title, moment.unix(start).format('MMM D,YYYY')+' - '+moment.unix(end).format('MMM D,YYYY'),responseObject);
                }
            }
        )
    }
    selectBusinessUnit(){
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Select Buisness Unit",
                customOptions: [
                    {
                        label: CoreBusinessUnit[CoreBusinessUnit.Brafton], 
                        value: CoreBusinessUnit.Brafton}, 
                    {
                        label: CoreBusinessUnit[CoreBusinessUnit.Castleford], 
                        value: CoreBusinessUnit.Castleford}]
            }
        }).afterClosed().subscribe(
            response=>{
                if(response){
                    
                    this.createTab(this.groupings.filters, 'Business Unit', CoreBusinessUnit[response], { businessUnit: response})
                }
            }
        )
    }
    selectTags(){

    }
    changeDisplayFields(){
        this.dialog.open(MultiselectDialogComponent, {
            width: '40%',
            data:{
            title: "Display Fields",
            options: this.displayOptions.map(option=>{

                if(this.display.findIndex(op=>op.field==option.field) > -1){
                    console.log(option.field);
                    option.selected = true;
                }else{
                    option.selected = false;
                }
                return option;
            })
        }}).afterClosed().subscribe( 
            newDisplayFields=>{
                if(newDisplayFields){
                    this.display = newDisplayFields.filter(op=>op.selected);
                    this.columns = this.display;
                    this.displayedColumns = this.display.map(col=>col.field);
                }
            }
        )
    }
    isQuote(){
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Select only Quote Tickets",
                customOptions: [
                    {
                        label: 'Yes', 
                        value: true
                    }, 
                    {
                        label: 'No',
                        value: false}]
            }
        }).afterClosed().subscribe(
            response=>{
                if(response){
                    this.createTab(this.groupings.filters, 'Quote Ticket', 'True', {isQuote: response});
                }
            }
        )
    }
    selectWorkType(){

    }
    selectAccount(){

    }
    selectDeveloper(){
        this.coreApiService.listTechnicalServicesProfiles().pipe(take(1)).subscribe(
            (devs:Array<User>)=>{
                 this.dialog.open(ConfirmationDialogComponent, {
                     data: {
                         title: "Choose a Developer",
                         customOptions: devs.map( user=>{
                             return {
                                 "label": user.name,
                                 "value": user.email
                             };
                         })
                     }
                 }).afterClosed().pipe(take(1)).subscribe(
                     res=>{
                         if(res){
                             var dev = devs.find(u=>u.email==res);
                             this.createTab(this.groupings.filters, 'Developer', dev.name, {developerEmail: dev.email});
                         }
                     }
                 )
             }
         );
    }
    updateDisplay($event, field){
        console.log($event, field)
    }
    getTickets(){
        var crit:TicketFilterCriteria = {};
        var filts = {};
        console.log(this.filters);
        this.filters.forEach(fil=>{
            filts = Object.assign(filts, fil.requestObject);
        });
        console.log(filts);
        this.ticketService.listTickets(filts).subscribe(
            tickets=>{
                console.log(tickets);
                this.ticketResults = tickets;
                this._alldata = this.ticketResults;
                this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
                this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
                this.dataSource.filter = performance.now().toString();
            }
        )
    }
}