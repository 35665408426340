import { _User } from "../interfaces/responseModels/_User";


export class User extends _User{
    constructor(user?){
        super();
        if(user){
            Object.assign(this, user);
            if(user.profileImageUrl){
                this.userIcon = user.profileImageUrl
            }
        }
    }
}