import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

@Component({
    selector: 'date-range-dialog',
    templateUrl: './date.range.component.html',
    styleUrls: ['./date.range.scss']
})
export class DateRangeDialogComponent{

    public rangeFields = [
        { title: 'Created Date', field: 'createdDate'},
        { title: 'Closed Date', field: 'closedDate'},
        { title: 'In Progress Date', field: 'activity'}
    ]
    public choosenField = null;
    public range = {
        Start: null,
        End: null
    }
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<DateRangeDialogComponent>, private toasty:Toasty){
        

    }
    updateField($event, field){
        
        this.choosenField = field;
    }
    updateDate(bound,$event){
        
        this.range[bound] = $event.value;
    }
    closeTrue(){
        this.dialogRef.close({
            field: this.choosenField,
            range: this.range
        });
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}