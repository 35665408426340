import { NotificationEvents } from './../KanbanService/services/notification.service';
import { AuthenticatedUser } from './authenticatedUser.service';
import { BfAuthService } from '@brafton/skynet-angular-security-service';

import { InjectionToken } from '@angular/core';
import { Injectable, Inject } from '@angular/core'
import { Observable, Subject } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { HttpHeaders, HttpRequest, HttpClient } from '@angular/common/http';

import { KANBAN_EVENT_CONFIG, KanbanEventApiConfig } from '../event.config';
import { Ticket } from 'app/KanbanService';
// import { NotificationEvents } from '../KanbanService';
@Injectable()
/**
 * Service for subscribing to events and receiving those events 
 * connects to the eventing services for this applciation
 */
export class EventService{
    public source:Observable<any>;
    public ws:WebSocket;
    public eventTrigger;
    public registeredEvents:Array<object>;
    /**
     * Observable for listening for new events
     */
    private obs:Observable<any>;
    private newTicketObs:Subject<any> = new Subject<any>();
    private newCommentObs:Subject<any> = new Subject<any>();
    private newTicketAssignmentObs:Subject<any> = new Subject<any>();
    private ticketStatusObs:Subject<any> = new Subject<any>();
    private newStakeholderObs:Subject<any> = new Subject<any>();
    constructor(private _http:HttpClient, @Inject(KANBAN_EVENT_CONFIG) private _config:KanbanEventApiConfig, private bfAuth:BfAuthService){
        this.eventTrigger = this.socket();
        this.obs = this.subscriber();
        this.obs.subscribe(e=>{});
    }
    listener(){
        return this.obs;
    }
    newTicketsAsync(){
        return this.newTicketObs.asObservable();
    }
    newTicketCommentAsync(){
        return this.newCommentObs.asObservable();
    }
    newDeveloperAsync(){
        return this.newTicketAssignmentObs.asObservable();
    }
    ticketStatusAsync(){
        return this.ticketStatusObs.asObservable();
    }
    newStakeholderAsync(){
        return this.newStakeholderObs.asObservable();
    }
    socket(){ //gotta try and use sockets to connect to build events
        let ws = new WebSocket(this._config.baseUrl);
        this.ws = ws;
         let observable = Observable.create(
            (obs:any) => {
                ws.onopen = ()=> {
                    // console.log("event connection open");
                };
                ws.onmessage = obs.next.bind(obs);
                ws.onerror = obs.error.bind(obs);
                ws.onclose = ()=>{
                    console.log("connection closed");
                    obs.complete("done");
                };
                return ws.close.bind(ws);
            }
        );
        let observer = {
            next: (data: Object) => {
                if (ws.readyState === WebSocket.OPEN) {
      
                }
            },
        };
        
        return Subject.create(observer, observable);
    }
    //Add event to to registeredEvents array
    registerForEvent(eventObject:{entity:string,event:string|Array<string>}){
        if(this.ws.readyState === 1){
            this.transmitEventTrigger(eventObject);
        }else{
            setTimeout(()=> {
                this.transmitEventTrigger(eventObject);
            }, 2000);
        }
        return this;
    }
    transmitEventTrigger(trigger){
        this.ws.send(JSON.stringify({
            action: 'registerForEvent',
            entity: trigger.entity,
            event: trigger.event
        }))
    }
    deRegisterForEvent(eventObject){
        this.ws.send(JSON.stringify({
            action: 'deRegisterForEvent',
            entity: eventObject.entity,
            event: eventObject.event
        }))
    }
    private subscriber(){
       return new Observable(observer => {
          this.eventTrigger.forEach( (s)=>{
              //if the event is registered
              console.log(s);
              let notification = JSON.parse(s.data);
            console.log(notification);
            // New ticket created 
            if(notification.event == NotificationEvents.$newTicket){
                if(notification.data.ticket){
                    this.newTicketObs.next(new Ticket(notification.data.ticket) );
                }
            }else if(notification.event == NotificationEvents.$newTicketComment){ // new comment on existing ticket
                this.newCommentObs.next(notification);
            }else if(notification.event == NotificationEvents.$assignedTicket){ // new developer assignement to a ticket
                this.newTicketAssignmentObs.next(notification);
            }else if(notification.event == NotificationEvents.$ticketMoved){ // ticket status has been updated
                this.ticketStatusObs.next(notification);
            }else if(notification.event == NotificationEvents.$newStakeholder){ // new stakeholder added to a ticket
                this.newStakeholderObs.next(notification);
            }
            // pass the notification event to the master observable accessable via this.obs
              observer.next(notification);
          })
        }).pipe(share());
    }

}