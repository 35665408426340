import { InjectionToken } from "@angular/core";

export var KANBAN_CONFIG = new InjectionToken<KanbanApiConfig>('kanbanApiConfig');

export abstract class KanbanApiConfig{
    baseUrl:string;
}

/**
 * Implimentation
 * 
 * let DaedelusApiConfig:DaedelusApiConfig = {
 *      baseUrl: "http://localdev.dev/api/v2/"
 * }
 * 
 * ngModel({
 * ...
 * providers: [
 *      { provide: DAEDELUS_CONFIG, useValue: DaedelusApiConfig }]
 * })
 */
