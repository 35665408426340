import { Component, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { EventEmitter } from 'events';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
import { TagsService } from 'app/KanbanService';
import { Tag } from 'app/KanbanService/models/responseModels/Tag';
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from 'app/shared/confirmation/confirmation.component';

@Component({
    selector: 'tags-form',
    templateUrl: './views/tags/ticketTags.form.html',
      styles: [`
      .remove-user{
        position: relative;
        font-size: 1em;
        left: .5em;
        top: -.25em;
        height: 1.5em;
        width: 1.5em;
        color: red;
        font-weight: 900;
        cursor:pointer;
    }
      `]
})
export class TagsFormComponent{
    // @Output('newTag') newTagEmitter = new EventEmitter();
    public existingTags:Tag[] = [];
    public fullTagList:Tag[] = [];
    public sortedTagList:Tag[] = [];
    @ViewChild('tags') tagElement;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<TagsFormComponent>, private toasty:Toasty, private tagsService:TagsService, private _dialogRef:MatDialog){

        this.existingTags = data.tags;

    }
    ngOnInit(){
        this.tagsService.listTags().subscribe(
            allTags=>{
                this.fullTagList = allTags;
            }
        )
    }
    close(){
        this.dialogRef.close(this.existingTags);
    }
    ngOnDestroy(){
        this.close();
    }
    removeTag(tag){
        let confOptions:ConfirmationDialogOptions = {
            message: "Are you Sure you want to delete this tag",
            title: "Delete Tag",
            subtitle: tag
        };
        this._dialogRef.open(ConfirmationDialogComponent,{
            data: confOptions
        }).afterClosed().subscribe(
            result=>{
                if(result){
                    let index = this.existingTags.findIndex(e=>e==tag);
                    this.existingTags.splice(index, 1);
                }
            }
        )
    }
    addTag($event, tagsEl){
        //check if tag is already in the list.
        tagsEl.value = null;
        let tag = $event.option.value;
        if(!this.existingTags.find(e=>e==tag)){
            this.existingTags.push(tag);

        }
    }
    findTags($event, el){
        if($event.keyCode == 40){
            return;
        }
        this.sortedTagList = this.fullTagList.filter((_tag)=>{
            return _tag.name.toLowerCase().includes(el.value);
        }).slice(0,20);
    }

}