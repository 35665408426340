import { MatTableDataSource, MatSort } from '@angular/material';
import { getBusinessDays, getAssignedUnits, hoursToUnits, unitsToHours } from './../shared/utils';
import { TicketRequestModel } from './../KanbanService/models/requestQueryModels/TicketRequestModel';
import { AuthenticatedUser, PermissionSet } from './../shared/authenticatedUser.service';
import { TicketService, FilterCriteria, TicketFilterCriteria } from './../KanbanService/services/ticket.service';
import { TicketListService } from './../shared/ticketListService';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as CanvasJS from '../shared/canvasjs.min';
import { StatusForDev, StatusForCMS, TICKETSTATUS, TicketStatus, Ticket } from 'app/KanbanService';
import * as moment from 'moment';
import { TechDeliverableService, ListPhasesRequest } from '@brafton/deliverables-client';
@Component({
    selector: 'dashboard-compnent',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.scss']
})
export class  DashboardComponent {
    public PermissionSet = PermissionSet;
    public TICKETSTATUS = TICKETSTATUS;
    public unitChart:CanvasJS;
    public rollingChart:CanvasJS;
    public leadTimeChart:CanvasJS;
    public activeLabel;
    public available = 157;
    public currentLeadTime = null;
    public statusCounts = {
        pending: 0,
        "tech hold": 0,
        woam: 0,
        approval: 0
    };
    startOfMonth;
    endOfMonth;
    today;
    displayedColumns = ["accountName", "subject","status", "progress", "actions"];
    public teamLeadTime = [];
    public loadingUnitsChart;
    public loadingClosedChart;
    public loadingLeadTimeChart;
    public dataSource:MatTableDataSource<any> = new MatTableDataSource<Element>([]);
    @ViewChild(MatSort) sort: MatSort;

    constructor(private ticketListingService:TicketListService, private ticketService:TicketService, public authenticatedUser:AuthenticatedUser, private techDeliverables:TechDeliverableService, private _changeDetect:ChangeDetectorRef){
        this.loadingUnitsChart = true;
        this.loadingClosedChart = true;
        this.loadingLeadTimeChart = true;

    }
    ngOnInit(){       
        
        if(this.authenticatedUser.currentUser.teamId == 10){
            this.activeLabel = StatusForDev;
        }else{
            this.activeLabel = StatusForCMS;
        }
        this.startOfMonth = moment().startOf('month').unix() * 1000;
        this.endOfMonth = moment().endOf('month').unix() * 1000;
        this.today = moment();
        var days = getBusinessDays(this.endOfMonth,this.today.unix()*1000);
        this.available = (days * (this.authenticatedUser.checkPermission([PermissionSet.isAdmin])? 3.5 : 7.5) ) * 2;
        this.loadData();
        //https://github.com/brafton/brafton-deliverables/blob/5b2ad267f807bbcad6092932e74058bbc09a4eee/src/Brafton.Deliverables.Client.Angular/src/enumeration-type/enumeration.types.ts#L663
        
        

    }
    ngAfterViewInit(){
        if(this.authenticatedUser.currentUser.teamId == 10 || this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
            this.loadCompletedVsExpectedData();
            this.loadMonthToDateData();
        }
        this.loadRecentTicketData();
        this.loadLeadTimeData();
    }
    ngAfterViewChecked(){
        this._changeDetect.detectChanges();
    }
    loadData(){
        var id = this.authenticatedUser.currentUser.id;
        // if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
        //     email = null;
        // }
        this.ticketListingService.listTicketsAsync().subscribe(
            tickets=>{
                
                tickets = this.ticketListingService.sortTicketsByStakeholder(tickets, id);
                
                tickets.forEach(tix=>{
                    // if(this.authenticatedUser.currentUser.teamId == 10){
                    //     if(tix.developer && tix.developer.id == this.authenticatedUser.currentUser.id){
                    //         ++this.statusCounts[TICKETSTATUS[tix.status]]
                    //     }
                    // }else{
                    //     ++this.statusCounts[TICKETSTATUS[tix.status]]
                    // }
                    ++this.statusCounts[TICKETSTATUS[tix.status]]                    
                });                
            }
        );        
    }
    loadCompletedVsExpectedData(){
        this.loadingClosedChart = true;
        let filter:TicketFilterCriteria = {};
        filter.closedDateStart = moment().subtract(11, 'months').startOf('month').unix() * 1000;
        filter.closedDateEnd = moment().endOf('month').unix() * 1000;
        // filter.isClosed = true;
        // filter.developerId = this.authenticatedUser.currentUser.id;
        var expectedUnits = new Array(12).fill(0);
        var actualUnits = new Array(12).fill(0);
        this.ticketService.getClosedStats(filter.closedDateStart, filter.closedDateEnd, ['unitsAllocated', 'unitsAdjusted', 'closedDate', 'time']).subscribe(
            tickets=>{
                
                /**
                 * Loop through the tickets 
                 * key for each array is the month
                 * for units check for adjusted first
                 * for actual calculate units from time.progress
                 */
                tickets.forEach(tix=>{
                    try{
                        let month = moment(tix.closedDate).month();
                        expectedUnits[month] += getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted);
                        actualUnits[month] += hoursToUnits(parseFloat((tix.time.progress).toFixed(2)));
                    }catch(e){
                        console.log(e, tix);
                    }
                });
                this.buildRollingChartPassThrough(expectedUnits, actualUnits);

            }
        )
    
    }
    loadMonthToDateData(){
        // this.loadingUnitsChart = true;
        //@todo need to determine developers % completed to expectaion and develop a kpi for this
    this.ticketListingService.listTicketsAsync().subscribe(
        tickets=>{
            tickets = this.ticketListingService.sortTicketsByStatus(tickets,[TicketStatus.pending, TicketStatus.woam]);
            // if(!this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
            //     tickets = this.ticketListingService.sortTicketsByStakeholder(tickets,this.authenticatedUser.currentUser.email);
            // }

            var actual = 0;
            var paused = 0;
            var unitsThroughMonth = [];
            tickets.filter(tix=>{
              
                // if(this.authenticatedUser.checkPermission([PermissionSet.isAdmin])){
                //     return tix;
                // }
                // if(this.authenticatedUser.currentUser.teamId == 10){
                //     return tix.developer && tix.developer.id == this.authenticatedUser.currentUser.id;
                // }
                return tix;
            }).forEach(tix=>{
                // console.log(tix.status);
                let index = unitsThroughMonth.findIndex(user=>user.id==tix.developer.id);
                if(index < 0){
                    unitsThroughMonth.push({
                        name: tix.developer.name,
                        id: tix.developer.id,
                        actual: 0,
                        paused: 0,
                        hoursPerDay: 0
                    });
                    index = unitsThroughMonth.length -1;
                }
                if(tix.developer['profileAvailabilityTerms'] && unitsThroughMonth[index].hoursPerDay < 1){
                    try{
                    let hours = tix.developer['profileAvailabilityTerms'][0].hoursPerDay;
                    unitsThroughMonth[index].hoursPerDay = hours;
                    }catch(e){}
                }
                if(tix.status === TicketStatus.pending){
                    let totalTicketUnits = getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted)
                    let used = tix.time.progress * 2;
                    let units = totalTicketUnits - used;
                    if(units > 0){
                        actual += units;
                        unitsThroughMonth[index].actual += units;
                    }
                }else{
                    // console.log(tix);
                    let totalTicketUnits = getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted)
                    let used = tix.time.progress * 2;
                    let units = totalTicketUnits - used;
                    if(units > 0){
                        paused += units;
                        unitsThroughMonth[index].paused += units;
                    }
                }
            })
            // console.log(paused);
            this.buildUnitChart(unitsThroughMonth);
        }
    )
    }
    buildUnitChart(unitsThroughMonth){ //units left to use this month vs units waiting in pending to be completed
        var days = getBusinessDays(this.endOfMonth,this.today.unix()*1000);
        // this.available = (days * (this.authenticatedUser.checkPermission([PermissionSet.isAdmin])? 3.5 : 7.5) ) * 2;
        var data = [];
        unitsThroughMonth.forEach(user=>{
            let available = (days * user.hoursPerDay ) * 2;
            data.push({
                name: user.name,
		        showInLegend: true,
                type: "column",
                yValueFormatString: "#,##0.0#\" units\"",
                dataPoints: [
                    { label: "Available through Month", y: available },	
                    { label: "Pending", y: user.actual },	
                    { label: "Remaining WOAM", y: user.paused }                    
                ]
            })
        })
        this.unitChart = new CanvasJS.Chart("chartContainer-units", {
            animationEnabled: true,
            backgroundColor: 'transparent',
            theme: "light2", // "light1", "light2", "dark1", "dark2"
            // title: {
            //     text: "Actual vs Allocated vs Adjusted Units"
            // },
            axisY: {
                title: "Units",
                suffix: " units",
                includeZero: false
            },
            axisX: {
                title: ""
            },
            data: data
            // [{
            //     name: "Mine",
		    //     showInLegend: true,
            //     type: "column",
            //     yValueFormatString: "#,##0.0#\" units\"",
            //     dataPoints: [
            //         { label: "Available through Month", y: this.available },	
            //         { label: "Pending", y: actual },	
            //         { label: "On Hold", y: paused }                    
            //     ]
            // }]
        });
        this.loadingUnitsChart = false;
        
        this.unitChart.render();
        
    }
    buildRollingChartPassThrough(expectedUnits, actualUnits){
        var createdDateStart = moment().subtract(11, 'months').unix() * 1000;
        var createdDateEnd = moment().endOf('month').unix() * 1000;
        var openedUnits = new Array(12).fill(0);
        this.ticketService.getOpenedStats(createdDateStart, createdDateEnd, ['createdDate', 'unitsAllocated', 'unitsAdjusted']).subscribe(
            tickets=>{
                tickets.forEach(tix=>{
                    try{
                        let month = moment(tix.createdDate).month();
                        openedUnits[month] += getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted);
                    }catch(e){
                        console.log(e, tix);
                    }
                });
                
                this.buildRollingChart(expectedUnits, actualUnits, openedUnits);
            });

    }
    buildRollingChart(allocatedUnits, usedUnits, openedUnits = []){
        var expected = [];
        var actual = [];
        var opened = [];
        var start = moment().subtract(11, 'months');
        // console.log(allocatedUnits, usedUnits);
        for(var i=0;i<12;i++){
            expected.push({
                x: new Date(start.year(), start.month()),
                y: (allocatedUnits[start.month()])
            })
            actual.push({
                x: new Date(start.year(), start.month()),
                y: (usedUnits[start.month()])
            })
            opened.push({
                x: new Date(start.year(), start.month()),
                y: (openedUnits[start.month()])
            })
            start.add(1, 'month');
        }
        // console.log(expected, actual);
        this.rollingChart = new CanvasJS.Chart("chartContainer-rolling", {
            animationEnabled: true,
            theme: "light2",
            backgroundColor: 'transparent',
            // title:{
            //     text: "Monthly Expenses, 2016-17"
            // },
            axisY :{
                includeZero: false,
                prefix: ""
            },
            toolTip: {
                shared: true
            },
            legend: {
                fontSize: 13
            },
            data: [
                { 
                    name: "Units Opened for Month",
                    type: "column",
                    transparent: true,
                    // yValueFormatString: "#0.## °C",
                    showInLegend: true,
                    xValueType: "MMM YYYY",
                    xValueFormatString: "MMM YYYY",
                    yValueFormatString: "#,##0 Units",
                    dataPoints: opened
                },
                {
                type: "splineArea",
                showInLegend: true,
                name: "Completed in Units",
                yValueFormatString: "#,##0 Units Vs",
                xValueFormatString: "MMM YYYY",
                dataPoints: actual
             },
            {
                type: "spline", 
                showInLegend: true,
                name: "Target Units To Complete",
                yValueFormatString: "#,##0 Units",
                dataPoints: expected
             }
            ]
        });
        this.loadingClosedChart = false;
        this.rollingChart.render();
    }
    loadRecentTicketData(){
        this.ticketListingService.listTicketsAsync().subscribe(
            tickets=>{
                let sorted = tickets.sort((a,b)=>{
                    if(a.lastModifiedDate > b.lastModifiedDate){
                        return -1;
                    }
                    if(a.lastModifiedDate < b.lastModifiedDate){
                        return 1;
                    }
                    return 0;
                }).filter(tix=>{
                    return !tix.isQuote && (this.authenticatedUser.checkPermission([PermissionSet.isAdmin]) || tix.stakeholders.find(s=>s.id === this.authenticatedUser.currentUser.id));
                }).slice(0,15).map(tix=>{
                    tix['accountName'] = tix.account? tix.account['name']: null;
                    if(tix.time && tix.time.progress && (tix.unitsAdjusted || tix.unitsAllocated)){
                        tix['progress'] = (((tix.time.progress * 2) / getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted)) * 100).toFixed(1);
                    }else if(tix.time && tix.time.progress){
                        tix['progress'] = 100;
                    }else{
                        tix['progress'] = 0;
                    }
                    return tix;

                });
                this.buildRecentTicketChart(sorted);
            }
        )
    }
    buildRecentTicketChart(tickets){
        this.dataSource = new MatTableDataSource<Element>(tickets);
        this.dataSource.sort = this.sort;

    }

    loadLeadTimeData(){
        // this.loadingLeadTimeChart = true;
        var averageLeadTime = new Array(13).fill(0);
        // var actual = new Array(12).fill(0);
        //Tickets opened past 12 months
        let filter:TicketFilterCriteria = {};
        filter.createdDateStart = moment().subtract(11, 'months').unix() * 1000;
        filter.createdDateEnd = moment().endOf('month').unix() * 1000;
        this.ticketService.getOpenedStats(filter.createdDateStart, filter.createdDateEnd, ['createdDate', 'developer', 'leadTime', 'time']).subscribe(
            tickets=>{
                
                var months = new Array(12).fill([]);
                var teamTotals = [];
                tickets.forEach(tix=>{
                    if(tix.createdDate){
                        let month = moment(tix.createdDate).month();
                        months[month] = [tix, ...months[month]];

                        if(tix.developer && (this.authenticatedUser.checkPermission([PermissionSet.isTechTeam]))){
                            let index = teamTotals.findIndex(user=>user.id==tix.developer.id);
                            if(index < 0){
                                teamTotals.push(tix.developer);
                                index = teamTotals.length -1;
                       
                                teamTotals[index].leadTimeByMonth = new Array(12).fill([]);
                            }
                            teamTotals[index].leadTimeByMonth[month] = [tix, ...teamTotals[index].leadTimeByMonth[month]];
                        }
                    }
                })
                
                var averages = months.map((month:[])=>{
                    var totTix = month.length;
                    var accum = month.reduce( (accum,tix:Ticket)=>{
                        return accum + tix.leadTime;
                    },0)
                    // console.log(accum, totTix);
                    return parseFloat((accum? ( (accum / totTix) * 1.25 / 7.5).toFixed(1) : "0"));
                })
                
                var individualAverages = [];
                
                    individualAverages = teamTotals.map(user=>{
                        if( this.authenticatedUser.checkPermission([PermissionSet.isTechTeam])){
                            user.leadTimeByMonth = user.leadTimeByMonth.map((month:[])=>{

                                var totTix = month.length;
                                var accum = month.reduce( (accum,tix:Ticket)=>{
                                    return accum + tix.leadTime;
                                },0)
                                // console.log(accum, totTix);
                                return parseFloat((accum? ( (accum / totTix) * 1.25 / 7.5).toFixed(1) : "0"));

                            })
                        }
                        return user;

                    })
                
                // console.log(individualAverages);
                // console.log(averages);
                this.buildLeadTimeChart(averages, individualAverages);
            }
        )
        //total pending units / # of ticket (ie 300 / 10) = 30 units (60 hours) / 2.5 = 24 hours = 3-5 days
        this.ticketListingService.listTicketsAsync().subscribe(
            tickets=>{
                tickets = this.ticketListingService.sortTicketsByStatus(tickets,[TicketStatus.pending]);
            //    console.log(tickets);
               let total = tickets.length;
               var actual = 0;
               tickets.forEach(tix=>{
                    let tmpUnits = getAssignedUnits(tix.unitsAllocated, tix.unitsAdjusted);
                    let used = hoursToUnits(tix.time.progress)
                    let units = tmpUnits - used;
                    if(units > 0){
                        actual += units;
                        let index = this.teamLeadTime.findIndex(user=>user.id==tix.developer.id);
                        if(index < 0){
                            this.teamLeadTime.push(tix.developer);
                            index = this.teamLeadTime.length -1;
                            this.teamLeadTime[index].leadTime = 0
                        }
                        this.teamLeadTime[index].leadTime += units;
                    }
                    

               })
               this.teamLeadTime.forEach(user=>{
                   var time = ((user.leadTime / 2) / 7.5 * 1.25); 
                   user.leadTime = time.toFixed(1) + " - " + (time + 2).toFixed(1);
               })
               //Total units
               var potentionalLead = (actual / 2) / 2.5;
               potentionalLead = (potentionalLead / 7.5 * 1.25);
               this.currentLeadTime = potentionalLead.toFixed(1) + " - " + (potentionalLead + 2).toFixed(1);
            }
        )
    }
    buildLeadTimeChart(averages, individualAverages){
        
        //@todo for tech team display individual lead time and average monthly lead time
        // console.log(averages);
        var totalTeamLeadTimePerMonth = []
        var start = moment().subtract(11, 'months');
        // console.log(allocatedUnits, usedUnits);
        for(var i=0;i<12;i++){
    
            totalTeamLeadTimePerMonth.push({
                x: new Date(start.year(), start.month()),
                y: (averages[start.month()])
            })
            start.add(1, 'month');
        }
        var data = [];
        data.push({ //Load Team Lead Time per month
            name: "Tech Teams Average",
            type: "spline",
            // yValueFormatString: "#0.## °C",
            showInLegend: true,
            xValueType: "MMM YYYY",
            xValueFormatString: "MMM YYYY",
            yValueFormatString: "#,##0.##\" days\"",
            dataPoints: totalTeamLeadTimePerMonth
        });
        for(var user of individualAverages){
            // console.log("new data");
            var userAvg = [];
            var startTeam = moment().subtract(11, 'months');
        // console.log(allocatedUnits, usedUnits);
            for(var i=0;i<12;i++){
        
                userAvg.push({
                    x: new Date(startTeam.year(), startTeam.month()),
                    y: (user.leadTimeByMonth[startTeam.month()])
                })
                startTeam.add(1, 'month');
            }
            data.push({
                name: user.name,
                type: "spline",
                // yValueFormatString: "#0.## °C",
                showInLegend: true,
                xValueType: "MMM YYYY",
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "#,##0.##\" days\"",
                dataPoints: userAvg
            })
        }
        // console.log(actual);
        this.leadTimeChart = new CanvasJS.Chart("chartContainer-lead", {
            animationEnabled: true,
            backgroundColor: 'transparent',
            // title: {
            //     text: "Avg Tech Team Lead time per Month"
            // },
            axisX: {
                title: "Date"
            },
            axisY: {
                title: "Days",
                suffix: ""
            },
            toolTip:{
                shared: true
            },
            data: data
        });
        this.loadingLeadTimeChart = false;
        this.leadTimeChart.render();
    }
}