import { Toasty } from 'app/shared/toastNotifications/toasty.service';
import { Subject, Observable, interval } from 'rxjs';
import { Injectable } from '@angular/core';
import { BfAuthService,BfAuthJwtHelper  } from '@brafton/skynet-angular-security-service';
import { User, Department, UserService, UserCreateModel } from '../KanbanService';
import { CoreApiAccountService } from 'app/services/core/core-api.service';
import { delay, filter, first, map, switchMap, tap } from 'rxjs/operators';
const APP_NAME = "kanban";
@Injectable()
export class AuthenticatedUser{
   
    private _user = new User;

    public get currentUser():User {
        return this._user;
    }
    public set currentUser(user){
        
        this._user = user;
        this.observer.next(this.currentUser);
    }
    
    private observer = new Subject<any>();
    public get userAsync():Observable<any>{

        return this.observer.asObservable();
    }
    private _userStub;
    public get user(){
        return this._userStub;
    }
    private get _emailStub(){

        var emailParts = this.currentUser.email.split('@');
        return emailParts[0];
    }
    constructor(private bfAuth:BfAuthService, private userService:UserService, private coreApiService:CoreApiAccountService, private toasty:Toasty){
 
        this.bfAuth.profileAsync.subscribe(
            prof=>{
                if(prof.email){
                    this.setTheUser(null);
                }
            }
        );
        this.bfAuth.tokenAsync.pipe(
        filter((t) => t.length > 0),
        map((t) => {
          console.log('recieved token');
          const tokenExpiry = BfAuthJwtHelper.expirationDate(t);
          console.log('token expiry is - ' + tokenExpiry.toString());
          return tokenExpiry;
        }),
        switchMap((t: Date) =>
          interval(60000).pipe(
            filter((o) => {
              const now = new Date();
              now.setMinutes(now.getMinutes() + 5);
              console.log('checking expiry : now ' + now.toString() + ' ----- ' + t.toString());
              return now > t;
            }),
            first(),
          ),
        ),
      )
      .subscribe((d) => {
        console.log('logging out');
        this.bfAuth.logout();
      });
    }
    public getUserDetails(){
        return this._user;
    }

    private setTheUser(user){

        let profile = this.bfAuth.profile;
        // console.log(profile);
        let appPermissions = [];
        if(profile.app_metadata.apps && profile.app_metadata.apps[APP_NAME]){
            appPermissions = profile.app_metadata.apps[APP_NAME];
        }
        this.currentUser = new User(
            {
                id: profile.app_metadata.profileId,
                name: `${profile.given_name} ${profile.family_name}`,
                teamId: profile.app_metadata.department_id,
                username: profile.email,
                role: profile.app_metadata.role,
                // role: "Staff",
                profileImageUrl: profile.picture,
                email: profile.email,
                permissions: appPermissions
            }
        )
        if(this.coreApiService.isReady){
            this.setProfile();
        }else{
            this.coreApiService.authenticated().subscribe(
                authenticated=>{
                    if(authenticated){
                        this.setProfile();
                    }
                }
            );
        }
        
        
        // console.log(this.currentUser, this._emailStub);
    }
    private setProfile(){
        this.coreApiService.getUserProfileById(this.currentUser.id).subscribe(
            _coreUser=>{
                // console.log(_coreUser);
                this._userStub = _coreUser;
            }
        )
    }
    getUserData(prop){
        return this.currentUser[prop];
    }    
    /**
     * check if the user permissions include required permission set 
     * [if stakeholder is required must pass in stakeholders to check against]

     * @param required If Permissions require a user to be a stakeholder as well as additional permissions sets. Role.isStakeholder must be the first argument passed in the required array.
     * @param stakeholderList 
     */
    checkPermission(required:Array<string>, stakeholders?:Array<User>){
        // console.log(this.currentUser.permissions);
            // User is a Brafton Admin and complete access
            if(!this.currentUser.permissions){
                return false;
            }
            if(this.currentUser.role == Roles.Admin){
                return true;
            }
            if(required.indexOf(PermissionSet.isTechTeam) > -1){
                return this.currentUser.teamId == 10;
            }
            var granted = false;
            var stakeholderIndex = required.indexOf( PermissionSet.isStakeholder );
            if(stakeholderIndex === 0){ // Permission set requires stakeholder with the possibilities of additional requirments
                if(stakeholders.findIndex( stakeholder=>{
                    // if(stakeholder.email){
                    //     var stakeholderParts = stakeholder.email.split('@');
                    //     return stakeholderParts[0] == this._emailStub;
                    // }
                    if(stakeholder.id){
                        return stakeholder.id == this.currentUser.id;
                    }
                    return false;
                }) > -1){
                    // remove the 
                    required.splice(stakeholderIndex, 1); 
                    granted = true;
                    //check if there are additional permissionsets required beyond being just a stakeholder
                    if(required.length > 0){
                        required.forEach(check=>{
                            if( !this.currentUser.permissions.includes(check) ){
                                granted = false;
                            }
                        });
                    }
                }
            }else{ // Permissions require stakeholder or other permissions sets but not in addition to.
                if( stakeholderIndex > -1 && (
                    stakeholders.findIndex( stakeholder=>{
                        // if(stakeholder.email){
                        //     var stakeholderParts = stakeholder.email.split('@');
                        //     return stakeholderParts[0] == this._emailStub;
                        // }
                        if(stakeholder.id){
                            return stakeholder.id == this.currentUser.id;
                        }
                        return false;
                    }) > -1
                )
                ){
                    granted = true;
                }
                // Permissions do not require being a stakeholder so just check permission set.
                required.forEach(check=>{
                    if(check == PermissionSet.readTeamTickets && (this.currentUser.permissions.includes(check)) ){ // Permissions set for readteamtickets require an additional check for if a stakeholder is also part of the current users team.
                        stakeholders.forEach(user=>{

                            if(user.team && (this.currentUser.teamId == user.team['id'])){
                                granted = true;
                            }
                        })
                    }else if( this.currentUser.permissions.includes(check) ){
                        granted = true;
                    }
                });
            }
            return granted;
    }
}
export class Roles{
    static Admin = "Admin";
    static Staff = "Staff";
    static Role = "Role";
    static Client = "Client";
}
export class PermissionSet{
    //skynet permissions sets
    static createTicket = 'createTicket';
    static readAllTicketDetails = 'readAllTicketDetails';
    static modifyTicket = 'modifyTicket';
    static modifyTicketDevelopers = 'modifyTicketDevelopers';
    static modifyTicketQuote = 'modifyTicketQuote';
    static requestQA = 'requestQA';
    static readTeamTickets = 'readTeamTickets';
    static readAllTickets = 'readAllTickets';
    static closeTicket = 'closeTicket';
    static createAccount = 'createAccount';
    static modifyAccount = 'modifyAccount';
    static readAllAccounts = 'readAllAccounts';
    static readSettings = 'readSettings';
    static modifySettings = 'modifySettings';
    
    //need something to ask about admin this is not a permission
    static isAdmin = 'isAdmin';
    //need to determine if user is on the tech team
    static isTechTeam = 'isTechTeam';
    // isStakeholder is purely an app permission and is not loaded via skynet.
    static isStakeholder = 'stakeholder';
}