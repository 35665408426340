import { User } from './User';
export class AccountNote{
    public id: string;
    public accountId: string;
    public createdDate: number;
    public updatedDate?: number;
    public createdBy: User;
    public updatedBy?: User;
    public note: string;
    constructor(accountNote?){
        
        Object.assign(this, accountNote);
    }
}