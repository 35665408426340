import { AccountNoteCreateModel } from './../models/requestCreateModels/AccountNoteCreateModel';
import { AccountCreateModel } from './../models/requestCreateModels/AccountCreateModel';
import { DataService } from './../shared/data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Account } from '../models/responseModels/Account';
import { AccountNote } from '../models/responseModels/AccountNote';
import { AccountNoteUpdateModel } from '../models/requestUpdateModels/AccountNoteUpdateModel';

@Injectable()
export class AccountNoteService extends DataService {
    // constructor(_http:Http) {
    //     super(_http);
    // }
    listAccountsNotes(accountId:string):Observable<Array<AccountNote>>{
        let url = `accounts/${accountId}/notes`;
        return this.makeGetRequest(url)
            .pipe(map( (accounts)=>{
                return accounts.map(account=>{
                    return new AccountNote(account);
                })
            }))
    }
    createAccountNote(accountNote:AccountNoteCreateModel):Observable<AccountNote>{
        let url = `accounts/${accountNote.accountId}/notes`;
        return this.makePostRequest(url, accountNote)
            .pipe(map( (accounts)=>{
                return new AccountNote(accounts[0])
            }))
    }

    getAccountNoteById(accountId, noteId):Observable<AccountNote>{
        let url = `accounts/${accountId}/notes/${noteId}`;
        return this.makeGetRequest(url)
            .pipe(map( (accounts)=>{
                return new AccountNote(accounts[0])
            }))
    }
    updateAccountNote(accountNote:AccountNoteUpdateModel):Observable<AccountNote>{
        let url = `accounts/${accountNote.accountId}/notes/${accountNote.id}`;
        return this.makePostRequest(url, accountNote)
            .pipe(map( (accounts)=>{
                return new AccountNote(accounts[0])
            }))
    }
    deleteAccountNote(accountId:string,noteId:string){
        let url = `accounts/${accountId}/notes/${noteId}`;
        return this.makeDeleteRequest(url)
            .pipe(map( (accounts)=>{
                return new AccountNote(accounts[0])
            }))
    }
}
