import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.scss']
})
export class ConfirmationDialogComponent{

    public message = "";
    public title = "";
    public subtitle = null;
    public confirm = false;
    public standard = true;
    public customOptions;
    constructor(@Inject(MAT_DIALOG_DATA) public data:ConfirmationDialogOptions, private dialogRef:MatDialogRef<ConfirmationDialogComponent>, private toasty:Toasty){
        
        this.message =  data.message || "";
        this.title = data.title || "";
        this.confirm = data.confirm || false;
        this.subtitle = data.subtitle || null;
        if(data.customOptions){
            this.standard = false;
            this.customOptions = data.customOptions;
        }
    }

    closeTrue(){
        this.dialogRef.close(true);
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}

export interface ConfirmationDialogOptions{
    message:string;
    title?:string;
    confirm?:boolean;
    subtitle?:string;
    customOptions?:Array<{label:string,value:any}>;
}