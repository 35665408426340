import { KanbanConfigApi } from './../../app.config';
import { KANBAN_CONFIG, KanbanApiConfig } from './../../KanbanService/shared/api.config';
import { Observable,from, forkJoin, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BfAuthGuard, BfAuthService } from '@brafton/skynet-angular-security-service';
import { HttpClient, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class CoreApiAccountService{
    public isReady = false;
    private subject:Subject<any> = new Subject();
    private tokens = {
        'brafton': "",
        'contentlead': ""
    };
    private brafton_core_api_url = 'https://core-api-lambda.internal.brafton.com/api/';
    private contentlead_core_api_url = 'https://core-api-lambda.internal.contentlead.com/api/';
    private core_api_url = {
        'BR': this.brafton_core_api_url,
        'CF': this.brafton_core_api_url,
        'CL': this.contentlead_core_api_url
    };
    constructor(private _http:HttpClient, private bfAuth:BfAuthService, 
        @Inject(KANBAN_CONFIG) private _apiConfig: KanbanApiConfig
    ){
        bfAuth.delegateTokensAsync.subscribe(
            token=>{
                // console.log(token);
                let braftonCore = token.find(t=>t.AppName == 'core-api');
                // console.log(braftonCore);
                this.tokens.brafton = braftonCore? braftonCore.id_token :"";
                // console.log(this.tokens);
                // this.subject.next(true);
                this.isReady = true;
            }
        )
        // console.log(this._apiConfig.getVal());
        // this.authenticateForContentLead();
    }
    public authenticated():Observable<any>{
        return this.subject.asObservable();
    }
    authenticateForContentLead(){
        this._http.get(this._apiConfig.baseUrl+'authenticate/core-api').subscribe(
            (auth:any)=>{
                this.tokens.contentlead = auth.contentlead.id_token;
                this.tokens.brafton = auth.brafton.id_token;
                this.subject.next(true);
                this.isReady = true;
            }
        )
    }
    toQueryString(conditions = {}){
        
        let query = Object.keys(conditions).reduce( (query, prop, index) =>{
            query += index > 0? "&":"";
            return query + prop+"="+conditions[prop];
        },"");
        return query.length > 0? "?"+query : "";
    }
    listAccounts(conditions:AccountsRequestModel = {}){ //@todo make two requests and combine observable
        let br_url = this.brafton_core_api_url + 'accounts' + this.toQueryString(conditions);
        // let cl_url = this.contentlead_core_api_url + 'accounts' + this.toQueryString(conditions);

        let brafton = this._http.get(br_url, this.setHeaders()).pipe(map( (res:any)=>{
            let response = res;
            
            response.items.map(item=>{
                if(item.businessUnit.id == 7){
                    item._brand = "CF";
                }else{
                    item._brand = 'BR';
                }
                return item;
            });
            return response;

        })).pipe(catchError(error=>{
            console.log(error);
            return from([error]);
        }))
        // let contentlead = this._http.get(cl_url, this.setHeaders('contentlead')).pipe(map( (res:any)=>{
        //     let response = res;
        //     response.items.map(item=>{
        //             item._brand = 'CL';   
        //         return item;
        //     });
        //     return response;
        // })).pipe(catchError(error=>{
        //     console.log(error);
        //     return from([error]);
        // }))
        // let contentlead = Observable.from(new Promise((res, rej)=>{
        //     setTimeout(res, 100, []);
        // }));
        return brafton.pipe(map( all=>{
            // console.log(all);
            all.items.sort( (a,b)=>{
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;  
            });  
            return all;
        }));
    }
    listFeeds(conditions:FeedsRequestModel = {}){
        let br_url = this.brafton_core_api_url + 'feeds' + this.toQueryString(conditions);
        let cl_url = this.contentlead_core_api_url + 'feeds' + this.toQueryString(conditions);

        let brafton = this._http.get(br_url, this.setHeaders()).pipe(map( (res:any)=>{
            let response = res;
            
            response.items.map(item=>{
                if(item.account.businessUnit.id == 7){
                    item.account._brand = "CF";
                }else{
                    item.account._brand = 'BR';
                }
                return item;
            });
            return response;

        })).pipe(catchError(error=>{
            console.log(error);
            return from([error]);
        }))
        // let contentlead = this._http.get(cl_url, this.setHeaders('contentlead')).pipe(map( (res:any)=>{
        //     let response = res;
        //     response.items.map(item=>{
        //             item.account._brand = 'CL';   
        //         return item;
        //     });
        //     return response;
        // })).pipe(catchError(error=>{
        //     console.log(error);
        //     return from([error]);
        // }))
        // let contentlead = Observable.from(new Promise((res, rej)=>{
        //     setTimeout(res, 100, []);
        // }));
        return brafton.pipe(map( all=>{
            
            all.items.sort( (a,b)=>{
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;  
            });  
            return all;
        }));
    }
    getAccountInfo(id, brand = null):Observable<any>{
        if(!brand){
            brand = "BR";
        }
        let url = this.core_api_url[brand] + `Accounts/${id}`;
        return this._http.get(url, this.setHeaders()).
            pipe(map( (res:any)=>{
                return res;
            })).
            pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    getFeeds(accountId){
        let url = this.core_api_url['BR']+`Feeds/?accountId=${accountId}`;
        return this._http.get(url, this.setHeaders()).
            pipe(map( (res:any)=>{
                return res.items;
            })).
            pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    getVideoCreds(accountId){
        let url = this.core_api_url['BR']+`accounts/${accountId}/videoApiCredentials`;
        return this._http.get(url, this.setHeaders()).
            pipe(map( (res:any)=>{
                return res;
            })).
            pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    getCdnInfo(feedId){
        let url = this.core_api_url['BR'] + `FeedContentDelivery/getbyfeedid?feedId=${feedId}`;
        return this._http.get(url, this.setHeaders()).
            pipe(map( (res:any)=>{
                return res;
            })).
            pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    getUserProfileByEmail(email:string){
        let url = this.brafton_core_api_url +  `Profiles/${email}`;
        return this._http.get(url, this.setHeaders())
            .pipe(map( (res:any)=>{
                return res
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    getUserProfileById(id:string|number){
        let url = this.brafton_core_api_url + `Profiles/${id}`;
        return this._http.get(url, this.setHeaders())
            .pipe(map( (res:any)=>{
                return res;
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    listAllUsers(pageSize = 100){
        let url = this.brafton_core_api_url + `Profiles?pageSize=${pageSize}`;
        return this._http.get(url, this.setHeaders())
            .pipe(map((res:any)=>{
                return res;
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))

    }
    listUserByQuery(search){
        let url = this.brafton_core_api_url + `Profiles/listbyquery?query=${search}`;
        return this._http.get(url, this.setHeaders())
            .pipe(map((res:any)=>{
                return res;
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    listTeams(){
        let url = this.brafton_core_api_url + `profiles?profileTypeId=4`;
        return this._http.get(url, this.setHeaders())
            .pipe(map((res:any)=>{
                return res;
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    listTeamProfiles(teamId){
        let url = this.brafton_core_api_url + `profileavailabilities/?teamId=${teamId}&enabled=true`;
        return this._http.get(url, this.setHeaders())
            .pipe(map((res:any)=>{
                return res;
            }))
            .pipe(catchError(error=>{
                console.log(error);
                return error;
            }))
    }
    listTechnicalServicesProfiles(){
        return this.listTeamProfiles(10)
            .pipe(map(res=>{
                return res.filter(item=>item.type.id==1);
            }));
    }
    setHeaders(brand = 'brafton'){
        let headers = new HttpHeaders({
            'Authorization':  'Bearer '+this.tokens['brafton'],
            'Content-Type': 'application/json'
        });

        // let options = new RequestOptions({headers: headers});
        return {headers: headers};
    }
}
interface BaseRequestModel{
    query?: string;
    page?:number;
    pageSize?:number;
    status?:string;
    accountManagerId?:number;
    podsId?:string;
    sectionIds?:string;
}
export interface AccountsRequestModel extends BaseRequestModel{
}
export interface FeedsRequestModel extends BaseRequestModel{
    accountId?:number;
}