import { Injectable } from '@angular/core';
import { DataService } from '../shared/data.service';

@Injectable()
export class FileService extends DataService{
    private today = new Date();

    uploadRawFiles(ticketId, files:FileList){
        let url = `files/upload`;
        let data = new FormData();
        for(let i =0; i<files.length;i++){
            data.append('file[]', files[i]);
        }
        if(ticketId){
            data.append("ticketId", ticketId);
        }
        return this.makePostRequest(url, data);
    }
}