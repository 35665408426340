export abstract class _User {
  id: string;
  name: string;
  email: string;
  permissions?: Array<string>;
  userIcon?: string;
  lastLogin: number;
  profile?: object;
  role?: string;
  receiveNotifications: boolean;
  teamId?: number;
  team: object;

}