import { Toasty } from './../shared/toastNotifications/toasty.service';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppKanbanConfig } from '../app.config';

@Component({
    moduleId: module.id,
    selector: 'login',
    providers: [],
    templateUrl: 'login.component.html',
    styles: [`
        form{
            padding-top:15%;
            width:50%;
            margin:auto;
            text-align:center;
        }
    `],
    outputs: ['someevent']
})
export class LoginComponent implements OnInit{
    username:string;
    password:string;
    model:Object = {
        username: "",
        password:""
    };
    private _hold = false;
    constructor(private authService: BfAuthService, private route: Router, private appConfig: AppKanbanConfig) {
    }

    ngOnInit() {
        if(!this.authService.authenticated()){
            
            this.authService.login();
        }else{
            
            this.route.navigate(['dashboard'])
        }
    }

}