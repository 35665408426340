export enum WorkType{
    Contract,
    Unpaid,
    Rolling,
    Internal
}
export const WORKTYPE = [
    'Contract',
    'Unpaid',
    'Rolling',
    'Internal'
]