import { User, Asset } from "app/KanbanService";

import { Department, TicketStatus, TicketWorkType, TicketPriority } from "../../enums";

export abstract class _TicketCreateModel {
  accountManager: User;
  developer: User;
  department: Department;
  stakeholders: Array<User>;
  paymentType: string;
  pricePaid?: number;
  unitsAllocated?: number;
  status: TicketStatus;
  isClosed: boolean;
  account: Array<any>;
  feed?:Object;
  workType: TicketWorkType;
  workCategory: string;
  subject: string;
  scope: string;
  assets?: Array<Asset>;
  daedelusId?: string;
  atlasId?:string;
  createdDate: number;
  dueDate?: number;
  priority: TicketPriority;
  createdBy: User;
  salesforceId?: string;
  trelloId?:string;
  projectId?: string;
  isQuote: boolean;
  quotedWorkTicket: boolean;
  quoteTicketId?: string;
  quoteDollar?: number;
  quoteUnits?: number;
  quoteHours?: number;
  parentTicketId?: string;
  atlas?:{id:string,module:string,name:string,notes?:string}
}