import { WORKTYPE, WorkType } from './../../KanbanService/models/enums/WorkType';
import { WorkService } from './../../KanbanService/services/work.service';
import { CommonModule } from '@angular/common';
import { Component, Input, Inject, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

import {map} from 'rxjs/operators';
import { WorkCategory } from 'app/KanbanService';


@Component({
    selector: 'worktype-dialog',
    templateUrl: './workTypePopup.component.html',
    styleUrls: ['./worktype.scss']
})
export class WorkTypePopUpDialogComponent{
    public simpleInput = false;
    public message = "";
    public title = "Update Work Category";
    public currentType = null;
    public currentCategory = null;
    public workTypes = WORKTYPE;
    public workType = {};
    public workCategories = [];
    public catObj:WorkCategory;
    public selectedValues = {
      type: null,
      cat: null
    };
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<WorkTypePopUpDialogComponent>, private toasty:Toasty, private workTypeService:WorkService){
        this.currentType = data.workType;
        this.currentCategory = data.workCategory;
    }
    determineLikelyWorkCategory($event){
      let type:WorkType = <WorkType> WorkType[<string>$event.value];
      this.selectedValues.type = $event.value;
      console.log($event);
      this.workTypeService.listWorkCategories(type).subscribe(
        cats=>{
          console.log(cats);
          this.workCategories = cats;
        }
      )
    }

    determineUnitAllocation($event){
      let cat = $event.value;
        let catObj:WorkCategory = this.workCategories.find(_cat=>_cat.name==cat);
        this.catObj = catObj;
        this.selectedValues.cat = catObj.name;

    }

    setTicketValues(){}

    closeTrue(){
      if(!this.selectedValues.type || !this.selectedValues.cat){
        this.selectedValues = null;
      }
      this.dialogRef.close(this.selectedValues);
    }
}

export interface WorkTypePopUpDialogOptions{
    message;
    title?;
    subtitle?;
    workType;
    workCategory;
};