import { TicketUpdateModel } from './../KanbanService/models/requestUpdateModels/TicketUpdateModel';
import { TicketStages } from './../KanbanService/models/enums/TicketStages';
import { Component } from '@angular/core';
import { TICKETSTATUS, TicketStatus, Department, TicketService, UserService, Ticket, User } from 'app/KanbanService';
import { MatDialog, DialogPosition } from '@angular/material';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { AuthenticatedUser, PermissionSet } from 'app/shared/authenticatedUser.service';
import { TicketListService } from 'app/shared/ticketListService';
import { ActivatedRoute, Router } from '@angular/router';
import { SingleTicketView } from 'app/singleTicket/singleTicket.component';
import { ConfirmationDialogOptions, ConfirmationDialogComponent } from 'app/shared/confirmation/confirmation.component';
import { TicketActions } from 'app/singleTicket/ticketActionsEnum';
import { ControlContainer, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ticket-board',
    template: `<router-outlet></router-outlet>`
})
export class TicketBoardComponent{

}
/**
 * TicketBoardInterface
 * provides ticket board functionality for assorted ticket views.
 */
export abstract class TicketBoardInterface{
    public displayColumns = [TicketStatus.pending, TicketStatus.progress, TicketStatus.techHold, TicketStatus.woam, TicketStatus.approval];
    public subs:Subscription[] = [];
    public _Department = Department;
    public activeColumns = [];
    public tickets = [];
    public sortParameters = "";
    public openShow = [];
    public stakeholders:User[] = [];
    public developers:User[] = [];
    public accounts:any[] = [];
    public businessUnit:any[] = [];
    public parameters = {
        stakeholderId: null,
        status: null,
        accountId: null
    };
    public filterTheResults = {
        department: null,
        manager: null,
        developer: null,
        account:null,
        business:null
    }
    public filterForm = {
        department: new FormControl(''),
        manager: new FormControl(''),
        developer: new FormControl(''),
        account: new FormControl(''),
        business: new FormControl('')
    }
    private tmpTicketHold = null;
    public ticketCount = 0;
    public devShowAllTickets = false;
    public loadingTickets = true;
    constructor(
        public dialog: MatDialog,
        protected ticketService:TicketService, 
        protected userService:UserService,
        protected bfAuth:BfAuthService,
        protected authenticatedUser:AuthenticatedUser,
        protected _ticketList:TicketListService,
        protected _activatedRoute:ActivatedRoute,
        protected _router:Router
    ){
    }
    setupColumns(){
        this.activeColumns = [];
        TICKETSTATUS.forEach((stage)=>{
            if(stage == TICKETSTATUS[TicketStatus.closed]
                || stage == TICKETSTATUS[TicketStatus.open] 
                || stage == TICKETSTATUS[TicketStatus.design] 
                || stage == TICKETSTATUS[TicketStatus.devReady] 
            ){
                return;
            }
            var f_name = stage.replace(/ /g, "-");
            this.activeColumns.push({
                id: f_name,
                name: stage
            });
        })
    }
    retreiveTickets(status:TicketStatus[] = null, stakeholderEmail = null, accountId = null){
        
        this.tickets = [];
        this.ticketCount = 0;
        this._ticketList.listTicketsAsync(status, stakeholderEmail,accountId).subscribe(this.sortTickets.bind(this));
        // a new ticket has been received that needs to be added to the ticketboard
        this._ticketList.subscribeToTickets().subscribe(
            ticket=>{
                console.log("foriegn ticket incoming", ticket);
                var f_name = TICKETSTATUS[ticket.status].replace(/ /g, "-");
                if(!this.tickets[f_name]){
                    this.tickets[f_name] = [];
                }
                this.tickets[f_name].push(ticket);
                this.ticketCount++;
            }
        )
    }
    removeFilter($event, filter){
        document.getElementById('filter-'+filter);
        this.filterTheResults[filter] = null;
        this.filterForm[filter].reset();
        let rawTickets = this._ticketList.listTickets(this.parameters.status, this.parameters.stakeholderId);
        rawTickets = this.filterTickets(rawTickets);
        this.sortTickets(rawTickets);
    }
    setUserFilters(ticket:Ticket){

        try{
            // let accountOwnerIndex = this.accountOwners.findIndex(u=>u.id == ticket.accountManager.id);
            // if(accountOwnerIndex === -1){
            //     this.accountOwners.push(ticket.accountManager);
            // }
            ticket.stakeholders.forEach(user=>{
                let userIndex = this.stakeholders.findIndex(u=>u.id==user.id);
                if(userIndex === -1){
                    this.stakeholders.push(user);
                }
            })
            let developerIndex = this.developers.findIndex(u=>u.id==ticket.developer.id);
            if(developerIndex === -1 && ticket.developer){
                console.log(ticket.developer, ticket);
                this.developers.push(ticket.developer);
            }
            let accountIndex = this.accounts.findIndex(a=>a.id==ticket.account['id']);
            if(accountIndex === -1 && ticket.account){
                this.accounts.push(ticket.account);
                
            }
            let businessIndex = this.businessUnit.findIndex(b=>b.id==ticket.account['businessUnit']['id']);
            if(businessIndex === -1 && ticket.account['businessUnit']){
                this.businessUnit.push(ticket.account['businessUnit']);
            }
        }catch(e){
            
        }
        
    }
    sortTickets(tickets:Array<Ticket>){
        // console.log(tickets);
        this.tickets = [];
        this.ticketCount = 0;
        tickets.forEach(ticket=>{
            
            try{
            var f_name = TICKETSTATUS[ticket.status].replace(/ /g, "-");
            }catch(e){
                console.log(e);
            }
            if(this.userCanNotSeeTicket(ticket, f_name)){
                return;
            }
            
            this.setUserFilters(ticket);
            if(!this.tickets[f_name]){
                this.tickets[f_name] = [];
            }           

            if(ticket.swimmer){
                this.tickets[f_name].splice(0,0,ticket);
            }else{
                this.tickets[f_name].push(ticket);
            }
            this.ticketCount++;
        })
        this.accounts.sort((a,b)=>{
            
            if(!a.name || !b.name){
                return 0;
            }
            try{
                
                if(a.name.toLowerCase() < b.name.toLowerCase()){
                    return -1;
                }
                if(a.name.toLowerCase() > b.name.toLowerCase()){
                    return 1;
                }
            }catch(e){
                console.log(e, a,b);
            }
            return 0;
        })
        this.loadingTickets = false;
        for(let field in this.tickets){
            // console.log(field);
            this.sortTicketColumn(field);
        }
    }
    toggleShowAll($event){
        console.log($event);
        this.devShowAllTickets = !this.devShowAllTickets;
        this.setFilterCrit(null);
    }
    userCanNotSeeTicket(ticket:Ticket, statusName){
        var userCanSeeTicket = false;
        if(this.sortParameters == 'all'){ // 2 reasons you can see tickets on the all (readAllTickets, roleIsAdmin)
            if(this.authenticatedUser.checkPermission([PermissionSet.readAllTickets]) ){

                userCanSeeTicket = true;
            }
        }else if(this.sortParameters == "team"){ // 2 ways you can see your teams tickets (readTeamTickets, roleIsAdmin);
           if(this.authenticatedUser.checkPermission([PermissionSet.readTeamTickets],ticket.stakeholders )){
               userCanSeeTicket = true;
           }
        }else if(this.sortParameters == 'mine'){ // only way tickets show up here is if you are a stakeholder in the ticket.
            if(this.authenticatedUser.checkPermission([PermissionSet.isStakeholder], ticket.stakeholders)){
                userCanSeeTicket = true;
            }
            if(this.authenticatedUser.currentUser.teamId == 10 && !this.devShowAllTickets){ //current user is a member of the tech team and to help limit noise we limit the "mine" to the tickets they are the developer on. 

                 if(ticket.developer && (ticket.developer.email !== this.authenticatedUser.currentUser.email) ){
                    userCanSeeTicket = false;
                }

            }
        }else if(ticket.developer){ // fallback if you are the developer you can see the ticket.@todo determine if its needed
            // if(ticket.developer.email == this.authenticatedUser.currentUser.email){
            //     userCanSeeTicket = true;
            // }
        }
        return !userCanSeeTicket;
    }
    setFilterCrit(selection){
        try{
            let crit = selection.source.id.split('-');
            let value = selection.value;
            this.filterTheResults[crit[1]] = value;
        }catch(e){}
        if(this.tmpTicketHold === null){ // if we are currently 
            this.tmpTicketHold = this.tickets;
        }
        
        let rawTickets = this._ticketList.listTickets(this.parameters.status, this.parameters.stakeholderId);
        rawTickets = this.filterTickets(rawTickets);
        this.sortTickets(rawTickets);
        //filter the ticket results
    }
    filterTickets(tickets:Ticket[]){

        if(this.filterTheResults.department){
            let dept = Department[this.filterTheResults.department];
            tickets = tickets.filter(ticket=>ticket.department == parseFloat(dept) );
        }
        if(this.filterTheResults.developer){
            tickets = tickets.filter(ticket=>ticket.developer? ticket.developer.id == this.filterTheResults.developer.id : false);
        }
        if(this.filterTheResults.manager){ //now this is stakeholder i need a map
            // tickets = tickets.filter(ticket=>ticket.accountManager.id ==this.filterTheResults.manager.id);
            tickets = tickets.filter(ticket=>{
                return ticket.stakeholders.find(u=>u.id == this.filterTheResults.manager.id);
            })
        }
        if(this.filterTheResults.account){
            tickets = tickets.filter(ticket=>{
                console.log(ticket);
                return ticket.account['id']==this.filterTheResults.account.id
            });
        }
        if(this.filterTheResults.business){
            tickets = tickets.filter(ticket=>{
                if(ticket.account['businessUnit']){
                    return ticket.account['businessUnit']['id']==this.filterTheResults.business.id

                }
                return false;
            });
        }
        return tickets;
    }
    resetFilterCrit(){
        //need to reset the select fields as well.
        for(let res in this.filterTheResults){
            console.log(res);
            document.getElementById('filter-'+res)
            this.filterTheResults[res] = null;
            this.filterForm[res].reset();
        }
        this.tickets = this.tmpTicketHold;
        this.tmpTicketHold = null;
    }
    // showTicket(ticket){
    //     console.log(ticket);
    //     var previousTicketStatus = ticket.statusName();
    //     let pos:DialogPosition = {
    //         right: "0",
    //         // bottom: "0",
    //         // top: "69px"
    //     }
    //     this.dialog.open(SingleTicketView, {
    //         position: pos,
    //         data: {
    //             ticket: ticket
    //         },
    //         width:"65%",
    //         // height: "91%",
    //         panelClass: "ticket-details"
    //     }).afterClosed().subscribe((tix:Ticket)=>{
    //         console.log(tix);
    //         if(!tix){
    //             let oldStatusIndex = this.tickets[previousTicketStatus].findIndex(t=>t.id==tix.id);
    //             this.tickets[previousTicketStatus].splice(oldStatusIndex, 1);
    //             return;
    //         }

    //         //closed not being removed from list???? 
    //         if(!this.tickets[tix.statusName()]){
    //             this.tickets[tix.statusName()] = [];
    //         }
    //         if(previousTicketStatus !== tix.statusName()){
    //             //remove ticket from old status array and add to the new array
    //             let oldStatusIndex = this.tickets[previousTicketStatus].findIndex(t=>t.id==tix.id);
    //             this.tickets[previousTicketStatus].splice(oldStatusIndex, 1);
    //             this.tickets[tix.statusName()].push(tix);

    
    //         }else{
    //             let index = this.tickets[tix.statusName()].findIndex(_tix=>_tix.id==tix.id);
    //             if(index > -1){
    //                 this.tickets[tix.statusName()][index] = tix;
    //             }
    //         }
    //     })
    // }
    //Listens to changes made to a ticket when it is opened into full view
    updateBoard($event, ticket:Ticket){
        if($event.action == TicketActions.DeletedTicket){
            let oldStatusIndex = this.tickets[ticket.statusName()].findIndex(t=>t.id==ticket.id);
            this.tickets[ticket.statusName()].splice(oldStatusIndex, 1);
            return;
        }
        
        let tix:Ticket = $event.ticket;
        console.log(tix, ticket);
        if(TicketStatus.closed != tix.status){
            if(!this.tickets[tix.statusName()]){
                this.tickets[tix.statusName()] = [];
            }
        }
        let status = ticket.statusName();
        let _status = tix.statusName();
        if(ticket.statusName() !== tix.statusName()){
            //remove ticket from old status array and add to the new array
            let oldStatusIndex = this.tickets[ticket.statusName()].findIndex(t=>t.id==tix.id);
            this.tickets[ticket.statusName()].splice(oldStatusIndex, 1);
            if(tix.status != TicketStatus.closed){
                this.tickets[tix.statusName()].push(tix);
            }

        }else{
            let index = this.tickets[tix.statusName()].findIndex(_tix=>_tix.id==tix.id);
            if(index > -1){
                this.tickets[tix.statusName()][index] = tix;
            }
        }
        this._ticketList.updateTicket(tix);

    }
    changeDeveloper(updatedTicket, new_status_name, ticketData){
        //get list of developers available

        //change the dev with a dialog to select the dev from a dropdown list. Keep any previous devs as stakeholder for reference 

        //notify dev they have a new ticket via push message and email

        //notify the old dev that they are no longer responsible for this ticket but to connect with the new developer for handoff. 
        if(!this.tickets[new_status_name]){
            this.tickets[new_status_name] = [];
        }
        if(ticketData.status == TicketStatus.open){
            //assign the ticket to the active user. 
            let options:ConfirmationDialogOptions = {
                title: "Assign Developer",
                message: "Would you like to assign yourself as the developer?"
            }
            // options.title = "Assign Developer";
            // options.
            this.dialog.open(ConfirmationDialogComponent, {
                data: options
            }).afterClosed().subscribe(
                res=>{
                    if(res){
                        this.ticketService.updateDeveloper(ticketData.id,this.authenticatedUser.currentUser).subscribe(
                            devTicket=>{
                                console.log(devTicket);
                                this.tickets[new_status_name].push(devTicket);
                            }
                        )
                    }else{
                        //get a list of devs
                        this.userService.listUsers({
                            role: "Developer"
                        }).subscribe(
                            (devs:Array<User>)=>{
                                this.dialog.open(ConfirmationDialogComponent, {
                                    data: {
                                        title: "Choose a Developer",
                                        customOptions: devs.map( user=>{
                                            return {
                                                "label": user.name,
                                                "value": user.email
                                                };
                                        })
                                    }
                                }).afterClosed().subscribe(
                                    res=>{
                                        console.log(res);
                                        let choosenDev = devs.find( _dev=>{
                                            return _dev.email === res
                                        });
                                        this.ticketService.updateDeveloper(ticketData.id,choosenDev).subscribe(
                                            devTicket=>{
                                                console.log(devTicket);
                                                this.tickets[new_status_name].push(devTicket);
                                                this.sortTicketColumn(new_status_name);
                                            }
                                        )
                                    }
                                )
                            }
                        )
                        
                    }
                }
            )
        }else{
            this.tickets[new_status_name].push(updatedTicket);
            this.sortTicketColumn(new_status_name);
        }

        
    }
    userActionPermitted(){
        return true;
    }
    dropChangeStatus(e, column, columnIndex){
        let ticketData: Ticket = e.dragData;
        let updatedTicket:TicketUpdateModel = {};
        var old_status_name = TICKETSTATUS[ticketData.status].replace(/ /g, "-");
        var new_status_name = TICKETSTATUS[columnIndex].replace(/ /g, "-");
        if(!this.userActionPermitted()){
            return;
        }
        if(ticketData.status === columnIndex 
            // || this.user.isNotADev 
            || columnIndex === 0
        
        ){
            return this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    message: "You cannot complete this ticket move from " + old_status_name + " To " + new_status_name,
                    confirm: true
                }
            }).afterClosed().subscribe(
                res=>{
                    console.log(res);
                }
            )

        }

        let oldStatus = ticketData.status;
        let tIndex = (this.tickets[old_status_name] as Array<Ticket>).findIndex(t=>t.id == ticketData.id);
        (this.tickets[old_status_name] as Array<Ticket>).splice(tIndex, 1);
        updatedTicket.status = columnIndex;
        updatedTicket.lastModifiedDate = new Date().getTime();
        updatedTicket.lastMoved['from'] = ticketData.lastMoved && ticketData.lastMoved['to']? ticketData.lastMoved['to']:  {
            state: old_status_name,
            timestamp: ticketData.createdDate
        }
        updatedTicket.lastMoved['to'] = {
            state: new_status_name,
            timestamp: new Date().getTime()
        }
        // {
        //     from: ticketData.lastMoved && ticketData.lastMoved['to']? ticketData.lastMoved['to']:  {
        //         state: old_status_name,
        //         timestamp: ticketData.createdDate
        //     },
        //     to: {
        //         state: new_status_name,
        //         timestamp: new Date().getTime()
        //     }
        // }
        this.ticketService.updateTicket(ticketData.id, updatedTicket).subscribe(
            updatedTicket=>{
                if(!this.tickets[new_status_name]){
                    this.tickets[new_status_name] = [];
                }
                if(ticketData.status == TicketStatus.open){
                    //assign the ticket to the active user. 
                    let options:ConfirmationDialogOptions = {
                        title: "Assign Developer",
                        message: "Would you like to assign yourself as the developer?"
                    }
                    // options.title = "Assign Developer";
                    // options.
                    this.dialog.open(ConfirmationDialogComponent, {
                        data: options
                    }).afterClosed().subscribe(
                        res=>{
                            if(res){
                                this.ticketService.updateDeveloper(ticketData.id,this.authenticatedUser.currentUser).subscribe(
                                    devTicket=>{
                                        console.log(devTicket);
                                        this.tickets[new_status_name].push(devTicket);
                                    }
                                )
                            }else{
                                //get a list of devs
                                this.userService.listUsers({
                                    role: "Developer"
                                }).subscribe(
                                    (devs:Array<User>)=>{
                                        this.dialog.open(ConfirmationDialogComponent, {
                                            data: {
                                                title: "Choose a Developer",
                                                customOptions: devs.map( user=>{
                                                    return {
                                                        "label": user.name,
                                                        "value": user.email
                                                        };
                                                })
                                            }
                                        }).afterClosed().subscribe(
                                            res=>{
                                                console.log(res);
                                                let choosenDev = devs.find( _dev=>{
                                                    return _dev.email === res
                                                });
                                                this.ticketService.updateDeveloper(ticketData.id,choosenDev).subscribe(
                                                    devTicket=>{
                                                        console.log(devTicket);
                                                        this.tickets[new_status_name].push(devTicket);
                                                        this.sortTicketColumn(new_status_name);
                                                    }
                                                )
                                            }
                                        )
                                    }
                                )
                                
                            }
                        }
                    )
                }else{
                    this.tickets[new_status_name].push(updatedTicket);
                    this.sortTicketColumn(new_status_name);
                }

                
            }
        )
        //update ticket.time[status] using the time since lastupdated and doing math up til now. to add that number of days to the time. 
        
        //popup for a message about why your moving it 
        // if pending to progress default message - starting work
        //if progress to techhold - pausing work
        //if techhold to progress

    }
    private sortTicketColumn(column: string) {
        this.tickets[column].sort((a, b) => {
            return a.daysOpen < b.daysOpen ? 1 : a.daysOpen > b.daysOpen ? -1 : 0;
        });
        this.tickets[column].sort((a, b) => {
            return a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0;
        });
    }

    leaveTicketMessage(ticket){
        
    }
}