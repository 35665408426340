import { CommonModule } from '@angular/common';
import { Component, Input, Inject, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

@Component({
    selector: 'textnumber-dialog',
    templateUrl: './textNumberPopUp.component.html',
    styleUrls: ['./textnumber.scss']
})
export class TextNumberPopUpDialogComponent{

    public message = "";
    public title = "";
    public subtitle = null;
    public confirm = false;
    public standard = true;
    public placeholder = "Text Here";
    public value = "";
    public type = "number";
    public customOptions;
    @ViewChild('inputArea') inputArea:ElementRef;

    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<TextNumberPopUpDialogComponent>, private toasty:Toasty){
        
        this.message =  data.message || "";
        this.title = data.title || "";
        this.type = data.type || "number";
        this.placeholder = data.placeholder || this.placeholder;
        this.value = data.value || this.value;

    }
    setDefaultValue($event){
        $event.root.innerHTML = this.value;
    }

    closeTrue(){
        this.dialogRef.close(this.inputArea.nativeElement.value);
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}

export interface TextNumberPopUpDialogOptions{
    message;
    title?;
    subtitle?;
    placeholder?;
    value?;
    type?;
}