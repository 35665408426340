import { ConfirmationDialogComponent } from './../../shared/confirmation/confirmation.component';
import { Subject } from 'rxjs';
import { NewTicketDialog } from './../../singleTicket/newTicket/newTicket.component';
import { TicketActions } from './../../singleTicket/ticketActionsEnum';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort, DialogPosition } from '@angular/material';
import { TicketService, UserService, TicketStatus, Ticket } from 'app/KanbanService';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { AuthenticatedUser } from 'app/shared/authenticatedUser.service';
import { TicketListService } from 'app/shared/ticketListService';
import { ActivatedRoute, Router } from '@angular/router';
import { SingleTicketView } from 'app/singleTicket/singleTicket.component';
import { ListPhasesRequest, TechDeliverableService, WorkReadyStatus, ProductService, DeliverableService, ToDoService } from '@brafton/deliverables-client';
import { take } from 'rxjs/operators';

import * as moment from 'moment';
import { TaskType } from 'app/KanbanService/models/enums/TaskTypes';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';
import { TicketChanges } from '../../singleTicket/previewTicket/previewTicket.component';
import { ContactServiceModule } from '@brafton/core-api-client';
import { ATLASMAP } from 'app/shared/utils';
@Component({
    selector: 'unassigned-tickets',
    templateUrl: './unassignedTickets.component.html',
    styleUrls: ['./unassignedTickets.scss']
})
export class UnassignedTicketsComponent{
    displayedColumns = ['account','subject', 'workType','workCategory', 'unitsAllocated', 'Actions'];
    dataSource: MatTableDataSource<any>;
    phaseDisplayedColumns = ['account', 'phase', 'assigned', 'end', 'units', 'actions'];
    phaseDataSource: MatTableDataSource<any>;
    @ViewChild(MatSort) sort: MatSort;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    public unassignedTicketList:Ticket[] = [];
    public phaseList = [];
    public loadingDeliverables = false;
    public ATLASMAP = ATLASMAP;
    public WorkReadyStatus = WorkReadyStatus;
    constructor(        
        public dialog: MatDialog,
        private ticketService:TicketService, 
        private userService:UserService,
        private bfAuth:BfAuthService,
        private authenticatedUser:AuthenticatedUser,
        private _ticketList:TicketListService,
        private _activatedRoute:ActivatedRoute,
        private _router:Router,
        private techDeliverables:TechDeliverableService,
        private productService:ProductService,
        private todoService:ToDoService,
        private deliverablesService:DeliverableService){
            this._ticketList.listTicketsAsync([TicketStatus.open]).pipe(take(1)).subscribe(
                unassignedTickets=>{
                    this.unassignedTicketList = unassignedTickets;
                    this.dataSource = new MatTableDataSource<Ticket>(this.unassignedTicketList);
                    // this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.getAtlasPhases();
                }
            );
            this._ticketList.subscribeToTickets().subscribe(
                ticket=>{
                    this.unassignedTicketList.push(ticket);
                    this.dataSource.data = this.unassignedTicketList;
                }
            )

            
    }
    
    getAtlasPhases(){
        //@todo abstract out a bit more to allow for large swatches of work that are ready... use pagination to page through list of actionable work and add in date toggle to select old and future work and also to see work that is pending as well. 
        //use moment().utc().format(); for the date now.
        // let phaseRequest:ListPhasesRequest = { //https://github.com/brafton/brafton-deliverables/blob/develop/src/Brafton.Deliverables.Client.Angular/src/enumeration-type/enumeration.types.ts#L663
        //     // workStatusTypes: "ReadyToWorkOn",
        //     workStatusTypes: [WorkReadyStatus.ReadyToWorkOn.id, WorkReadyStatus.Schedule.id, WorkReadyStatus.ScheduleHoldReady.id].toString(),
        //     page: 1,
        //     pageSize: 40,
        //     fromDate: (moment().startOf('month').subtract(1, 'month').unix() * 1000).toString(),
        //     toDate: (moment().endOf('month').utc().unix() * 1000).toString(),
            
        // }
        // console.log(phaseRequest);
        // this.loadingDeliverables = true;
        // this.techDeliverables.ListPhasesAsync(phaseRequest)
        
        this.todoService.ListPhasesAsync(1,50,null,null,[WorkReadyStatus.ReadyToWorkOn, WorkReadyStatus.Schedule, WorkReadyStatus.ScheduleHoldReady], null,null, (moment().startOf('month').subtract(1, 'month').unix() * 1000).toString(),(moment().endOf('month').add(1, 'month').utc().unix() * 1000).toString(),null,null,'EndDateAscending', null, null, '10').subscribe(
                phases=>{
                    console.log(phases);
                    var actionable = phases.items;
                    this._ticketList.listTicketsAsync().subscribe(tickets=>{
                        let toCreate = actionable.filter(phase=>{ 
                            let index = tickets.findIndex(tix=>{

                                let validTix = phase.parentProject.id==tix.atlasId;
                                if(!validTix){
                                    return false;
                                }
                                validTix = false;
                                if(tix.tasks && tix.tasks.findIndex(task=>task.atlasPhaseId==phase.id) > -1){
                                    validTix = true;
                                }else{
                                    phase['hasOpenProject'] = tix.id;
                                }

                                return validTix;
                            })
                            return index < 0;
                        })
                        this.phaseList = toCreate;
                        this.loadingDeliverables = false;
                        console.log(this.phaseList);
                        this.phaseDataSource = new MatTableDataSource<Ticket>(this.phaseList);
                    })
                    //loop through the tickets and check for items that are not already created.
                    

                }
            )

    }
    findMaster(project){
        if(project.module.id == "MasterProject"){
            return project;
        }
        if(project.parentProject){
            return this.findMaster(project.parentProject);
        }
        return null;
        
        
    }
    determineUrl(projectPhase){
        
        if(
            projectPhase.parentProject.module.id == "MasterProject" || 
            projectPhase.parentProject.module.id == "InternalProject" ||
            projectPhase.parentProject.parentProject.module.id == "InternalProject" ||
            projectPhase.parentProject.parentProject.module.id == "MasterProject"){
                
                /*
                parentphase.parentproject.id is the id
                parentModule.parentProject.module parentphase.parentproject.parentproject.id is the id
                projects/{id}
                */
                var master = this.findMaster(projectPhase.parentProject);
                return `https://atlas.brafton.com/projects/${master.id}`;
            }
            return `https://atlas.brafton.com/produce/productions/${projectPhase.parentProject.id}`;
    }
    determinePhaseName(phase){
        var nameparts = [];
        if(phase){
            
            var master = this.findMaster(phase);
            

            if(master){
                nameparts.push(master.name);
            }
        }
        nameparts.push(phase.parentProject.name);
        nameparts.push(phase.parent.name);
        nameparts.push(phase.parent.parent.name);
        nameparts.push(phase.name);
        nameparts = nameparts.filter((item,index)=>{
            
            return nameparts.indexOf(item) === index;
        })
        return nameparts.join(' ');
    }
    removeTicketFromList(data){
        let ticket = data.ticket;
        console.log(data, ticket);
        if(ticket.status !== TicketStatus.open){
            let index = this.unassignedTicketList.findIndex(tix=>tix.id ==ticket.id);
            console.log(index);
            if(index > -1){
                this.unassignedTicketList.splice(index, 1);
                this._ticketList.updateTicket(ticket);
                this.dataSource.data = this.unassignedTicketList;
                console.log(this.unassignedTicketList);
            }
        }
    }
    updateBoard($event:TicketChanges, ticket:Ticket){
        console.log($event, ticket);
        console.log(ticket.status, TicketStatus.open);
        if($event.action == TicketActions.DeletedTicket){
            console.log('delete me');
            let index = this.unassignedTicketList.findIndex(_tix=>_tix.id===ticket.id);
                if(index > -1){
                    this.unassignedTicketList.splice(index, 1);
                    this.dataSource.data = this.unassignedTicketList;
                    this._ticketList.updateTicket(ticket, true);
                }
        }else if($event.ticket.status !== TicketStatus.open){
            console.log($event.ticket.status, TicketStatus.open);
            this.removeTicketFromList({ticket: $event.ticket});
        }else{
            console.log('fell into hole');
        }
    }
    showTicket(ticket, appendPhase = false){ //@todo if appendPhase than i need to show open tasks when i open the ticket and remov ethe phase when im done.
        console.log(ticket);
        var previousTicketStatus = ticket.statusName();
        // this.ticketOpen = true;
        let pos:DialogPosition = {
            right: "0",
            // bottom: "0",
            // top: "69px"
        }
        let openData = {
            ticket: ticket,
            phase: appendPhase || null
        }
        const dial = this.dialog.open(SingleTicketView, {
            position: pos,
            data: openData,
            width:"60%",
            // height: "91%",
            panelClass: "ticket-details"
        });
        dial.afterClosed().subscribe((tix:Ticket)=>{
            if(!tix){ //ticket was deleted
                console.log(tix);
                let index = this.unassignedTicketList.findIndex(_tix=>_tix.id===ticket.id);
                if(index > -1){
                    this.unassignedTicketList.splice(index, 1);
                    this.dataSource.data = this.unassignedTicketList;
                    this._ticketList.updateTicket(ticket.id, true);
                }
            }else if(tix.status !== TicketStatus.open){
                
                this.removeTicketFromList({ticket:tix});
            }
            if(appendPhase){
                //remove phase from listing.
            }
        })
    }
    checkForOpenAtlasProjectTicket(phase){
        
    }
    openExistingTicket(phase){
        console.log(phase);
        var ticket = this._ticketList.listTickets().find(tix=>tix.id==phase.hasOpenProject);
        if(ticket){
            console.log(ticket);
            this.showTicket(ticket);
        }
    }
    addPhaseToExistingTicket(phase){
        var ticket = this._ticketList.listTickets().find(tix=>tix.id==phase.hasOpenProject);
        console.log(ticket, phase);
        var phasename = this.determinePhaseName(phase);
        this.dialog.open(ConfirmationDialogComponent, {
            width: "50%",
            data:{
                title: `Add Phase to open ticket`,
                subtitle: `${phasename}`,
                message: `${ticket.subject} is already open. Do you want to add the phase "<i>${phasename}</i>" to the open ticket and assign to the active developer ${ticket.developer.name}`
            }
        }).afterClosed().subscribe(
            confirmation=>{
                if(confirmation){
                    console.log(phase);
                    let assignedUser = phase.owner.ownerType.id == "Person"? phase.owner : null;
                    if(assignedUser && assignedUser.$id){
                        delete assignedUser.$id;
                        delete assignedUser.team;
                    }
                    var task:_TaskCreateModel = {
                        title: this.determinePhaseName(phase),
                        completed: false,
                        atlasPhaseId: phase.id,
                        allocatedUnits: phase.unitsInTotal.availableUnits,
                        locked: false,
                        assignedUser: assignedUser,
                        taskType: TaskType.atlas
                        
                    }
                    console.log(task);
                    this.ticketService.createNewTask(ticket.id, task).subscribe(
                        phase =>{
                            if(!ticket.tasks){
                                ticket.tasks = [];
                            }
                            ticket.tasks.push(phase);
                            this._ticketList.updateTicket(ticket);
                            this.showTicket(ticket);
                            //remove phase from atlas listing
                            this.getAtlasPhases();
                        }
                    )
                    
                }
            }
        )
    }
    convertPhaseToTicket(phase){
        //@todo add popup if project already has an open ticket ask if you want to add this phase to the open ticket or create a new one.
        var ticket = this._ticketList.listTickets().find(tix=>tix.id==phase.hasOpenProject);
        this.deliverablesService.GetAsync(phase.parentProject.id, false).subscribe(
            project=>{
                // console.log(project);
                this.dialog.open(NewTicketDialog, {
                    data:{
                        atlasProject: project,
                        atlasPhase: phase,
                        existingTicket: ticket? ticket : null
                    }
                }).afterClosed().subscribe(newTicket=>{
                    if(newTicket){
                        //need to add new ticket to the list but also resort through the phases to ensure we remove any that just got a ticket (for tickets that get more than 1 phases)
                        try{
                            var phaseIds = newTicket.tasks.map(task=>task.atlasPhaseId);
                            for(var _id of phaseIds){
                                let _phaseIndex = this.phaseList.findIndex(_phase=>_phase.id==_id);
                                if(_phaseIndex > -1){
                                    this.phaseList.splice(_phaseIndex, 1);
                                }
                            }
                            this.phaseDataSource.data = this.phaseList;
                        }catch(e){
                            console.log(e);
                        }
                    }


                })
            }
        )

        
    }
    //@todo Need to modify the move functionality to force selection of a developer first.
}