import { User } from "./User";

export class Asset{
    name?:string;
    description:string;
    parentId:string;
    reference?:string;
    type :string;
    createdBy:User;
    constructor(Asset?){
        Object.assign(this, Asset);
    }
}