import { CoreApiAccountService } from 'app/services/core/core-api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
@Component({
    selector: 'new-task-dialog',
    templateUrl: './newTask.component.html',
    styleUrls: ['../../singleTicket/newTicket/newTicket.scss','./newTask.scss']
})
export class NewTaskDialog{
    public overallocation = false;
    public currentUsers = [];
    private selectedUser;
    public query = "";
    public taskFormGroup:FormGroup
    public presets = {
        title: '',
        units: 0,
        assigned: null,
        unitsAvailable: 0
    };
    public pageTitle: "Create Task";
    constructor(
        @Inject(MAT_DIALOG_DATA) data, 
        private dialog:MatDialogRef<NewTaskDialog>,
        private formBuilder:FormBuilder,
        private coreApi:CoreApiAccountService){
            if(data){
                let tmpUnits = 0
                this.presets.unitsAvailable = (tmpUnits = parseInt(data.unitsAvailable)) >= 0? tmpUnits: 0;
                if(data.title){
                    this.presets.title = data.title;
                    this.presets.units = data.units;
                    this.presets.assigned = data.assigned;
                }
                this.pageTitle = data.pageTitle || this.pageTitle;
            }
    }

    ngOnInit(){
        this.taskFormGroup = this.formBuilder.group({
            title: [''],
            units: [''],
            assigned: ['']
        });
        if(this.presets.title != ''){
            this.taskFormGroup.get('title').setValue(this.presets.title);
            this.taskFormGroup.get('units').setValue(this.presets.units);
            this.taskFormGroup.get('assigned').setValue(this.presets.assigned);
        }
    }
    /**
     * Ensure we have enough units to allocate
     * @param $e event
     */
    calculateUnits($e){
        let desiredUnits = parseInt($e.target.value);

        if(desiredUnits > this.presets.unitsAvailable){

            // $e.target.value = this.presets.unitsAvailable;
            this.taskFormGroup.get('units').setValue(this.presets.unitsAvailable);
            this.overallocation = true;
            console.log(this.overallocation);
            return;
        }
        this.overallocation = false;
    }
    /**
     * user search in core api for assignee
     * @param $e event
     */
    findUsers($e){
        
        if($e.key == "Tab"){
          
        }else if($e.key == "ArrowUp" || $e.key == "ArrowDown"){

        }else{
            let q = this.query;
            this.query = $e.target.value;
            if(this.query.length > 2 && q !== this.query){
                this.coreApi.listUserByQuery(this.query).pipe(map(_userList=>{
                    return _userList.filter(u=>u.type.id == 1);
                })).subscribe(
                    userList=>{
                        console.log(userList);
                        this.currentUsers = userList;
                    }
                )
            }
        }
    }
    userSelected($e){
        console.log($e);
        this.selectedUser = $e.option.value;
    }
    displayUser(user){
        return user? user.name: "";
    }
    submitTask(){
        if(this.taskFormGroup.invalid){
            return;
        }
        let task = {
            title: this.taskFormGroup.get('title').value,
            allocatedUnits: this.taskFormGroup.get('units').value || 0,
            assignedUser: this.taskFormGroup.get('assigned').value || null
        }
        this.dialog.close(task);
    }
}