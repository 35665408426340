import { CommonModule } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';

@Component({
    selector: 'phase-completed-dialog',
    templateUrl: './phaseCompleted.component.html',
    styleUrls: ['./phaseCompleted.scss']
})
export class PhaseCompletedDialogComponent{

    public message = "";
    public title = "";
    public subtitle = null;
    public confirm = false;
    public standard = true;
    public partial = false;
    public totalUnits = 0;
    public TotalPercent = 0;
    public customOptions;
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<PhaseCompletedDialogComponent>, private toasty:Toasty){
        
        this.message =  data.message || "";
        this.title = data.title || "";
        this.confirm = data.confirm || false;
        this.subtitle = data.subtitle || null;
        this.totalUnits = data.totalUnits || 0;

        if(data.customOptions){
            this.standard = false;
            this.customOptions = data.customOptions;
        }
    }
    ngOnInit(){
        console.log(this.totalUnits);

    }
    updateTotal($event){
        console.log($event);
        this.TotalPercent = $event.value;
        
        console.log(Math.round(this.totalUnits * (this.TotalPercent / 100)));
    }
    closeTrue(){
        var units;
        var dialogReturn;
        if(!this.partial){
            this.dialogRef.close(true);
        }else{
            try{
                units = Math.round(this.totalUnits * (this.TotalPercent / 100));
                if(this.totalUnits - units < 1){
                    units = Math.floor(this.totalUnits - 1);
                }
                console.log(units);
            }catch(e){
                units = false;
            }
            this.dialogRef.close({
                totalUnits: units
            });
        }

    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}