import { User } from "./User";
import { _Comment } from "../interfaces/responseModels/_Comment";

// export interface Comment{
//     date:number;
//     message:string;
//     subject?:string;
//     from:User;
//     to?:User;
//     projectId:string;
// }
export class Comment extends _Comment{
    constructor(comment?){
        super();
        Object.assign(this, comment);
    }
}