import { CommonModule } from '@angular/common';
import { Component, Input, Inject, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Toasty } from 'app/shared/toastNotifications/toasty.service';
// import { QuillEditorComponent } from 'ngx-quill-editor/quillEditor.component';
import { QuillEditorComponent } from 'ngx-quill';
import { QuillOptionsStatic, Quill } from 'quill';
import {map} from 'rxjs/operators';

import 'quill-mention';
import { CoreApiAccountService } from 'app/services/core/core-api.service';

@Component({
    selector: 'textarea-dialog',
    templateUrl: './textAreaPopUp.component.html',
    styleUrls: ['./textarea.scss']
})
export class TextAreaPopUpDialogComponent{
    public simpleInput = false;
    public message = "";
    public title = "";
    public subtitle = null;
    public confirm = false;
    public standard = true;
    public placeholder = "Text Here";
    public users = [];
    public value = "";
    public customOptions;
    // @ViewChild('textarea') textarea;
    @ViewChild('quillEditor') quillEditor:QuillEditorComponent;
    @ViewChild('simpleInput') inputText:ElementRef;
    public quillOptions:QuillOptionsStatic = {};
    public modules = {
        mention: { //for the @mention functionality
          //@todo need to build into source for hitting the profile api endpoint on core api
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            dataAttributes: ["email"],
            spaceAfterInsert: true,
            onSelect: (item, insertItem) => {
              const editor = this.quillEditor.quillEditor as Quill
              insertItem(item)
              // necessary because quill-mention triggers changes as 'api' instead of 'user'
              editor.insertText(editor.getLength() - 1, '', 'user')
            },
            source: (searchTerm, renderList) => {
              const matches = [];
              this.users.forEach((entry) => {
                // console.log(entry);
                if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(entry)
                }
              })
              renderList(matches, searchTerm)
            }
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
        
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
        
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
        
            ['clean'],                                         // remove formatting button
        
            ['link', 'image', 'video']    
          ]
    }
    constructor(@Inject(MAT_DIALOG_DATA) public data:any, private dialogRef:MatDialogRef<TextAreaPopUpDialogComponent>, private toasty:Toasty, private coreApi:CoreApiAccountService){
        
        this.message =  data.message || "";
        this.title = data.title || "";
        this.subtitle = data.subtitle || null;
        this.placeholder = data.placeholder || this.placeholder;
        this.value = data.value || this.value;
        this.simpleInput = data.simpleInput || this.simpleInput;
        if(data.users){
          this.users = data.users.filter(_user=>{
              return _user.id;
          }).map(_raw=>{
                _raw.value = _raw.name? _raw.name :_raw.firstName + ' ' + _raw.lastName;
                _raw.email = _raw.username;
                return _raw;
              }) || [];
          }
    }
    setDefaultValue($event){
        $event.root.innerHTML = this.value;
    }

    closeTrue(){
        if(this.simpleInput){
            this.dialogRef.close(this.inputText.nativeElement.value);
        }else{
          var isBlank = this.quillEditor.quillEditor.root.innerText.trim();
          var totalValue = null;
          if(isBlank){
            totalValue = this.quillEditor.quillEditor.root.innerHTML
          }
         this.dialogRef.close(totalValue);
        }
    }
    closeFalse(){
        this.dialogRef.close(false);
    }
}

export interface TextAreaPopUpDialogOptions{
    message;
    title?;
    subtitle?;
    placeholder?;
    value?;
    singleInput?:boolean;
};