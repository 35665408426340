import { WorkCategory } from './../../KanbanService/models/responseModels/WorkCategory';
import { WorkService } from './../../KanbanService/services/work.service';
import { Component, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { WORKTYPE } from 'app/KanbanService/models/enums/WorkType';
import { WorkCategoryForm } from './workCategory.form.component';
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from 'app/shared/confirmation/confirmation.component';

@Component({
    selector: 'work-categories',
    templateUrl: `./views/workCategory/workCategory.html`,
    styleUrls:[`./views/workCategory/workCategory.scss`]
})
export class WorkCategoryComponent{
    displayedColumns = ['name', 'description','workType', 'min', 'actions'];
    dataSource: MatTableDataSource<any>;
    WORKTYPE = WORKTYPE;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(private workService: WorkService, private dialog:MatDialog){
        this.dataSource = new MatTableDataSource<Element>([]);

    }
    ngOnInit(){
        this.workService.listWorkCategories().subscribe(cats=>{
            this.dataSource = new MatTableDataSource<Element>(cats);
            this.dataSource.paginator = this.paginator;

        })
    }
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    createWorkCategory(){
        this.formPopup();
    }
    formPopup(cat = null){
        let data = {};
        if(cat){
            data['workCategory'] = cat;
        }
        this.dialog.open(WorkCategoryForm, {
            data,
            width: '700px'
        }).afterClosed().subscribe(
            _workCat=>{
                if(_workCat){
                    if(cat){
                        let index = this.dataSource.data.findIndex(
                            _cat=>{
                                return _cat.id==_workCat.id
                            });
                        this.dataSource.data[index] = _workCat;
                    }else{
                        this.dataSource.data.push(_workCat);
                    }
                    this.dataSource._updateChangeSubscription();
                }
            }
        )
    }
    pageEvent($event){

    }
    altEvent($event){
        
    }
    editWork(workCategory){
        this.formPopup(workCategory);
    }
    deleteWork(workCategory){
        let conf:ConfirmationDialogOptions = {
            message: "Are you sure you want to delete this workType. It will no longer be available."
        }
        conf.title = "Delete Work Category";
        this.dialog.open(ConfirmationDialogComponent, {
            data: conf
        }).afterClosed().subscribe(
            response=>{
                console.log(response);
                if(response){
                this.workService.deleteWorkCategory(workCategory.id).subscribe(
                    res=>{
                        console.log(res);
                    }
                )
                }
            }
        )
    }
  }