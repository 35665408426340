import { WorkType } from './../../enums/WorkType';
import { User } from "../../responseModels/User";
import { Department, TicketStatus, TicketWorkType, TicketPriority } from "../../enums";
import { Asset } from "../../responseModels/Asset";
import { Task } from "../../responseModels/Task";

export abstract class _Ticket {
  account: Array<any>;
  feed?:Object;
  id: string;
  accountManager: User;
  developer: User;
  stakeholders: Array<User>;
  department: Department;
  paymentType: string;
  pricePaid?: number;
  unitsAllocated?: number;
  status: TicketStatus;
  workType: string;
  workCategory: string;
  subject: string;
  scope: string;
  assets?: Array<Asset>;
  daedelusId?: string;
  atlasId?:string;
  atlasModule?:string;
  atlas?:{id:string,module:string,name:string,notes?:string}
  comms?: Array<Comment>;
  time: Timespent;
  daysOpen: number;
  leadTime: number;
  cycleTime: number;
  lastModifiedBy: User;
  lastModifiedDate: number;
  createdDate: number;
  dueDate?: number;
  closedDate?: number;
  createdBy: User;
  salesforceId?: string;
  trelloId?:string;
  escalated: boolean;
  priority: TicketPriority;
  swimmer: boolean;
  unresponsive: boolean;
  projectId?: string;
  isQuote: boolean;
  quoteConvertedToWork?: boolean;
  quotedWorkTicket?: boolean;
  quoteTicketId?: string;
  quoteDollar?: number;
  quoteUnits?: number;
  quoteHours?: number;
  quotedDifferenceHours?: number;
  quoteScope?: string;
  hoursDifference?: number;
  lastMoved: object;
  moveHistory: Array<any>;
  unitsAdjusted: number;
  tasks?: Array<Task>;
  parentTicketId: string;
  tags?:Array<string>;
}

export interface Timespent{
  open?;
  design?;
  devReady?;
  pending?;
  progress?;
  techHold?;
  woam?;
  approval?;
  closed?;
}