import { EventService } from './../../shared/event.service';
import { TicketListService } from '../../shared/ticketListService';
import { Department } from '../../KanbanService'

import { AuthenticatedUser, PermissionSet } from '../../shared/authenticatedUser.service'
import { ConfirmationDialogComponent, ConfirmationDialogOptions } from '../../shared/confirmation/confirmation.component';
import { BfAuthService } from '@brafton/skynet-angular-security-service';
import { TicketService, UserService, User } from '../../KanbanService'

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs';
import { MatDialog, DialogPosition } from '@angular/material';
import { TICKETSTATUS, TicketStatus, Ticket } from '../../KanbanService';

import { SingleTicketView } from '../../singleTicket/singleTicket.component';
import { daysBetween } from '../../shared/utils';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError, ParamMap } from '@angular/router';
import { TicketBoardInterface } from '../ticketBoard.component';
import { map, filter, switchMap, distinct } from 'rxjs/operators';
@Component({
    moduleId: module.id,
    selector: 'main-view',
    templateUrl: './allTickets.component.html',
    styleUrls: ['./allTickets.scss']

})
export class AllTicketsComponent extends TicketBoardInterface implements OnInit, OnDestroy{
    public showFilters = false;
    
    constructor(
        dialog: MatDialog,
        ticketService:TicketService, 
        userService:UserService,
        bfAuth:BfAuthService,
        authenticatedUser:AuthenticatedUser,
        _ticketList:TicketListService,
        _activatedRoute:ActivatedRoute,
        _router:Router,
        private _eventService:EventService
    ){
        super(dialog, ticketService,userService,bfAuth,authenticatedUser, _ticketList, _activatedRoute,_router);
        
        // this._router.url
        this.subs.push(this._router.events.
            pipe(filter((e)=>e instanceof NavigationEnd)).
            pipe(distinct()).
            subscribe(
            (event:NavigationEnd)=>{
                // console.log(event.url);
                let sort = event.url.split('/');
                // console.log(sort);
                //make stakeholderEmail an array????
                
                let parameters = {
                    status: null,
                    stakeholderEmail: null
                };
                let valid = sort[sort.length -1];

                switch(valid){
                    case 'team': //@todo setup team view
                    // Check if the user has the viewTeam permission set
                    // Check if the user has teamId of 10 (dev) they can see all tech tickets
                    // Get a list of the users in the current users team and view all tickets with that user as the stakeholder.
                    
                    this.sortParameters = 'team';
                    console.log("My teams tickets");
                    break;
                    case 'all':
                    // parameters.stakeholderEmail = this.authenticatedUser.currentUser.email;
                    this.sortParameters = 'all';
                    // check if the user is allowed to see all the tickets and if not set it for mine
                    let canSeeAll = this.authenticatedUser.checkPermission([PermissionSet.readAllTickets]);
                    if(canSeeAll){ // @todo do i want to allow a user ot see their own tickets on the All screen or display a message rather that they don't have permission???
                        // console.log(" I get to see it all");
                        break;
                    }
                    break;
                    default:
                    // console.log("default just see my tickets");
                    this.parameters.stakeholderId = this.authenticatedUser.currentUser.id;
                    this.sortParameters = 'mine';
                    break;
                }
                this.setupColumns();
                
                this.retreiveTickets(this.parameters.status, this.parameters.stakeholderId);
            }
        ));
    }
    ngOnInit(){        
        
        this.setupColumns();
        let status = null;
        let stakeholderEmail = null;
        let accountId = null;
        this._activatedRoute.params.subscribe(
            param=>{
                if(param.ticketId){
                    this._ticketList.listTicketsAsync().subscribe(tickets=>{
                        let tix = tickets.find(t=>t.id==param.ticketId);
                        // this.showTicket(tix);
                        //@todo need to rework how navigation to a a single ticket url happens
                    })
                }
            }
        )
        //get tickets list constraint

        //Are you an Admin (are you an admin list all tickets)

        //Are you a DevAdmin (are you an admin list all tickets but you have more abilities)

        //If none of the above just list tickets you are a stakeholder of
        // if(not admin or devadmin){
        //     stakeholderEmail = this.authenticatedUser.currentUser.email;
        // }
       
        // this.retreiveTickets(status, stakeholderEmail, accountId);
    }
    ngOnDestroy(){
        this.subs.forEach(sub=>{
            sub.unsubscribe();
        })
    }    

    
}