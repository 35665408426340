import { AccountCreateModel } from './../models/requestCreateModels/AccountCreateModel';
import { DataService } from './../shared/data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Account } from '../models/responseModels/Account';

@Injectable()
export class AccountService extends DataService {
    // constructor(_http:Http) {
    //     super(_http);
    // }
    listAccounts():Observable<Array<Account>>{
        let url = `accounts`;
        return this.makeGetRequest(url)
            .pipe(map( (accounts)=>{
                return accounts.map(account=>{
                    return new Account(account);
                })
            }))
    }
    createAccount(account:AccountCreateModel):Observable<Account>{
        let url = `accounts`;
        return this.makePostRequest(url, account)
            .pipe(map( (accounts)=>{
                return new Account(accounts[0])
            }))
    }

    getAccountById(accountId):Observable<Account>{
        let url = `accounts/${accountId}`;
        return this.makeGetRequest(url)
            .pipe(map( (accounts)=>{
                return new Account(accounts[0])
            }))
    }
}
