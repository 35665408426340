import { PermissionSet } from 'app/shared/authenticatedUser.service';
import { Toasty } from './../../shared/toastNotifications/toasty.service';
import { unitsToHours } from './../../shared/utils';
import { AccountCreateModel } from './../../KanbanService/models/requestCreateModels/AccountCreateModel';
import { TicketListService } from './../../shared/ticketListService';
import { CoreApiAccountService } from './../../services/core/core-api.service';
import { AuthenticatedUser } from './../../shared/authenticatedUser.service';

import { MAT_DIALOG_DATA, MatDialogRef, MatHorizontalStepper, MatDialog, MatTableDataSource } from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { TicketStatus, TicketPriority, Ticket, TicketService, TicketCreateModel, User, Department, Contract, Unpaid, Rolling, AccountService, WorkService, WorkCategory } from '../../KanbanService';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { ConfirmationDialogComponent } from '../../shared/confirmation/confirmation.component';
import { TextAreaPopUpDialogComponent } from 'app/shared/textareaPopUp/textAreaPopUp.component';
import { WORKTYPE, WorkType } from 'app/KanbanService/models/enums/WorkType';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';
import { Observable, forkJoin, Subject } from 'rxjs';
import { mergeMap, flatMap, takeUntil, take } from 'rxjs/operators';
import * as moment from 'moment';
import { TaskType } from 'app/KanbanService/models/enums/TaskTypes';
import { FileUploadComponent } from 'app/shared/fileUploader/fileUpload.Component';
// import { tickets } from '../../KanbanService/test/ticketData';

@Component({
    selector: 'new-ticket-dialog',
    templateUrl: './newTicket.component.html',
    styleUrls: ['./newTicket.scss']
})
export class NewTicketDialog{
    private destroySubs$ = new Subject<boolean>();
    isLinear = false;
    wasQuoted = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    private ownerAccountManager = null;
    public searchByField = 'feed';
    public filteredQuoteTickets = [];
    public quoteTickets = [];
    public activeClients = [];
    public activeFeeds = [];
    public internalAccounts = [];
    public workTypes = WORKTYPE;
    public formGroup:Array<FormGroup> = [];
    public quillOptions = {
        placeholder: "Ticket Scope"
    }
    public workCategories = [];
    public isProspect = false;
    public isAllowedToOpenPaid = true;
    @ViewChild('stepper') stepper:MatHorizontalStepper;
    public dummyAccounts = [];
    private taskList = [];
    public phaseList = [];
    public userIdList = [];
    public userProfiles = [];
    public files = [];
    public requirementList = []; 
    public dueDateEligible = false;
    public ticketValues:Ticket;
    public catObj;
    public invalidFields = [];
    public dateError = false;
    public minDaysToCompletion = 0;
    public atlasProject = null
    public atlasActivePhase = null
    public existingTicket = null;
    public dataSource:MatTableDataSource<_TaskCreateModel>;
    public clientListingDataSource:MatTableDataSource<any>;
    public displayedColumns = ["select", "title", "allocatedUnits", "assignedUser"];
    public accountListingDisplayedColumns = ["select", "brand","feed", "account"];
    public selection = new SelectionModel<any>(true, []);
    public clientSearchText = "";
    public myFilter = (d: Date): boolean => {
        const day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
      }

    public creatingTicket = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) data, 
        public popUp: MatDialog,
        private dialog:MatDialogRef<NewTicketDialog>, 
        private _formBuilder:FormBuilder, 
        private ticketService:TicketService,
        private authenticatedUser:AuthenticatedUser,
        private coreApiService: CoreApiAccountService,
        private _ticketListService:TicketListService,
        private _kanbanAccountService:AccountService,
        private workService:WorkService,
        private toasty:Toasty
    ){
        if(data.atlasProject){
            this.atlasProject = data.atlasProject;
            //this.atlas has projectId top level. phases contains ALL phases so i need to filter just the module.id == "Tech" for tech phases.... if only 1 returned than just confirm the phase during creation. If more than one that add a "Task" for each tech phases and confirm.
            this.atlasActivePhase = data.atlasPhase;
            // console.log("project",this.atlasProject,"phase", this.atlasActivePhase);
            this.existingTicket = data.existingTicket;
            console.log(this.existingTicket);
            /**
             * Need to ask if just converting this phase into work (comerica or one off pieces) or the entire project.
             * 
             * If the project need to loop through the phases filter out just the tech module, and type of "work" if type is not work looper through that phases - phases looking for "work"
             * 
             * get a list of all type: work, phases for this project and add them all to the ticket.
             */
            

        }
    }
    ngOnInit(){
        this.formGroup = [
            this.firstFormGroup = this._formBuilder.group({
                account: ['', Validators.required],
                feed: [''],
                department:['Tech']
            }),
            this.secondFormGroup = this._formBuilder.group({
                projectScope: ['', Validators.required],
                projectTitle: ['', Validators.required]
            }),
            this.thirdFormGroup = this._formBuilder.group({
                ticketUnits: [''],
                ticketCost: [''],
                quoteTicket: ['']
            }),
            this.fourthFormGroup = this._formBuilder.group({
                workType: ['', Validators.required],
                workCategory: ['', Validators.required],
                dueDate: []
            })
        ]
          //get list of quote tickets for this account @todo need to get all tickets from the api not from listservice
          this._ticketListService.listTicketsAsync().subscribe(tickets=>{
            this.quoteTickets = tickets.filter(t=>t.isQuote);
          });
          if(this.atlasProject){
          //need to also attempt to prefill all relevant information
            //account
            this.coreApiService.getAccountInfo(this.atlasProject.feedDetails.accountId).subscribe(
                account=>{
                    if(account.businessUnit.id == 7){
                        account._brand = "CF";
                    }else{
                        account._brand = 'BR';
                    }
                    // this.firstFormGroup.get('accountAccount').setValue(account);
                    this.firstFormGroup.get('account').setValue(account);
                }
            )
            //Ticket title.
            //ticket scope
            //units
          }
          //get list of dummy acounts and git accounts this.internalAccounts
          
          if(!this.authenticatedUser.checkPermission([PermissionSet.isTechTeam])){
            this.isAllowedToOpenPaid = false;
            this.workTypes = [WORKTYPE[WorkType.Unpaid]];
          }
    }
    determineAtlasType($event){
        var accountName = "";
        var title = "";
        var scope = "";
        var phases = [];
        var allocatedUnits = 0;
        var atlasId = null;
        var atlas = {
            id: null,
            module: null,
            name: null,
            notes: null
        }
        var userList = [];
        try{
            if($event.value == "phase"){
                console.log(this.atlasActivePhase);
                title = this.atlasActivePhase.parentProject.name + ": "+ this.atlasActivePhase.parent.name;
                accountName = this.atlasActivePhase.feedDetails.feedName;
                //@todo can't concatante 2 null values
                
                let parentScope = (this.atlasActivePhase.parentProject.notes? this.atlasActivePhase.parentProject.notes: 'See Atlas Brief Document') || '';
                let phaseScope = this.atlasActivePhase.notes || '';
                
                if(phaseScope){
                    phaseScope += '<br/>';
                }
                scope = phaseScope.replace(/(?:\r\n|\r|\n)/g, '<br>')+ ' '+ parentScope.replace(/(?:\r\n|\r|\n)/g, '<br>');
                if(this.existingTicket){
                    title = "Peer Review: " + title;
                    scope = 'Review the following Scope of work : \n\n' + scope; 
                    console.log(title, scope);
                    
                }
                
                allocatedUnits = this.atlasActivePhase.unitsInTotal.availableUnits;
                // this.fourthFormGroup.get('dueDate').setValue(moment(this.atlasActivePhase.availableEnd.date).unix());
                console.log("the phase", this.atlasActivePhase);
                var task:_TaskCreateModel = {
                    title: this.atlasActivePhase.parent.name + ": "+this.atlasActivePhase.name,
                    completed: false,
                    atlasPhaseId: this.atlasActivePhase.id,
                    allocatedUnits: allocatedUnits,
                    locked: false,
                    date: moment(this.atlasActivePhase.availableEnd.date).unix()

                }
                if(this.atlasProject.owner && this.atlasProject.owner.ownerType.id == "Person"){
                    userList.push(this.atlasProject.owner);
                }
                if(this.atlasActivePhase.owner && this.atlasActivePhase.owner.ownerType.id == "Person"){
                    userList.push(this.atlasActivePhase.owner);
                }
                if(this.atlasActivePhase.owner.ownerType.id == "Person"){
                    task['assignedUser'] = this.atlasActivePhase.owner
                }
                phases.push(task);

            }else{
                console.log(this.atlasProject);
                title = this.atlasProject.name;
                console.log(this.atlasProject.notes);
                scope = (this.atlasProject.notes? this.atlasProject.notes: 'See Atlas Brief Document') + ' ';
                
                accountName = this.atlasProject.feedDetails.feedName;

                var recursivePhases = function(_phase){
                    var appendedTitle = this || {name:null};
                    if(_phase.instances){
                        var name = _phase.name;
                        _phase.instances.forEach(instance=>{                           
                            if(instance.phases){
                                instance.phases.forEach(recursivePhases, {name:name});
                                
                            }else if(instance.module && instance.module.id == "Tech" && instance.owner.team 
                            && (instance.owner.id == 10 || instance.owner.team.id == 10)
                            ){
                               
                                //Create Task here
                                instance.newName = appendedTitle.name? appendedTitle.name + ": "+instance.name : instance.name;
                                console.log("the instance", instance);
                                var task:_TaskCreateModel = {
                                    title: instance.newName,
                                    completed: false,
                                    atlasPhaseId: instance.id,
                                    allocatedUnits: instance.units.availableUnits,
                                    locked: false,
                                    assignedUser: instance.owner,
                                    taskType: TaskType.atlas,
                                    date: moment(instance.term.endDate).unix()
                                    
                                }
                                // this.fourthFormGroup.get('dueDate').setValue(moment(instance.availableEnd.date).unix());
                                scope += instance.notes || '';
                                scope += ' ';
                                allocatedUnits += instance.units.availableUnits;
                                phases.push(task);
                                if(instance.owner.ownerType.id == "Person"){
                                    userList.push(instance.owner);
                                }
                            }else if(instance.owner && instance.owner.ownerType.id == "Person"){
                                userList.push(instance.owner);
                            }
                        })
                    }
                    if(_phase.phases){
                        _phase.phases.forEach(recursivePhases);
                    }
                }
               
                //for the allocated units i need to add all the tech phases together.
                this.atlasProject.phases.forEach(recursivePhases);
            }
        }catch(e){}

        this.phaseList = phases.map((phase,index)=>{
            phase['order'] = index;
            return phase;
        });
        
        this.dataSource = new MatTableDataSource<_TaskCreateModel>(this.phaseList);
        this.selection.clear();
        this.dataSource.data.forEach(row => this.selection.select(row));
        this.secondFormGroup.get('projectTitle').setValue(title);
        this.secondFormGroup.get('projectScope').setValue(scope);
        this.thirdFormGroup.get('ticketUnits').setValue(allocatedUnits);
        this.clientSearchText = accountName;
        this.calculateUnitsMoney({target: {name: 'units', value: allocatedUnits}});
        this.userIdList = Array.from(new Set(userList.map(u=>u.id)));
        this.loadAtlasUsers();
    }
    triggerStepEvents($event){
        var label = $event.selectedStep.label || null;
        if(label && label == 'feedSearch' && this.clientSearchText){
            this.searchForFeeds(this.clientSearchText);
        }
    }
    excludeTicketReference(){
        this.existingTicket = null;
    }
    loadAtlasUsers(){
        this.userProfiles = [];
        this.userIdList.map(user=>{
            this.coreApiService.getUserProfileById(user).subscribe(
                profile=>{
                    this.userProfiles.push(profile);
                }
            )
        });

    }
    addStakeholder($event){
       
        if($event){
            this.userProfiles.push($event);
        }
    }
    uploadFiles($event){
        //popup for file upload
        var name = 'tmp-ticket';
        if(!name){
            this.toasty.error("You must enter a name first to continue");
            return;
        }
        this.popUp.open(FileUploadComponent,{
            data:{
                ticketId: name
            },
            width: '50%'
        }).afterClosed().pipe(takeUntil(this.destroySubs$)).subscribe(
            filesInfo=>{
                console.log(filesInfo);
               //add the file url to the list of uploaded files.
               if(filesInfo && filesInfo.length > 0){
                this.files.push(...filesInfo);
               }
            }
        )
        // $event.source.value = null;
    }
    removeStakeholder(user){
        let index = this.userProfiles.findIndex(_user=>user.id==_user.id);
        if(index > -1){
            this.userProfiles.splice(index, 1);
        }
    }
    checkDate($event){
        this.dateError = false;
        let minHoursToCompletion = unitsToHours( this.thirdFormGroup.get('ticketUnits').value);
        this.minDaysToCompletion = Math.ceil(7 + (minHoursToCompletion / 7.5)) * 1.5;
        let dueDate = moment($event.value).unix();
        let earliest = moment().add(this.minDaysToCompletion, 'days').unix();
        if(dueDate < earliest){
            //@todo don't allow this ticket to proceed
            this.fourthFormGroup.get("dueDate").setValue(null);
            this.dateError = true;
        }
    }
    setTicketValuesForPreview(){
        var newTicket =  {
            accountManager: this.ownerAccountManager,
            developer: null,
            status: TicketStatus.open,
            account: this.firstFormGroup.get('account').value,
            scope: this.secondFormGroup.get('projectScope').value,
            subject: this.secondFormGroup.get('projectTitle').value,
            unitsAllocated: this.thirdFormGroup.get('ticketUnits').value,
            pricePaid: this.thirdFormGroup.get('ticketCost').value,
            paymentType: this.isProspect? 'free': this.fourthFormGroup.get('workType').value != 'Unpaid'? 'paid': 'free',
            workType: this.isProspect? 'Unpaid':this.fourthFormGroup.get('workType').value,
            workCategory: this.isProspect? 'Quote' :this.fourthFormGroup.get('workCategory').value,
            createdDate: new Date().getTime(),
            createdBy: this.authenticatedUser.user,
            priority: 3,
            isQuote: this.fourthFormGroup.get('workCategory').value === "Quote",
            quotedWorkTicket: false,
            stakeholders:[],
            department: null
        };

        let dueDate;
        if(dueDate = this.fourthFormGroup.get('dueDate').value){
          
            newTicket['dueDate'] = moment(dueDate).format('MMMM Do YYYY');
        }
        if(this.firstFormGroup.get('feed').value){
            newTicket['feed'] = this.firstFormGroup.get('feed').value;
        }
        this.ticketValues = new Ticket(newTicket);
     
    }
    submitTicket(){
        this.creatingTicket = true;
        try{
            var formValid = true;
            this.invalidFields = [];
            this.formGroup.forEach(group=>{
                
                if(!group.valid){
                    formValid = false;
    
                }
            });
            
            if(!formValid){
                this.toasty.error("There are fields not filled out that are required. Please go through each step to ensure you have properly filled out each field", "Form not Valid");
                return false;
            }
            if(!this.ownerAccountManager){
                this.ownerAccountManager = {
                    name: "unknown (curator error)"
                }
            }
            var dept:string = this.firstFormGroup.get('department').value;
            var depart:Department = Department[dept];
            //@todo only add the current user if they are NOT the ownerAccountManager.
            var stakeholders = [this.ownerAccountManager];
            this.userProfiles.forEach(_user=>{
                let index = stakeholders.findIndex(user=>user.id==_user.id);
                if(index < 0){
                    stakeholders.push(_user);
                }
            });
            var currentUser = stakeholders.findIndex(user=>user.id==this.authenticatedUser.currentUser.id);
            console.log(currentUser);
            if(this.authenticatedUser.currentUser.id != this.ownerAccountManager.id && currentUser < 0){
                console.log(stakeholders);
                stakeholders.push(this.authenticatedUser.user);
            }

            var assignedDev = this.selection.selected.length > 0 ? stakeholders.find(user=>user.id == this.selection.selected[0].assignedUser.id) : null;
            console.log(assignedDev);
            var newTicket:TicketCreateModel =  {
                accountManager: this.ownerAccountManager,
                developer: assignedDev,
                stakeholders: stakeholders,
                status: TicketStatus.open,
                account: this.firstFormGroup.get('account').value,
                scope: this.secondFormGroup.get('projectScope').value,
                subject: this.secondFormGroup.get('projectTitle').value,
                unitsAllocated: this.thirdFormGroup.get('ticketUnits').value,
                pricePaid: this.thirdFormGroup.get('ticketCost').value,
                paymentType: this.isProspect? 'free': this.fourthFormGroup.get('workType').value != 'Unpaid'? 'paid': 'free',
                workType: this.isProspect? 'Unpaid':this.fourthFormGroup.get('workType').value,
                workCategory: this.isProspect? 'Quote' :this.fourthFormGroup.get('workCategory').value,
                createdDate: new Date().getTime(),
                createdBy: this.authenticatedUser.user,
                department: depart,
                priority: 3,
                isQuote: this.isProspect? true : (this.fourthFormGroup.get('workCategory').value === "Quote" || this.fourthFormGroup.get('workCategory').value === "Quote - Website Build"),
                isClosed: false,
                quotedWorkTicket: false
            };
            var quoteTicket;
            if(quoteTicket = this.thirdFormGroup.get('quoteTicket').value){
                newTicket.quoteTicketId = quoteTicket.id
            }
            if(this.files.length > 0){
                newTicket.assets = this.files;
            }
            let dueDate;
            if(dueDate = this.fourthFormGroup.get('dueDate').value){
                newTicket.dueDate = moment(dueDate).unix();
            }
            
            if(this.atlasActivePhase || this.atlasProject){
                newTicket.atlasId = this.atlasProject.id;
                newTicket.atlas = {
                    id: this.atlasProject.id,
                    module: this.atlasProject.module.id.toLowerCase(),
                    name: this.atlasProject.name,
                    notes: this.atlasProject.notes
                }
                
            }
            if(this.firstFormGroup.get('feed').value){
                newTicket['feed'] = this.firstFormGroup.get('feed').value;
            }
            if(this.phaseList.length > 0){
                // console.log(this.phaseList, this.phaseList[this.phaseList.length - 1]);
                // console.log(newTicket);
                newTicket.dueDate = this.phaseList[this.phaseList.length - 1].date;
            
            }
            this.ticketService.createTicket( newTicket).subscribe(
                ticket=>{
                    console.log(ticket);
                    this._ticketListService.addNewTicket(ticket);
                    //check if there are tasks to create
                    if(this.taskList.length > 0 || this.phaseList.length > 0){
                        console.log("doing tasks", this.taskList, this.phaseList);
                        let taskObs = this.taskList.map(task=>{
                            let _newTask:_TaskCreateModel = {
                                title: task,
                                completed: false
                            }
                            return this.ticketService.createNewTask(ticket.id, _newTask);
                        })
                        console.log(taskObs);
                        let phaseObs = this.selection.selected.map(phase=>{
                            phase.assignedUser = phase.assignedUser? phase.assignedUser.id : null;
                            return this.ticketService.createNewTask(ticket.id, phase);
                        })
                        console.log(phaseObs);
                        let sub = forkJoin(...taskObs,...phaseObs);
                        console.log(sub);
                        
                        sub.subscribe(res=>{
                            console.log(res);
                            ticket.tasks = res;
                            this._ticketListService.updateTicket(ticket);
                            this.creatingTicket = false;
                            this.dialog.close(ticket);  
          
                          
       
                        }, er=>{
                            console.log(er);
                            this.creatingTicket = false;
                        }, () => {
                            this.creatingTicket = false;
                            this.dialog.close(ticket);
                        });
                    }else{
                        this.creatingTicket = false;
                        this.dialog.close(ticket);
                    }
                    
                },
                er=>{
                    console.log(er);
                    this.creatingTicket = false;
                }
            )
        }catch(e){
            //@todo need to show the error
            console.log(e);
            this.creatingTicket = false;
        }
    }

    calculateUnitsMoney($e){
        if($e.target.value > 0){
            if($e.target.name == 'units'){
                this.thirdFormGroup.get('ticketCost').setValue( $e.target.value  * 100);
            }else if($e.target.name == 'money'){
                this.thirdFormGroup.get('ticketUnits').setValue( $e.target.value / 100 );
            }
        }

    }
    accountSelected($event){
        let selectedAccount = $event.option.value;
        if(selectedAccount.ownerAccountManager){
            this.coreApiService.getUserProfileByEmail(selectedAccount.ownerAccountManager.email).subscribe(
                _user=>{

                    this.ownerAccountManager = new User(_user);
                }
            )
            // this.ownerAccountManager = new User(selectedAccount.ownerAccountManager);
        }else{
            //throw an error we can't select an account without an am assigned. 
        }
        this.filteredQuoteTickets = this.quoteTickets.filter( ticket=>{
            return ticket.account.id == selectedAccount.id
        });
    }
    /**
     * Method to enter an account manaually if it is not in core api.  
     * Used primary for sales but can also be for tracking github issue work. should add what is entered into an "Account" collection for sorting and reporting. 
     */
    alternativeAccountSelect(){
        this.popUp.open(TextAreaPopUpDialogComponent,{
            data: {
                message: "Please enter client exactly as they appear in Salesforce.",
                title: "Create Prospect Account",
                subtitle: "Sales Prospects are only eligable for Quote work. Please email the Director of Technical Support for anything beyond that.",
                simpleInput: true
            },
            width: '40%'
        }).afterClosed().subscribe(
            name=>{
                if(name){
                    let fauxAccount:AccountCreateModel = {
                        name: name,
                        ownerAccountManager: this.authenticatedUser.currentUser,
                        _brand: 'SP',
                        createdById: this.authenticatedUser.currentUser.id
                    };
                    //save the account in the database. if they alraedy exist in the db return the existing info.
                    this.firstFormGroup.get('account').setValue(fauxAccount);
                    this.ownerAccountManager = this.authenticatedUser.currentUser;
                    this.isProspect = true;
                    this.fourthFormGroup.get('workType').setValue('Unpaid');
                    this.fourthFormGroup.get('workCategory').setValue('Quote');
                    this._kanbanAccountService.createAccount(fauxAccount).subscribe(
                        accountResponse=>{
                            
                        }
                    )
                    
                }

            }
        )
    }
    searchBy($event){
        console.log($event);
        this.searchByField = $event.value;
        if(this.searchByField == 'feed'){
            this.accountListingDisplayedColumns = ["select", "brand","feed", "account"];
        }else{
            this.accountListingDisplayedColumns = ["select", "brand", "account"];
        }
        
    }
    searchForClient($event){

        if($event.key == "Tab"){
            if(this.activeClients.length === 1){
                // this.selectThisAccount(this.activeAccounts[0]);
            }else{
                this.activeClients = [];
            }
        }else if($event.key == "ArrowUp" || $event.key == "ArrowDown"){

        }else{
            if(this.searchByField == 'feed'){
                this.searchForFeeds($event.target.value);
            }else{
                this.searchForAccounts($event.target.value);
            }
           
        }
    }
    searchForFeeds($value){
        this.coreApiService.listFeeds({query: $value}).subscribe(
            (feeds:any)=>{
                console.log(feeds);
                this.activeClients = feeds.items
                this.clientListingDataSource = new MatTableDataSource<any>(this.activeClients);

            },
            error=>{
                console.log(error);
                // this._toasty.warning("Accounts could not be retrieved", "Core API");
            });
    }
    searchForAccounts($value){
        this.coreApiService.listAccounts({query: $value}).subscribe(
            (accounts:any)=>{
                let activeClients = accounts.items;
                this.activeClients = activeClients.concat(this.dummyAccounts);
                this.clientListingDataSource = new MatTableDataSource<any>(this.activeClients);
                
            },
            error=>{
                console.log(error);
                // this._toasty.warning("Accounts could not be retrieved", "Core API");
            });
    }
    displayAccount(account){
        return account? `(${account._brand}) ${account.name}`: undefined;
    }
    quoteTicketSelected($event){
        console.log($event, $event.option.value);
        let cost = {
            units: $event.option.value.quoteUnits || 0,
            dollars: $event.option.value.quoteDollar || 0
        }
        console.log(cost);
        //fill in the dollar and unit amount.
        this.thirdFormGroup.get('ticketUnits').setValue(cost.units);
        this.thirdFormGroup.get('ticketCost').setValue(cost.dollars);
    }
    displayQuote(ticket){

        return ticket? `${ticket.subject} `: undefined;
    }
    loadWorkCategories(type:string){
        let workType = <WorkType> WorkType[type];
        this.workService.listWorkCategories(workType).pipe(take(1)).subscribe(
            cats=>{
                console.log(cats);
                this.workCategories = cats;
            }
        )
    }
    determineLikelyWorkCategory($event){
        //check the work type and 
        var workType = $event.value;
        this.loadWorkCategories(workType);
        //if a paid work has been selected check if any units have been entered. if not throw a warning and go back to unit allocation
        if(workType == WORKTYPE[WorkType.Contract] || workType == WORKTYPE[WorkType.Rolling] ){
            this.dueDateEligible = true;
            let cost = this.thirdFormGroup.get('ticketUnits').value;
            if(!cost){
                $event.source.value = null;
                this.popUp.open(ConfirmationDialogComponent,{
                    data: {
                        message: "You have selected paid work but did not enter units allocated or $$ cost of this work",
                        confirm: true
                    }
                })
                this.stepper.selectedIndex = 2;
            }
            
            if(!this.authenticatedUser.checkPermission([PermissionSet.isAdmin, PermissionSet.isTechTeam])){
                this.popUp.open(ConfirmationDialogComponent,{
                    data: {
                        title: "Insufficent Permissions",
                        message: "You have selected paid work, however all paid work must be logged through Atlas. <br/>Please ensure an Atlas project is open and all appropriate assets are provided there along with a scope of work in the notes.",
                        confirm: true
                    }
                }).afterClosed().subscribe(close=>this.dialog.close());
                
            }
        }else{
        //if free work is selected throw a warning noting that free work has been selected. if this work should be charged for this will be turned into a quote ticket and you will be required to contact production for proper unit allocation and open a new work ticket.
        this.dueDateEligible = false;
            this.toasty.info("You have select unpaid work.  Should your request require a charge, this ticket will be turned into a quote ticket after which it will need to be added to Atlas to be actionable.","Free Work");
        }
    }
    //Does the current work selected match the units charged
    determineUnitAllocation($event){
        this.taskList = [];
        this.requirementList = [];
        let cat = $event.value;
        let catObj:WorkCategory = this.workCategories.find(_cat=>_cat.name==cat);
        this.catObj = catObj;
        let setUnits = this.thirdFormGroup.get('ticketUnits').value;
        if(setUnits < catObj.min){ // units paid are less than min cahrge
            //@todo do something as units are not enough
        }
        if(catObj.tasks && catObj.tasks.length > 0){
            this.taskList = catObj.tasks;
        }
        if(catObj.requirements && catObj.requirements.length > 0){
            this.requirementList = catObj.requirements;
        }
        if(cat.files && catObj.files.length > 0){
            this.files.push(...catObj.files);
        }
    }
    /** Whether the number of selected elements matches the total number of rows. */
        isAllSelected() {
            const numSelected = this.selection.selected.length;
            const numRows = this.dataSource.data.length;
            return numSelected == numRows;
        }
        
        /** Selects all rows if they are not all selected; otherwise clear selection. */
        masterToggle() {
            this.isAllSelected() ?
                this.selection.clear() :
                this.dataSource.data.forEach(row => this.selection.select(row));

                
        }
        selectAccountRadio($event){
            console.log($event);
            var $value = $event.value;
            if($value.account){ //isfeed
                this.firstFormGroup.get('account').setValue($value.account);
                this.firstFormGroup.get('feed').setValue($value);

            }else{
                this.firstFormGroup.get('account').setValue($value);
                this.firstFormGroup.get('feed').setValue(null);
            }
        }
        checkValid(formGroup, elementRef){
            console.log(formGroup, elementRef);
            if(formGroup.invalid && formGroup.controls.account.invalid){
                elementRef.style.display = "block";
                elementRef.innerHTML = "You must select an Account. You can search by feed or account name below.";


            }else{
                elementRef.style.display = "none"; 
                elementRef.innerHTML = "";
                console.log(this.workTypes);
                if(formGroup.value.account.id == 2169 && !this.workTypes.includes(WORKTYPE[WorkType.Internal])){ 
                    this.workTypes.push(WORKTYPE[WorkType.Internal]);
                }
            }
        }
}