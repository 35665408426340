import { Component, Input } from '@angular/core';

@Component({
    selector: 'user-chip',
    template: `
    <div class="chip-container" [class.large]="largeIcon" *ngIf="user">
    <img class="user-icon" [class.large]="largeIcon" *ngIf="user.profileImageUrl != null" [src]="user.profileImageUrl" alt="..." [title]="user.name" />
    <span *ngIf="user.profileImageUrl == null" [style.backgroundColor]="getRandomColor(user)" [class.large]="largeIcon" class="userIconSubstitute user-icon"  [title]="user.name">{{ user.name?.charAt(0) }}</span>
    <span *ngIf="details" class="details" [class.large]="largeIcon">{{user.name}}</span>
    <span class="team">{{user.team?.name}}</span>
    </div>
    `,
    styles: [`
    .chip-container{
        display:inline-block;
        text-align:center;
    }
    .chip-container.large{
        width:100%;
        display:block;
    }
    .user-icon{
        transition: .5s all ease-in-out;
        width:30px;
        border-radius:100%;
        display:inline-block;
        line-height: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 1.35em;
        color: white;
        border:2px solid rgba(255, 255, 255, 0.99);
    }
    .user-icon.large{
        width:60px;
        height:60px;
        line-height:60px;
    }
    .team{
        font-size: .5rem;
        display:block;
    }
    .details.large{
        display:block;
        text-align:center;
    }
    .userIconSubstitute{
        margin:auto;
    }
    .userIconSubstitute.large{
        font-size:40px;
    }
    `]
})
export class UserChipComponent{
    @Input('user') user;
    @Input('largeIcon') largeIcon = false;
    @Input('displayDetails') details = false;
    public userIconColors = ["blue", "orange", "red", "green","purple","aqua"];
    constructor(){
        console.log(this.user);

    }
    getRandomColor(user){
        if(user.color){
            return user.color;
        }
        user.color = this.userIconColors[Math.floor(Math.random()*this.userIconColors.length)];

        return user.color;
    }
}