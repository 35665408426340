import { WorkReadyStatus } from '@brafton/deliverables-client';
import * as moment from 'moment';
import { _TaskCreateModel } from 'app/KanbanService/models/requestCreateModels/_TaskCreateModel';
import { TaskType } from 'app/KanbanService/models/enums/TaskTypes';
import { Task } from 'app/KanbanService/models/responseModels/Task';

export var ATLASMAP = {
  'tech': 'tech',
  'video': 'videos',
  'design': 'designs',
  'consulting': 'consulting',
  'editorial': 'editorial',
  'social': 'social',
  'account management': 'account-manager',
  'accountmanagement': 'account-manager'
}
export const daysBetween = function( date1, date2 ) {
    //Get 1 day in milliseconds
    // console.log(date1, date2);
    var one_day=1000* 60*60*24;
  
    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
  
    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    // console.log(difference_ms);
    // Convert back to days and return
    return Math.round(difference_ms/one_day); 
  }
  export const getBusinessDays = function(startDate, endDate){
    // console.log(startDate, endDate);
    var startDateMoment = moment(startDate);
    var endDateMoment = moment(endDate);
    // console.log(startDateMoment, endDateMoment);
    var days = Math.round(startDateMoment.diff(endDateMoment, 'days') - startDateMoment .diff(endDateMoment, 'days') / 7 * 2);
    if (endDateMoment.day() === 6) {
      days--;
    }
    if (startDateMoment.day() === 7) {
      days--;
    }
    return days;
  }
  export const unitsToHours = function(units){
    return units? units / 2 : 0;
  }
  export const hoursToUnits = function(hours){
    return hours? hours * 2 : 0;
  }
  export const getAssignedUnits = function(unitsAllocated, unitsAdjusted){
    let units = unitsAdjusted ? parseFloat(unitsAdjusted.toString()) : unitsAllocated? parseFloat(unitsAllocated.toString()) : 0;
    return units;
  }
  export class atlasPhasesToTasks{
    private WorkReadyStatus:WorkReadyStatus = null;
    setWorkReadyStatus(status:WorkReadyStatus){
      this.WorkReadyStatus = status;
    }
    private tasks = [];
    private phases = [];
    private appendTitle = null;
    listTasks():Array<Task>{
      return this.tasks;
    }
    listPhases():Array<any>{
      return this.phases;
    }
    convert(phases):Array<Task>{
      console.log(this.WorkReadyStatus)
      phases.forEach(p=>{
        this.recursivePhases(p);
      });
      return this.tasks;
    }

    private recursivePhases(_phase){
    var appendedTitle = this.appendTitle? {name:this.appendTitle}: {name:null};
      
    if(_phase.instances){
        var name = _phase.name;
        _phase.instances.forEach(instance=>{                           
            if(instance.phases){
                this.appendTitle = this.appendTitle? this.appendTitle+': '+ instance.name : instance.name;
                instance.phases.forEach(p=>{
                  this.recursivePhases(p);
                })
                
            }else if(instance.module && instance.module.id == "Tech" && instance.owner.team 
            && (instance.owner.id == 10 || instance.owner.team.id == 10) 
            && !instance.completed
            ){
              if(this.WorkReadyStatus == null || 
                instance.workReadyStatus.name == this.WorkReadyStatus.name){
                this.phases.push(instance);
                //Create Task here
                instance.newName = instance.parentDeliverable.productInstancePhase? instance.parentDeliverable.productInstancePhase.name+': '+instance.parentDeliverable.name+'| '+instance.name: instance.parentDeliverable.name+': '+instance.name;
                //instance.parentDeliverable.productInstancePhase.name | instance.parentDeliverable.name | instance.name
                var task:_TaskCreateModel = {
                    title: instance.newName,
                    completed: false,
                    atlasPhaseId: instance.id,
                    allocatedUnits: instance.units.availableUnits,
                    locked: false,
                    assignedUser: instance.owner? instance.owner.id: null,
                    taskType: TaskType.atlas                    
                }
                task['created'] = instance.created;
                this.tasks.push(task);
                this.appendTitle = null;
              }
               
            }else if(instance.owner && instance.owner.ownerType.id == "Person"){
                
            }
        })
    }
    if(_phase.phases){
        _phase.phases.forEach(p=>{
          this.recursivePhases(p);
        });
    }
  }
}