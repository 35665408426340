import { AuthenticatedUser } from './authenticatedUser.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';

import { BfAuthService } from '@brafton/skynet-angular-security-service';

@Injectable()
export class ActivationGuard implements CanActivate {
    constructor(private bfAuth:BfAuthService, private authenticatedUser:AuthenticatedUser) {}

    public canActivate(): Observable<boolean> {
        if (this.authenticatedUser.currentUser.email) { 
            return of(true); 
        }

        let subject = new Subject<boolean>();
        let subscription = this.authenticatedUser.userAsync.subscribe(user => {
            
            if (user.email) {
                subject.next(true);
                subject.complete();

                subscription.unsubscribe();
            }
        });

        return subject;
    }
}